/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/07/2017.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param kind
 * @param page
 * @param tags
 * @param direction
 * @param sort
 * @returns {(function(*): void)|*}
 */
export function fetchLastPosts(kind, page = 1, tags = null, direction = null, sort = null) {
  return function (dispatch) {
    dispatch({ type: "FETCH_LAST_POSTS" });

    const params = {
      page,
      kind,
      tags: tags ? tags.join(",") : null,
      sort,
      direction
    };

    const queryParams = AxiosConfig.objectToURLQuery(params);
    axios
      .get(
        AxiosConfig.getEndpointAddress() +
          `/api/posts/latest.json?${queryParams}`
      )
      .then((response) => {
        dispatch({
          type: "FETCH_LAST_POSTS_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_LAST_POSTS_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param params
 * @returns {(function(*): void)|*}
 */
export function fetchPostsAdmin(params) {
  return function (dispatch) {
    dispatch({ type: "FETCH_ADMIN_POSTS" });

    axios
      .get(
        AxiosConfig.getEndpointAddress() +
          "/api/posts/admin.json?" +
          AxiosConfig.objectToURLQuery(params),
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "FETCH_ADMIN_POSTS_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_ADMIN_POSTS_REJECTED",
          payload: error,
        });
      });
  };
}

export function cleanupCollection() {
  return function (dispatch) {
    dispatch({ type: "CLEANUP_COLLECTION_POSTS" });
  };
}

/**
 *
 * @param slug
 * @returns {(function(*): Promise<void>)|*}
 */
export function fetchSimilar(slug) {
  return async (dispatch) => {
    dispatch({ type: "FETCH_SIMILAR_POSTS" });

    await axios
      .get(AxiosConfig.getEndpointAddress() + `/api/posts/similar/${slug}.json`)
      .then((response) => {
        dispatch({
          type: "FETCH_SIMILAR_POSTS_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_SIMILAR_POSTS_REJECTED",
          payload: error,
        });
      });
  };
}
