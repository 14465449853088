/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as appActions from "./../actions/app";

export function useAppStore() {
  const dispatch = useDispatch();
  const _app = useSelector((store) => store.app);

  const clearErrors = useCallback(
    () =>
      dispatch(appActions.clearErrors()),
    [dispatch]
  );

  const acceptCookies = useCallback(
    (options) =>
      dispatch(appActions.acceptCookies(options)),
    [dispatch]
  );

  return {
    app: _app,
    clearErrors,
    acceptCookies
  };
}
