/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022
 */

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import DropdownNavyNavbar from "./PageTemplate/DropdownNavyNavbar";
import FooterNavy from "./PageTemplate/FooterNavy";
import Navbar from "./PageTemplate/Navbar";

const PageTemplate = (props) => {

  useEffect(() => {

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  return (
    <>
      <Navbar>

      <DropdownNavyNavbar />
      </Navbar>
      <div className={"wrapper wrapper-admin"}>
        {props.children}
      </div>
      <FooterNavy/>
    </>
  );
};

PageTemplate.defaultProps = {};

PageTemplate.propTypes = {};

export default PageTemplate;
