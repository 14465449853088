import React from "react";
import classNames from "classnames";
import { formatError } from "../helpers/error";
import PropTypes from "prop-types";
import { Alert, Container } from "reactstrap";

const defaultMessage = "Could not perform this action";

const ApiError = (props) => {
  const errorObj = props.error;
  const messageDefault = props.defaultMessage || defaultMessage;
  const containerClassName = classNames(
    { container: !props.containerClassName },
    props.containerClassName
  );
  return errorObj ? (
    <Alert color={props.color}>
      <Container className={containerClassName}>
        <div className="alert-icon">
          <i className="fas fa-exclamation"></i>
        </div>
        <span>
          {formatError(errorObj, messageDefault).map((item, index) => (
            <React.Fragment key={index}>
              {item}
              <br />
            </React.Fragment>
          ))}
        </span>
      </Container>
    </Alert>
  ) : null;
};

ApiError.defaultProps = {
  color: "danger"
}

ApiError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  containerClassName: PropTypes.string,
  defaultMessage: PropTypes.string,
  color: PropTypes.string,
};

export default ApiError;
