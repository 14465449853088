/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import AdminPageTemplate from "../../templates/AdminPageTemplate";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Datetime from "react-datetime";
import AxiosConfig from "../../AxiosConfig";
import SubscribersTable from "./SubscribersIndexPage/SubscribersTable";
import {useAdminMailListStore} from "../../hooks/adminMailList";

const SubscribersIndexPage = (props) => {
  const { adminMailList, fetchMailList } = useAdminMailListStore();

  const isLoading = adminMailList.fetch;
  const params = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    created_after: params.get("created_after") || null,
    created_before: params.get("created_before") || null,
  });

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      page: params.get("page") || 1,
      email: params.get("email") || null,
      created_after: params.get("created_after") || null,
      created_before: params.get("created_before") || null,
      sort: params.get("sort") || null,
      direction: params.get("direction") || null,
    };
  }, [window.location.search]);

  /**
   *
   * @type {function(*): string}
   */
  const linkBuilder = useCallback(
    (newParams) => {
      const params = { ...searchParams, ...newParams };
      return `/admin/subskrybenci?` + AxiosConfig.objectToURLQuery(params);
    },
    [searchParams]
  );

  useEffect(() => {
    if (window.location.pathname === "/admin/subskrybenci") {
      fetchMailList(searchParams);
    }
  }, [searchParams]);

  /**
   *
   * @param e
   */
  const onCreatedAfterDateChange = (e) => {
    setState({
      ...state,
      created_after: typeof e === "object" ? e.unix() : null,
    });
  };

  /**
   *
   * @param e
   */
  const onCreatedBeforeDateChange = (e) => {
    setState({
      ...state,
      created_before: typeof e === "object" ? e.unix() : null,
    });
  };

  /**
   *
   * @param e
   */
  const onSearch = (e) => {
    const updatedUrl = { ...searchParams, ...state };

    updatedUrl.page = 1;
    const email = document.getElementById("email").value;

    updatedUrl.email = email && email.length ? email : null;

    updatedUrl.created_before = state.created_before || null;
    updatedUrl.created_after = state.created_after || null;

    updatedUrl.sort = null;
    updatedUrl.direction = null;

    props.history.replace(linkBuilder(updatedUrl));
  };

  /**
   *
   * @param e
   */
  const onClear = (e) => {
    props.history.replace(
      `/admin/subskrybenci?` + AxiosConfig.objectToURLQuery({ page: 1 })
    );
  };


  /**
   *
   * @param e
   */
  const onSort = (e) => {
    const updatedUrl = {...searchParams, ...e};
    props.history.replace(linkBuilder(updatedUrl));

  };

  return (
    <AdminPageTemplate>
      <Container>
        <Row>
          <Col className="" md="12">
            <Card className="card-plain">
              <CardHeader>
                <h4 className={"card-title"}>Subskrybenci</h4>

                <Form className={"form-horizontal"}>
                  <Row>
                    <FormGroup className={"col-3"}>
                      <label htmlFor={"title"}>Email</label>
                      <Input
                        id={"email"}
                        placeholder="Email"
                        type="text"
                        disabled={isLoading}
                        defaultValue={searchParams.email}
                      ></Input>
                    </FormGroup>


                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>Dodany po</label>
                      <Datetime
                        inputProps={{
                          placeholder: "Dodany po",
                          disabled: isLoading,
                        }}
                        onChange={onCreatedAfterDateChange}
                        value={
                          state.created_after
                            ? state.created_after * 1000
                            : null
                        }
                      />
                    </FormGroup>
                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>
                        Dodany przed
                      </label>
                      <Datetime
                        disabled={isLoading}
                        inputProps={{
                          placeholder: "Dodany prezd",
                          disabled: isLoading,
                        }}
                        onChange={onCreatedBeforeDateChange}
                        value={
                          state.created_before
                            ? state.created_before * 1000
                            : null
                        }
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button onClick={onClear} disabled={isLoading}>
                        Wyczyść
                      </Button>
                      <Button
                        color={"info"}
                        onClick={onSearch}
                        disabled={isLoading}
                      >
                        Szukaj
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody>
                <SubscribersTable
                  isLoading={isLoading}
                  collection={adminMailList.collection}
                  pagination={adminMailList.pagination}
                  linkBuilder={linkBuilder}
                  onSort={onSort}
                  sort={{
                    sort: searchParams.sort || null,
                    direction: searchParams.direction || null,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </AdminPageTemplate>
  );
};

SubscribersIndexPage.defaultProps = {};

SubscribersIndexPage.propTypes = {};

export default SubscribersIndexPage;
