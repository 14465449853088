/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/08/2023
 */

import React from "react";
import PropTypes from "prop-types";

const Youtube = ({ url }) => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        textAlign: "center",
      }}
      controls={"0"}
      src={`https://www.youtube-nocookie.com/embed/${url.pathname}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      modestbranding={"0"}
    ></iframe>
  );
};

Youtube.defaultProps = {};

Youtube.propTypes = {};

export default Youtube;
