/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as userActions from "./../actions/user";
import {
  checkSession,
  logout,
  remindPassword,
  resetPassword,
} from "./../actions/user";

export function useUserStore() {
  const dispatch = useDispatch();
  const _user = useSelector((store) => store.user);

  const login = useCallback(
    async (email, password, provider, token) =>
      await dispatch(userActions.login(email, password, provider, token)),
    [dispatch]
  );

  const register = useCallback(
    async (nickname, email, password, newsletterConsent, provider, token) =>
      await dispatch(userActions.register(nickname, email, password, newsletterConsent, provider, token)),
    [dispatch]
  );

  const deleteUser = useCallback(
    async () => await dispatch(userActions.deleteUser()),
    [dispatch]
  );

  const updateUser = useCallback(
    async (id, data) => await dispatch(userActions.updateUser(id, data)),
    [dispatch]
  );

  const uploadAvatar = useCallback(
    async (id, image) =>
      await dispatch(userActions.uploadAvatar(id, image)),
    [dispatch]
  );

  const remindPassword = useCallback(
    async (email) => await dispatch(userActions.remindPassword(email)),
    [dispatch]
  );

  const logout = useCallback(
    async () => await dispatch(userActions.logout()),
    [dispatch]
  );

  const resetPassword = useCallback(
    async (email, token, password) =>
      await dispatch(userActions.resetPassword(email, token, password)),
    [dispatch]
  );

  const checkSession = useCallback(
    async () => await dispatch(userActions.checkSession()),
    [dispatch]
  );

  return {
    user: _user,
    login,
    register,
    deleteUser,
    updateUser,
    uploadAvatar,
    remindPassword,
    logout,
    resetPassword,
    checkSession,
  };
}
