/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as usersActions from "./../actions/users";

export function useAdminUsersStore() {
  const dispatch = useDispatch();
  const _adminUsers = useSelector((store) => store.adminUsers);

  const fetchAdmin = useCallback(
    async (params) => await dispatch(usersActions.fetchAdmin(params)),
    [dispatch]
  );

  return {
    adminUsers: _adminUsers,
    fetchAdmin,
  };
}
