/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/01/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import Header from "./ArticlePage/Header";
import { usePostStore } from "../hooks/post";
import { Link, useHistory, useLocation } from "react-router-dom";
import { extractBrief } from "../tools/text";
import SeoBlock from "../components/SeoBlock";
import ArticleBody from "./ArticlePage/ArticleBody";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Comments from "./ArticlePage/Comments";
import Author from "./ArticlePage/Author";
import { useUserStore } from "../hooks/user";
import NewComment from "./ArticlePage/NewComment";
import { useCommentStore } from "../hooks/comment";
import Article from "../components/Schemas/Article";
import AxiosConfig from "../AxiosConfig";
import LikeButton from "./ArticlePage/LikeButton";
import VideoPageTemplate from "../templates/VideoPageTemplate";
import Video from "./VideoPage/Video";
import PanoramicContainer from "../components/PanoramicContainer";
import ArticleCard from "./ArticlesIndexPage/ArticleCard";
import { useSimilarPosts } from "../hooks/similarPosts";
import Indicator from "../components/Indicator";
const IntlDate = new Intl.DateTimeFormat("pl", {
  dateStyle: "medium",
  timeStyle: "short",
});

const VideoPage = (props) => {
  const [postLoaded, setPostLoaded] = useState(false);

  const { user } = useUserStore();
  const { post, fetchPost, votePost, unvotePost } = usePostStore();
  const { voteComment, unVoteComment, comment } = useCommentStore();
  const { similarPosts, fetchSimilar } = useSimilarPosts();

  const postModel = post.model || {};

  const url = useLocation();

  useEffect(() => {
    document.body.classList.add("blog-post");
    fetchPost(props.match.params.slug);
    fetchSimilar(props.match.params.slug);

    return () => {
      document.body.classList.remove("blog-post");
    };
  }, [url.pathname]);

  useEffect(() => {
    if (post.fetchSuccess) {
      setPostLoaded(true);
    }
  }, [post.fetchSuccess]);

  /**
   *
   * @param e
   */
  const onVote = (e) => {
    if (!user.model) {
      props.history.push("/logowanie");
    } else {
      if (Number.parseInt(postModel.user_vote) === 0) {
        votePost(postModel.id, 1);
      } else {
        unvotePost(postModel.id);
      }
    }
  };

  /**
   *
   * @param comment
   */
  const onVoteComment = (comment) => {
    if (!user.model) {
      props.history.push("/logowanie");
    } else {
      if (Number.parseInt(comment.user_vote) === 0) {
        voteComment(comment.id, 1);
      } else {
        unVoteComment(comment.id);
      }
    }
  };

  const title =
    post.fetch || !postModel.title ? "Wczytuję artykuł..." : postModel.title;

  const coverImage =
    postModel.images && postModel.images.length
      ? postModel.images.find((i) => i.is_cover)
      : null;

  let briefWordsArray = [];

  if (postLoaded && postModel.abstract) {
    briefWordsArray = extractBrief(postModel.abstract, 30).briefWordsArray;
  } else if (postLoaded && postModel.body) {
    briefWordsArray = extractBrief(postModel.body, 30).briefWordsArray;
  }

  const tagsInputValue =
    postLoaded && postModel.tags.length
      ? postModel.tags.map((item) => {
          return item.label;
        })
      : null;

  const seoBlock = postLoaded ? (
    <>
      <SeoBlock
        title={postModel.title}
        description={briefWordsArray.join(" ")}
        keywords={tagsInputValue ? tagsInputValue.join(", ") : null}
        image={
          postModel.images && postModel.images.length
            ? postModel.images[0].thumb_md_path
            : null
        }
        author={postModel.user.nickname}
        type="article"
      />
      <Article
        article={{
          title: postModel.meta_title,
          headline: postModel.meta_description,
          description: postModel.body,
          publishedDate: new Date(postModel.created).toISOString(),
          slug: postModel.slug,
          url: AxiosConfig.getResourceUrl(`/artykul/${postModel.slug}`),
          tags: postModel.tags.map((i) => i.label),
          featured: coverImage
            ? {
                title: postModel.meta_title,
                src: coverImage.orig_path,
                width: "800",
                height: "600",
              }
            : null,
          author: {
            name: postModel.user.nickname,
            imageUrl: AxiosConfig.getResourceUrl(
              `/avatar/${postModel.user.avatar}`
            ),
            url: AxiosConfig.getResourceUrl(
              `/uzytkownik/${postModel.user.slug}`
            ),
          },
          publisher: {
            contactPoint: "",
            address: "",
            logoUrl: AxiosConfig.getResourceUrl(`/img/pjl_logo_white.png`),
            name: "PJL",
            fullName: "Programowanie Jest Łatwe",
          },
        }}
        lastModified={new Date(
          (postModel.modified || postModel.created) * 1000
        ).toISOString()}
      />
    </>
  ) : null;

  const postBody = postLoaded ? (
    <ArticleBody body={postModel.body} votes={postModel.votes} />
  ) : null;

  const comments = postLoaded ? (
    <Comments
      comments={postModel.comments}
      comments_count={postModel.comments_count}
      onVoteComment={onVoteComment}
      createdCommentId={comment.createdCommentId}
      user={user.model}
    />
  ) : null;

  const video = postLoaded ? <Video url={postModel.video_url} /> : null;

  const tags =
    postLoaded && postModel.tags && postModel.tags.length
      ? postModel.tags.map((i, idx) => (
          <span key={i.slug} className={"text-info"}>
            #{i.label}
          </span>
        ))
      : null;

  const canEdit =
    user.model && (user.model.id == props.id || user.model.role === "admin");

  const controlButtons = canEdit ? (
    <UncontrolledDropdown className="button-dropdown d-inline-block ml-1">
      <DropdownToggle
        data-toggle="dropdown"
        id="navbarDropdown"
        className="btn-round btn-icon mr-1"
        color="secondary"
        size="md"
      >
        <i className="fas fa-ellipsis-v"></i>
      </DropdownToggle>
      <DropdownMenu aria-labelledby="navbarDropdown">
        <DropdownItem
          tag={Link}
          to={`/edytuj-artykul/${props.match.params.slug}`}
        >
          Edytuj...
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : null;

  const similar = similarPosts.collection.length
    ? similarPosts.collection.map((i, idx) => (
        <ArticleCard key={i.slug} post={i} showPublishDate={false} />
      ))
    : null;

  const similarPostsBlock = similar ? (
    <>
      <Col md={12}>
        <h4>Powiązane tematy</h4>
      </Col>
      {similar}
    </>
  ) : null;

  const content = postModel.slug ? (
    <>
      <Col>
        <div className={"video-header"}>
          {tags}

          <div className={"video-title"}>
            <h1 className={""}>{postModel.title}</h1>
            <div>
              <LikeButton
                className={"btn-link"}
                votes={postModel.votes}
                onVote={onVote}
                userVoted={Number.parseInt(postModel.user_vote) !== 0}
                isLoading={post.vote}
              />
              <Button
                tag={Link}
                to={"/subskrybuj"}
                className={"btn-subscribe"}
                color={"danger"}
              >
                Subskrybuj
              </Button>

              {controlButtons}
            </div>
          </div>
        </div>
      </Col>
      <Col md={12}>
        <div className={"views-counter text-default"}>
          {IntlDate.format(postModel.created * 1000)} <strong>&#183;</strong>{" "}
          {postModel.views_count} wyświetleń
        </div>
      </Col>
    </>
  ) : (
    <div className={"mt-3 mr-auto ml-auto"}>
      <Indicator />
    </div>
  );

  return (
    <VideoPageTemplate>
      {seoBlock}
      <div className="section section-video">
        <PanoramicContainer>{video}</PanoramicContainer>
        <Container>
          <Row>{content}</Row>
        </Container>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              {postBody}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-blog-info">
        <Container>
          <Row>
            <Col md="8">
              <hr></hr>

              {postModel.user ? <Author user={postModel.user} /> : null}
            </Col>
          </Row>
        </Container>
      </div>

      <NewComment />
      {comments}
      <Container>
        <Row>
          <Col className="" md="8"></Col>

          {similarPostsBlock}
        </Row>
      </Container>
    </VideoPageTemplate>
  );
};

VideoPage.defaultProps = {};

VideoPage.propTypes = {};

export default VideoPage;
