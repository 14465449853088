const initialState = {
  model: null,
  token: null,

  login: false,
  loginSuccess: false,
  loginError: null,

  register: false,
  registerSuccess: false,
  registerError: null,

  edit: false,
  editError: null,
  editLoading: false,
  editSuccess: false,

  delete: false,
  deleteError: null,
  deleteSuccess: false,

  avatarUpload: false,
  avatarUploadError: null,
  avatarUploadSuccess: false,

  resetPassword: false,
  resetPasswordSuccess: false,
  resetPasswordError: null,

  remindPassword: false,
  remindPasswordSuccess: false,
  remindPasswordError: null,

  checkSession: false,
  checkSessionSuccess: false,
  sessionExpired: false,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "USER_LOGIN":
      return {
        ...state,
        login: true,
        loginSuccess: false,
        loginError: null,
      };

    case "USER_LOGIN_REJECTED":
      return {
        ...state,
        login: false,
        loginSuccess: false,
        loginError: action.payload,
      };

    case "USER_LOGIN_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
        login: false,
        loginSuccess: true,
        loginError: null,
      };

    case "USER_REGISTER":
      return {
        ...state,
        register: true,
        registerSuccess: false,
        registerError: null,
      };

    case "USER_REGISTER_REJECTED":
      return {
        ...state,
        register: false,
        registerSuccess: false,
        registerError: action.payload,
      };

    case "USER_REGISTER_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        token: action.payload.token,
        register: false,
        registerSuccess: true,
      };

    case "USER_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "USER_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload,
      };

    case "USER_EDIT_FULFILLED":
      return {
        ...state,
        model: action.payload,

        edit: false,
        editSuccess: true,
      };

    case "USER_AVATAR_EDIT":
      return {
        ...state,
        avatarUpload: true,
        avatarUploadSuccess: false,
        avatarUploadError: null,
      };
    case "USER_AVATAR_EDIT_REJECTED":
      return {
        ...state,

        avatarUpload: false,
        avatarUploadError: action.payload,
      };

    case "USER_AVATAR_EDIT_FULFILLED":
      return {
        ...state,
        model: action.payload,
        avatarUpload: false,
        avatarUploadSuccess: true,
      };

    case "USER_DELETE":
      return {
        ...state,
        delete: true,
        deleteError: null,
        deleteSuccess: false,
      };

    case "USER_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "USER_DELETE_FULFILLED":
      return {
        ...state,

        delete: false,
        deleteSuccess: true,
      };

    case "USER_PASSWORD_REMIND":
      return {
        ...state,
        remindPassword: true,
        remindPasswordSuccess: false,
        remindPasswordError: null,
      };

    case "USER_PASSWORD_REMIND_FULFILLED":
      return {
        ...state,
        remindPassword: false,
        remindPasswordSuccess: true,
      };

    case "USER_PASSWORD_REMIND_REJECTED":
      return {
        ...state,
        remindPassword: false,
        remindPasswordError: action.data,
      };

    case "USER_RESET_PASSWORD":
      return {
        ...state,
        resetPassword: true,
        resetPasswordSuccess: false,
        resetPasswordError: null,
      };

    case "USER_RESET_PASSWORD_FULFILLED":
      return {
        ...state,
        resetPassword: false,
        resetPasswordSuccess: true,
        model: action.data.user,
        token: action.data.token,
      };

    case "USER_RESET_PASSWORD_REJECTED":
      return {
        ...state,
        resetPassword: false,
        resetPasswordError: action.data,
      };

    case "USER_SESSION_CHECK":
      return {
        ...state,
        checkSession: true,
        checkSessionSuccess: false,
      };

    case "USER_SESSION_CHECK_REJECTED":
      return {
        ...state,
        sessionExpired: true,
        checkSession: false,
        checkSessionSuccess: false,
      };

    case "USER_SESSION_CHECK_FULFILLED":
      return {
        ...state,
        model: action.payload.user,
        checkSession: false,
        checkSessionSuccess: true,
      };

    case "MAILLIST_UPDATE_FULFILLED":
      return {
        ...state,
        model: {
          ...state.model,
          email_subscriber: action.payload.email_subscriber
        }
      }

    case "USER_LOGOUT":
      return {
        ...initialState,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
        token: state.token,
      };
  }

  return state;
}
