import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RouterClass from "./router";
import {
  configureStore,
  persistor
} from "./store";
//import * as serviceWorker from './serviceWorker';
import "./assets/css/bootstrap.min.css";
import "./scss/styles.scss";

console.log("Hi TBH!");

function launchApp(swObject) {


  const app = document.getElementById("root");

  ReactDOM.render(
    <Provider store={configureStore}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterClass swObject={swObject} />
      </PersistGate>
    </Provider>,
    app
  );
}

//if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
launchApp();
/*
}
else {
  if ("serviceWorker" in navigator) {
    registerServiceWorker()
      .then(response => {
        launchApp(response);
      })
      .catch(error => {
        console.log(error);
      });
  }
  else {
    launchApp();
  }
}
*/
