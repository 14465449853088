/**
 * Created by leper on 08/01/2022
 */

import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {Button, Col, Row} from "reactstrap";
import AxiosConfig from "../../AxiosConfig";

const Header = (props) => {

  const pageHeader = useRef();

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, [])

  return (
    <div className="page-header page-header-small">
      <div
        className="page-header-image"
        style={{
          backgroundImage:
            "url(" + AxiosConfig.getResourceUrl(props.bgImgSrc) + ")",
        }}
        ref={pageHeader}
      ></div>
      <div className="content-center">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title" dangerouslySetInnerHTML={{__html: props.title}}/>
            {/*<Button className="btn-round btn-icon mr-1" color="info">
              <i className="fab fa-twitter"></i>
            </Button>
            <Button className="btn-round btn-icon" color="info">
              <i className="fab fa-instagram"></i>
            </Button>*/}
          </Col>
        </Row>
      </div>
    </div>
  );
};

Header.defaultProps = {
  bgImgSrc: '/img/bg/bg27.jpg',
  title: `Wszystko, co chcesz wiedzieć o&nbsp;programowaniu ale boisz się zapytać.`
};

Header.propTypes = {
  bgImgSrc: PropTypes.string,
  title: PropTypes.string
};

export default Header;
