/**
 * Created by leper on 03/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "reactstrap";
import TablePagination from "../../../components/TablePagination";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Indicator from "../../../components/Indicator";
import UserAvatar from "../../../components/UserAvatar";
import SortableCol from "../../../components/SortableCol";

const dateTimeFormat = new Intl.DateTimeFormat("pl-PL", {
  dateStyle: "short",
  timeStyle: "short",
});

const SubscribersTable = (props) => {
  if (props.isLoading) {
    return (
      <div className={"text-center"}>
        <h4 className={"card-title"}>Wczytuję</h4>
        <Indicator size={64} />
      </div>
    );
  }

  if (!props.isLoading && !props.collection.length) {
    return (
      <div className={"text-center"}>
        <h4 className={"card-title"}>Brak wyników do wyświetlenia</h4>
      </div>
    );
  }

  const rows = props.collection.map((i, idx) => {
    return (
      <tr key={`atr-${i.id}`}>
        <td className="text-center">
          {i.available ? <i className={"fas fa-check"} /> : null}
        </td>

        <td>{dateTimeFormat.format(new Date(i.created * 1000))}</td>
        <td>
          {i.modified
            ? dateTimeFormat.format(new Date(i.last_login * 1000))
            : "Nigdy"}
        </td>
        <td>{i.email}</td>
        <td className="text-center">
          {i.notify_newsletter ? <i className={"fas fa-check"} /> : null}
        </td>
        <td className="text-center">
          {i.notify_marketing ? <i className={"fas fa-check"} /> : null}
        </td>
        <td className="text-center">
          {i.notify_comments ? <i className={"fas fa-check"} /> : null}
        </td><td className="text-center">
          {i.notify_new_reply ? <i className={"fas fa-check"} /> : null}
        </td>
        <td className="text-right">
          <Button
            tag={Link}
            to={`/edytuj-profil/${i.slug}`}
            className="btn-icon mr-1 d-inline-flex justify-content-center align-items-center"
            color="success"
            size="sm"
            type="button"
          >
            <i className="far fa-edit"></i>
          </Button>
          {/*<Button className="btn-icon" color="danger" size="sm" type="button">
            <i className="far fa-trash-alt"></i>
          </Button>*/}
        </td>
      </tr>
    );
  });

  let resultsLabel = "Wynik";

  if (props.pagination.count > 1) {
    resultsLabel = "Wyniki";
  }
  if (props.pagination.count > 4) {
    resultsLabel = "Wyników";
  }

  return (
    <>
      <h4 className={"card-title"}>
        {props.pagination.count} {resultsLabel}
      </h4>
      <Table responsive>
        <thead>
          <tr>
            <th width={"5%"}></th>
            <SortableCol
              id={"created"}
              width={"12%"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Dodany
            </SortableCol>
            <SortableCol
              id={"modified"}
              width={"12%"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Edytowany
            </SortableCol>
            <th width={"15%"}>Newsletter</th>
            <th width={"15%"}>Marketing</th>
            <th width={"15%"}>Nowe komentarze</th>
            <th width={"15%"}>Aktywność</th>
            <th width={"13%"} className={"text-right"}>
              Akcje
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <TablePagination
        page={props.pagination.page || 0}
        pagesCount={props.pagination.pageCount || 0}
        linkBuilder={props.linkBuilder}
      />
    </>
  );
};

SubscribersTable.defaultProps = {};

SubscribersTable.propTypes = {
  onSort: PropTypes.func,
};

export default SubscribersTable;
