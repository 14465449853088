/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import CodeBlock from "../../components/TextFormatters/CodeBlock";
import LinkBlock from "../../components/TextFormatters/LinkBlock";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import ImageBlock from "../../components/TextFormatters/ImageBlock";

const ArticleBody = (props) => {
  return (
    <ReactMarkdown
      /*escapeHtml={true}*/
      className="markdown-content"
      components={{
        code: CodeBlock,
        a: LinkBlock,
        img: ImageBlock
        /*Image: ImageBlockComponent,*/
      }}
      remarkPlugins={[remarkGfm]}
    >
      {props.body}
    </ReactMarkdown>
  );
};

ArticleBody.defaultProps = {};

ArticleBody.propTypes = {};

export default ArticleBody;
