/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React, { useEffect, useRef } from "react";
import hljs from "highlight.js";
import PropTypes from "prop-types";
import 'highlight.js/styles/github.css';

const CodeBlock = (props) => {
  const codeRef = useRef();

  const lang = props.className && props.className.length ? props.className.replace("language-", "") : null;

  useEffect(() => {
    highlightCode();
  }, []);

  const highlightCode = () => {
    hljs.highlightElement(codeRef.current);
  };

  return (
    <pre>
      <code ref={codeRef} className={props.language}>
        {props.children}
      </code>
    </pre>
  );
};

CodeBlock.propTypes = {
  literal: PropTypes.string,
  language: PropTypes.string,
};

export default CodeBlock;
