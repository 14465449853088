/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as postActions from "./../actions/post";

export function usePostStore() {
  const dispatch = useDispatch();
  const _post = useSelector((store) => store.post);

  const fetchPost = useCallback(
    async (id) => await dispatch(postActions.fetchPost(id)),
    [dispatch]
  );

  const updatePost = useCallback(
    async (id, data, kind) => await dispatch(postActions.updatePost(id, data, kind)),
    [dispatch]
  );

  const votePost = useCallback(
    async (postId, vote) => await dispatch(postActions.votePost(postId, vote)),
    [dispatch]
  );

  const unvotePost = useCallback(
    async (postId) => await dispatch(postActions.unvotePost(postId)),
    [dispatch]
  );

  const uploadPostImage = useCallback(
    async (file, resource, changeData, resourceId, isCover) =>
      await dispatch(postActions.uploadPostImage(file, resource, changeData, resourceId, isCover)),
    [dispatch]
  );


  const deletePostImage = useCallback(
    async (imageId) =>
      await dispatch(postActions.deletePostImage(imageId)),
    [dispatch]
  );

  return {
    post: _post,
    fetchPost,
    updatePost,
    votePost,
    unvotePost,
    uploadPostImage,
    deletePostImage
  };
}
