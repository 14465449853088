/**
 * Created by leper on 20/01/2022
 */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, FormGroup, Input} from "reactstrap";

const CoverImageSelector = props => {

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview ]= useState(null);

  /**
   *
   * @param e
   */
  const onSelectCoverImage = (e) => {
    document.getElementById("cover_image").click();
  };


  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();

      reader.readAsDataURL(imageFile);

      reader.onloadend = (e) => {

        setImagePreview(e.target.result);
        props.onChange(imageFile)
      };
    }
    else {
      setImagePreview(null);
      props.onChange(null);
    }
  }, [imageFile]);

  /**
   *
   * @param e
   */
  const onCoverImageChange = (e) => {
    setImageFile( e.target.files[0]);

  };

  /**
   *
   * @param e
   */
  const onRemoveCoverImage = e => {
    setImageFile(null);
    props.onChange(null);
  }


  const removeCoverImgButton = imagePreview || props.previewImage ? (
    <Button color={"danger"} className={"btn-icon btn-round"} onClick={onRemoveCoverImage}>
      <i className={"fas fa-trash"} />
    </Button>
  ) : null;


  const image = imagePreview || props.previewImage ? (
    <img src={imagePreview || props.previewImage} className={"image-preview"} />
  ) : null;

  // console.log(image);

  return (
    <FormGroup className="col-md-12">
      <label htmlFor={"title"}>Zdjęcie w tle</label>
      <Input
        id={"cover_image"}
        type="file"
        className={"d-none"}
        onChange={onCoverImageChange}
      />
      <div className={"cover-image-upload-container"}>
        <div className={"upload-buttons"}>
          <Button
            color={"info"}
            className={"btn-icon btn-round"}
            onClick={onSelectCoverImage}
          >
            <i className={"fas fa-upload"} />{" "}
          </Button>
          {removeCoverImgButton}
        </div>
        <div className={"img-16x9"}>{image}</div>
      </div>
    </FormGroup>
  )
}

CoverImageSelector.defaultProps = {};

CoverImageSelector.propTypes = {
  previewImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired
}

export default CoverImageSelector;
