const initialState = {

  edit: false,
  editSuccess: false,
  editError: null,

  subscribe: false,
  subscribeSuccess: false,
  subscribeError: false,

  optOut: false,
  optOutSuccess: false,
  optOutError: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "MAILLIST_SUBSCRIBE":
      return {
        ...state,
        subscribe: true,
        subscribeSuccess: false,
        subscribeError: null,
      };

    case "MAILLIST_SUBSCRIBE_REJECTED":
      return {
        ...state,
        subscribe: false,
        subscribeError: action.payload,
      };

    case "MAILLIST_SUBSCRIBE_FULFILLED":
      return {
        ...state,
        subscribe: false,
        subscribeSuccess: true,
      };

    case "MAILLIST_UPDATE":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "MAILLIST_UPDATE_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload,
      };

    case "MAILLIST_UPDATE_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,
      };

    case "MAILLIST_OPTOUT":
      return {
        ...state,
        optOut: true,
        optOutSuccess: false,
        optOutError: null,
      };

    case "MAILLIST_OPTOUT_REJECTED":
      return {
        ...state,
        optOut: false,
        optOutError: action.payload,
      };

    case "MAILLIST_OPTOUT_FULFILLED":
      return {
        ...state,
        optOut: false,
        optOutSuccess: true,
      };


    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
      };
  }

  return state;
}
