/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

const Header = (props) => {
  return (
    <div className="page-header page-header-small">
      <div
        className="page-header-image"
        style={{
          backgroundImage: "url(/img/bg/bg07.svg)",
        }}
      ></div>
      <div className="content-center text-center">
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <h1 className="title">
              Narzędzia dla programistów w&nbsp;przeglądarce internetowej
            </h1>
            <h4 className="description text-white">
              Twoja przeglądarka, ta, z której teraz korzystasz, jest wyposażona
              w jedno z najbardziej zaawansowanych narzędzi dla programistów
              aplikacji webowych. W serii PJL - Przegladarka pokażę Tobie jak z
              niego korzystać.
            </h4>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
