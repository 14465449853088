/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import AxiosConfig from "../../AxiosConfig";
import ArticleBody from "./ArticleBody";
import { Link } from "react-router-dom";
import LikeButton from "./LikeButton";
import { useCommentStore } from "../../hooks/comment";
import classNames from "classnames";
import CommentForm from "./CommentForm";

const rtf = new Intl.RelativeTimeFormat("pl", {
  localeMatcher: "lookup", // other values: "lookup"
  numeric: "auto", // other values: "auto"
  style: "narrow", // other values: "short" or "narrow"
});

const IntlDate = new Intl.DateTimeFormat("pl", {
  dateStyle: "medium",
  timeStyle: "short",
});

const DIVISIONS = [
  { amount: 60, name: "seconds" },
  { amount: 60, name: "minutes" },
  { amount: 24, name: "hours" },
  { amount: 7, name: "days" },
  { amount: 4.34524, name: "weeks" },
  { amount: 12, name: "months" },
  { amount: Number.POSITIVE_INFINITY, name: "years" },
];

function formatTimeAgo(date) {
  let duration = date;

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return rtf.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}

const Comment = (props) => {
  const { id, user, votes, body, created, modified, user_vote } = props.comment;
  const { canEdit } = props;
  const { comment, deleteComment, setEditComment } = useCommentStore();
  const [deleting, setDeleting] = useState(false);


  const secondsAgo = (modified || created) - Number.parseInt(new Date().getTime() / 1000);

  const relativeTime = formatTimeAgo(secondsAgo);
  const modifiedLabel = modified ? 'edytowany ' : null;

  const isVoting = comment.vote && comment.commentVoteId === id;

  const onVote = useCallback(() => {
    props.onVote(props.comment);
  }, [props.comment.user_vote]);


  /**
   *
   * @param e
   */
  const onEdit = (e) => {
    setEditComment(props.comment);
  };

  /**
   *
   */
  const onEditCancel = () => {
    setEditComment(null);
  };

  /**
   *
   * @param e
   */
  const onDelete = (e) => {
    if (window.confirm("Na pewno chcesz usunąć ten komentarz?")) {
      setDeleting(true);
      deleteComment(id);
    }
  };

  const editDropdown = canEdit ? (
    <UncontrolledDropdown
      className="button-dropdown d-inline-block pull-right"
      color={"secondary"}
    >
      <DropdownToggle
        data-toggle="dropdown"
        id="navbarDropdown"
        className="btn-neutral btn-github"
        color="secondary"
        size="md"
      >
        <i className="fas fa-ellipsis-v"></i>
      </DropdownToggle>
      <DropdownMenu aria-labelledby="navbarDropdown">
        <DropdownItem onClick={onEdit}>Edytuj</DropdownItem>
        <DropdownItem onClick={onDelete}>Usuń</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : null;

  const content =
    comment.editCommentId !== id ? (
      <>
        <ArticleBody body={body} />

        <div className="media-footer">
          {editDropdown}
          {/*<Button
            className="btn-neutral pull-right"
            color="info"
            href="#pablo"
            id={`comment-${id}`}
            onClick={(e) => e.preventDefault()}
          >
            <i className="now-ui-icons ui-1_send"></i> Odpowiedz
          </Button>
          <UncontrolledTooltip delay={0} target={`comment-${id}`}>
            Odpowiedz na komentarz {user.nickname}
          </UncontrolledTooltip>
          */}
          <LikeButton
            userVoted={Number.parseInt(user_vote) !== 0}
            isLoading={isVoting}
            onVote={onVote}
            votes={votes}
            className={"btn-neutral pull-right pr-0"}
          />
        </div>
      </>
    ) : (
      <CommentForm
        isEditMode={true}
        onCancel={onEditCancel}
        defaultValue={body}
        commentId={id}
      />
    );

  return (
    <Media
      id={`cmnt-${id}`}
      className={classNames("comment", {
        fresh: props.isNewlyAdded,
        "bg-danger": deleting,
      })}
    >
      <Link
        className="pull-left"
        to={AxiosConfig.getResourceUrl(`/uzytkownik/${user.slug}`)}
        alt={`Link do profilu użytkownika ${user.nickname}`}
        onClick={(e) => e.preventDefault()}
      >
        <div className="avatar">
          <Media
            alt={`Avatar użytkownika ${user.nickname}`}
            title={`Avatar użytkownika ${user.nickname}`}
            className="img-raised"
            object
            src={AxiosConfig.getResourceUrl(`/avatars/${user.avatar}`)}
          ></Media>
        </div>
      </Link>
      <Media body>
        <Media heading tag="h5">
          {user.nickname}{" "}
          <small className="text-muted" id={`comment-timeago-${id}`}>
            · {modifiedLabel}{relativeTime}
          </small>
          <UncontrolledTooltip delay={0} target={`comment-timeago-${id}`}>
            {IntlDate.format(new Date(created * 1000))}
          </UncontrolledTooltip>
        </Media>

        {content}
      </Media>
    </Media>
  );
};

Comment.defaultProps = {};

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    created: PropTypes.number,
    user_vote: PropTypes.number,
    votes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onVote: PropTypes.func.isRequired,
  isNewlyAdded: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default Comment;
