import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
  NavLink,
  Badge,
} from "reactstrap";
import Logo from "../../components/Logo";
import classNames from "classnames";
import { useUserStore } from "../../hooks/user";
import UserAvatar from "../../components/UserAvatar";

const DropdownNavyNavbar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const { user, logout } = useUserStore();

  const isAdmin = user.model && user.model.role === "admin";

  const history = useHistory();

  /**
   *
   */
  const collapseNav = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(false);
  };

  /**
   *
   * @param e
   */
  const onLogout = (e) => {
    e.preventDefault();
    logout();
  };

  const mainLinks = [
    {
      link: "/blog",
      label: "Blog",
    },
    {
      link: "/slowniczek",
      label: "Słowniczek",
    },
    {
      link: "/poradnik",
      label: "Poradnik",
    },
    {
      link: "/101",
      label: "PJL 101",
    },
    {
      link: "/przegladarka",
      label: "DevTools w przeglądarce",
    },
    {
      link: "/sztuczna-inteligencja",
      label: "Sztuczna Inteligencja",
      isNew: true,
    },
    ,
  ].map((i, idx) => (
    <NavItem
      key={`nlm-${idx}`}
      className={classNames({
        active: history.location.pathname === i.link,
      })}
    >
      <NavLink tag={Link} to={i.link}>
        {i.label}{" "}
        {i.isNew ? (
          <Badge color="info" className="mr-1">
            Nowe!
          </Badge>
        ) : null}
      </NavLink>
    </NavItem>
  ));

  const loginOrUserMenu = user.model ? (
    <UncontrolledDropdown nav>
      <DropdownToggle
        nav
        caret
        data-toggle="dropdown"
        href="#"
        id="navbarDropdown"
        className={"d-flex align-items-center"}
        onClick={(e) => e.preventDefault()}
      >
        <UserAvatar user={user.model} size={28} />
      </DropdownToggle>
      <DropdownMenu aria-labelledby="navbarDropdown" right>
        <DropdownItem>Cześć {user.model.nickname}!</DropdownItem>
        <DropdownItem tag={Link} to={`/uzytkownik/${user.model.slug}`}>
          Profil
        </DropdownItem>
        {isAdmin ? (
          <>
            <DropdownItem header>Administracja</DropdownItem>

            <DropdownItem tag={Link} to={`/admin/artykuly`}>
              Artykuły
            </DropdownItem>
            <DropdownItem tag={Link} to={`/admin/uzytkownicy`}>
              Użytkownicy
            </DropdownItem>
            <DropdownItem tag={Link} to={`/admin/subskrybenci`}>
              Subskrybenci
            </DropdownItem>
            <DropdownItem header>
              <hr />
            </DropdownItem>
          </>
        ) : null}
        <DropdownItem onClick={onLogout}>Wyloguj</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button
      className="nav-link"
      color="info"
      tag={Link}
      to="/logowanie"
      onClick={collapseNav}
    >
      <p>Zaloguj</p>
    </Button>
  );

  return (
    <Container>
      <div className="navbar-translate">
        <NavbarBrand to="/" tag={Link} id="navbar-brand">
          <Logo className="logo-white xsmall" />
        </NavbarBrand>
        <UncontrolledTooltip target="navbar-brand">
          Programowanie Jest Łatwe!
        </UncontrolledTooltip>
        <button
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(!collapseOpen);
          }}
          aria-expanded={collapseOpen}
          className="navbar-toggler"
        >
          <span className="navbar-toggler-bar top-bar"></span>
          <span className="navbar-toggler-bar middle-bar"></span>
          <span className="navbar-toggler-bar bottom-bar"></span>
        </button>
      </div>
      {collapseOpen ? <div id="bodyClick" onClick={collapseNav} /> : null}

      <Navbar className={"fixed-top"} color="navy" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <Logo className="logo-white xsmall" />
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              Programowanie Jest Łatwe!
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              {mainLinks}

              <NavItem className={"dropdown"}>{loginOrUserMenu}</NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </Container>
  );
};

export default DropdownNavyNavbar;
