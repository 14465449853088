import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { history } from "../store";
/**
 *
 * @param email
 * @param password
 * @param provider
 * @param token
 * @param clickedObjectParentUuid
 */
export function login(email, password, provider, token) {
  return function (dispatch) {
    dispatch({ type: "USER_LOGIN" });

    axios
      .post(AxiosConfig.getEndpointAddress() + "/api/users/login.json", {
        email: email,
        password: password,
        provider: provider,
        token: token,
      })
      .then((response) => {
        dispatch({
          type: "USER_LOGIN_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_LOGIN_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 * @param nickname
 * @param email
 * @param password
 */
export function register(
  nickname,
  email,
  password,
  newsletterConsent,
  provider,
  token
) {
  return function (dispatch) {
    dispatch({ type: "USER_REGISTER" });

    axios
      .post(AxiosConfig.getEndpointAddress() + "/api/users.json", {
        nickname,
        email,
        password,
        newsletter_consent: newsletterConsent,
        provider,
        token,
      })
      .then((response) => {
        dispatch({
          type: "USER_REGISTER_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_REGISTER_REJECTED",
          payload: error,
        });
      });
  };
}

/**
 *
 *
 */
export const deleteUser = () => {
  return async (dispatch) => {
    dispatch({ type: "USER_DELETE" });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + `/api/users/delete.json`,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_DELETE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_DELETE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param slug, data
 */
export const updateUser = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: "USER_EDIT", id });

    await axios
      .put(
        AxiosConfig.getEndpointAddress() + `/api/users/${id}.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_EDIT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_EDIT_REJECTED",
          payload: error,
          id,
        });
      });
  };
};

/**
 *
 * @param slug, image
 */
export const uploadAvatar = (id, image) => {
  return async (dispatch) => {
    dispatch({ type: "USER_AVATAR_EDIT" });

    const data = new FormData();
    data.append("avatar", image);
    data.append("id", id);

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/users/avatar_upload.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_AVATAR_EDIT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_AVATAR_EDIT_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param email
 * @returns {Function}
 */
export const remindPassword = (email) => {
  return async (dispatch) => {
    dispatch({ type: "USER_PASSWORD_REMIND" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/users/remind_password.json";

    await axios
      .post(
        url,
        {
          email,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        dispatch({
          type: "USER_PASSWORD_REMIND_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_PASSWORD_REMIND_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @returns {Function}
 */
export const logout = () => {
  return function (dispatch) {
    dispatch({ type: "USER_LOGOUT" });
  };
};

/**
 *
 * @param email
 * @param token
 * @param password
 * @returns {(function(*): Promise<void>)|*}
 */
export const resetPassword = (email, token, password) => {
  return async (dispatch) => {
    dispatch({ type: "USER_RESET_PASSWORD" });

    const url =
      AxiosConfig.getEndpointAddress() +
      "/api/users/reset_password/" +
      email +
      "/" +
      token +
      ".json";

    await axios
      .put(
        url,
        {
          email,
          token,
          password,
        },
        AxiosConfig.getConfig()
      )
      .then((response) => {
        AxiosConfig.setAuthToken(response.data.token);

        dispatch({
          type: "USER_RESET_PASSWORD_FULFILLED",
          data: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_RESET_PASSWORD_REJECTED",
          data: error,
        });
      });
  };
};

/**
 *
 * @returns {function(*): Promise<void>}
 */
export const checkSession = () => {
  return async (dispatch) => {
    dispatch({ type: "USER_SESSION_CHECK" });

    const url =
      AxiosConfig.getEndpointAddress() + "/api/users/check_session.json";

    await axios
      .get(url, AxiosConfig.getAuthConfig())
      .then((response) => {
        dispatch({
          type: "USER_SESSION_CHECK_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "USER_SESSION_CHECK_REJECTED",
          payload: error,
        });
      });
  };
};
