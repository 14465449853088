/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/04/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import { Button, Col, Container, Row } from "reactstrap";
import { usePostsStore } from "../hooks/posts";
import Header from "./WebBrowserIndexPage/Header";
import Indicator from "../components/Indicator";
import SeoBlock from "../components/SeoBlock";
import AxiosConfig from "../AxiosConfig";
import WebPage from "../components/Schemas/WebPage";
import { Link } from "react-router-dom";
import EpisodeThumbnail from "./Pjl101IndexPage/EpisodeThumbnail";

const WebBrowserIndexPage = (props) => {
  const { posts, cleanupCollection, fetchLastPosts } = usePostsStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {};
  }, []);

  useEffect(() => {
    loadMore(1);
    return cleanupCollection;
  }, []);

  /**
   *
   * @param page
   */
  const loadMore = (page) => {
    fetchLastPosts("video", page, ["przegladarka"], "asc", "publish_date");
  };

  const postsCollection = [...posts.collection];

  for (let i = postsCollection.length; i < 9; i++) {
    postsCollection.push({
      title: `Odcinek ${i + 1} - premiera wkrótce`,
      images: [{}],
    });
  }

  const articles = postsCollection.map((item, idx) => {
    return <EpisodeThumbnail key={`pjl101-c-${idx}`} post={item} sm={4} />;
  });

  /*
  .map(i => {
    console.log(i);
  });*/

  const loaderContainer = (
    <Col xs={12} className="text-center">
      <Indicator size={32} />
    </Col>
  );

  const loader = posts.fetch ? loaderContainer : null;

  return (
    <PageTemplate>
      <SeoBlock
        title={"Narzędzia dla programistów w przeglądarce internetowej"}
        description={
          "Twoja przeglądarka, ta, z której teraz korzystasz, jest wyposażona w jedno z najbardziej zaawansowanych narzędzi dla programistów aplikacji webowych."
        }
        keywords={
          "programowanie jest łatwe, nauka programowania, od czego zacząć, podstawy programowania, warsztaty, informatyka, algorytmy"
        }
        image={"/img/bg/bg1.png"}
        author={"Programowanie jest łatwe"}
        type="website"
      />
      <WebPage
        name={"Narzędzia dla programistów w przeglądarce internetowej"}
        description={
          "Twoja przeglądarka, ta, z której teraz korzystasz, jest wyposażona w jedno z najbardziej zaawansowanych narzędzi dla programistów aplikacji webowych."
        }
        imageUrl={AxiosConfig.getResourceUrl("/img/bg/bg1.png")}
        url={AxiosConfig.getResourceUrl(`/przegladarka`)}
      />
      <Header />
      <div className="main">
        <Container>
          <div className={"section"}>
            <Col className="ml-auto mr-auto" md="12">
              <Container>
                <div className="section-pjl101-overview">
                  <Row>
                    <Col md="7">
                      <div className="img-16x9 position-relative">
                        <iframe
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            textAlign: "center",
                          }}
                          controls={"0"}
                          src="https://www.youtube-nocookie.com/embed/oq6IGCtCjDQ"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          modestbranding={"0"}
                        ></iframe>
                      </div>
                    </Col>
                    <Col md="5">
                      <h3>9 potężnych narzędzi prostych w obsłudze</h3>
                      <p>
                        Programowanie to nie tylko pisanie kodu. Rozkładając
                        czyjś program na cznniki pierwsze możemy bardzo dużo się
                        nauczyć. Narzędzia dla programistów pozwalają na
                        dekompozycję aplikacji. Prawie tak samo, gdybyśmy
                        rozebrali auto do ostatniej śrubki w warsztacie
                        samochodowym. Z tym, że trwa to ułamek sekundy, możliwa
                        jest dowolna modyfikacja i zawsze można zrobić ctrl+z.
                      </p>
                      <div className={"text-center"}>
                        <Button
                          tag={Link}
                          to={"/subskrybuj"}
                          className={"btn-subscribe"}
                          color={"danger"}
                        >
                          Subskrybuj
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </div>

          <div className="">
            <Row>
              <Col xs={12}>
                <h3>Odcinki</h3>
              </Col>
              {articles}
              {loader}
            </Row>
          </div>
        </Container>
      </div>
    </PageTemplate>
  );
};

WebBrowserIndexPage.defaultProps = {};

WebBrowserIndexPage.propTypes = {};

export default WebBrowserIndexPage;
