/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/02/2020.
 * eneurs -
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useAppStore } from "../hooks/app";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const CookiePopup = (props) => {
  const [showPreferences, setShowPreferences] = useState(false);

  const { app, acceptCookies } = useAppStore();

  useEffect(() => {
    if (app.cookies && app.cookies.functional) {
      const script = document.createElement("script");
      script.src = "/js/autotrack.js";
      script.async = true;
      script.id = "autotrack";

      document.body.appendChild(script);

      const gascript = document.createElement("script");
      gascript.innerHTML =
        "  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){\n" +
        "      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),\n" +
        "      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)\n" +
        "    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');\n" +
        "\n" +
        "      ga('create', 'UA-103091385-1', 'auto');\n" +
        "      ga('send', 'pageview');";
      gascript.async = true;
      gascript.id = "ga-autotrack";

      if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
        document.body.appendChild(gascript);
      }
    }
  }, [app.cookies]);

  /**
   *
   * @param e
   */
  const onPreferencesClick = (e) => {
    setShowPreferences(true);
  };

  /**
   *
   */
  const onAcceptCookie = (options) => (e) => {
    acceptCookies(options);
  };

  /**
   *
   * @param e
   */
  const onAcceptSomeCookie = (e) => {
    const options = {
      marketing: document.getElementById("cookie-marketing").checked,
      functional: document.getElementById("cookie-functional").checked,
    };

    acceptCookies(options);
  };

  const disclaimer = (
    <small>
      Ta strona używa plików „cookies” – informacji zarejestrowanych w tych
      plikach używamy m.in. w celach reklamowych, statystycznych oraz w celu
      dostosowania naszych serwisu do indywidualnych potrzeb Użytkowników.
      Witryna może udostępniać te dane podmiotom trzecim – w tym partnerom w
      mediach społecznościowych, takim jak Google i Meta (Facebook), YouTube – w
      celach marketingowych. Możesz zmienić ustawienia dotyczące „cookies” w
      swojej przeglądarce internetowej, może to jednak ograniczyć funkcjonalność
      serwisu. Szczegóły dotyczące działania cookies znajdziesz w{" "}
      <a href="/legal/polityka-prywatności" rel="nofollow" target="_blank">
        Polityce Prywatności
      </a>
      .
    </small>
  );

  const preferencesSettings = showPreferences ? (
    <>
      <p className={"mt-3 text-left"}>
        Zaznacz i zaakceptuj swoje preferencje dotyczące śledzenia:
      </p>
      <p>{disclaimer}</p>
      <FormGroup check>
        <Label check>
          <Input type="checkbox" id={"cookie-marketing"}></Input>
          <span className="form-check-sign"></span>
          Marketingowe
          <p>
            <small>
              Firma The Beaverhead LTD wraz ze swoimi partnerami reklamowymi (w
              tym Google, YouTube i Meta – dawniej Facebook) korzysta z funkcji
              śledzenia, aby dostarczać spersonalizowane reklamy i oferty, co
              obejmuje także wysyłanie spersonalizowanych wiadomości na
              zewnętrznych platformach reklamowych. Jeżeli nie zaakceptujesz
              tego śledzenia, nadal będziesz widzieć wyświetlane losowo reklamy
              firmy na innych platformach.
            </small>
          </p>
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            value={1}
            defaultChecked
            id={"cookie-functional"}
          ></Input>
          <span className="form-check-sign"></span>
          Funkcjonalne
          <p>
            <small>
              Funkcjonalne pliki cookie służą do analizowania sposobu
              korzystania z Serwisu. Pomagają wykrywać błędy i je naprawiać oraz
              testować skuteczność działania Serwisu. Dostarczają również
              informacji do analizy reklamowej i marketingu partnerskiego.
            </small>
          </p>
        </Label>
      </FormGroup>
      <div>
        <Button
          onClick={onAcceptCookie}
          onClick={onAcceptCookie({ marketing: true, functional: true })}
        >
          Zaakceptuj wszystkie
        </Button>
        <Button onClick={onAcceptSomeCookie} color={"info"}>
          Zaakceptuj wybrane
        </Button>
      </div>
    </>
  ) : null;

  const content = !showPreferences ? (
    <>
      <p className={"mt-3"}>
        Nie jesteśmy wyjątkowi. Nasza strona używa Cookies. Zapoznaj się z&nbsp;
        <a href="/legal/polityka-prywatności" rel="nofollow" target="_blank">
          Polityką Prywatności
        </a>
        .
        <br />
      </p>
      <p>{disclaimer}</p>
      <Button onClick={onAcceptCookie} onClick={onPreferencesClick}>
        Preferencje
      </Button>
      <Button
        onClick={onAcceptCookie}
        color={"info"}
        onClick={onAcceptCookie({ marketing: true, functional: true })}
      >
        Zaakceptuj
      </Button>
    </>
  ) : (
    preferencesSettings
  );

  return (
    <div
      id="swUpdateToast"
      className={
        "page__update-notification-wrap" + (app.cookies === null ? " show" : "")
      }
    >
      <Container className={"position-relative"}>
        <img
          src={"/img/cookie.png"}
          alt={"Obrazek przedstawiający nadgryzione ciasteczko"}
        />
      </Container>
      <div className={"cookie-container"}>
        <Container>
          <Row>
            <Col md={12}>{content}</Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CookiePopup;
