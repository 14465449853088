/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import Comment from "./Comment";

const Comments = (props) => {
  if (!props.comments || !props.comments.length) {
    return null;
  }

  const comments = props.comments.map((i, idx) => (
    <Comment
      key={i.id}
      comment={i}
      onVote={props.onVoteComment}
      isNewlyAdded={props.createdCommentId === i.id}
      canEdit={
        props.user &&
        (props.user.role === "admin" || props.user.id === i.user.id)
      }
    />
  ));

  return (
    <div className="section section-comments">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <div className="media-area">
              <h3 className="title text-center">
                {props.comments_count > 0
                  ? `Komentarzy: ${props.comments_count}`
                  : "Brak komentarzy"}
              </h3>
              {comments}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Comments.defaultProps = {
  comments: [],
};

Comments.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      created: PropTypes.number,
      user_vote: PropTypes.number,
      votes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  comments_count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  createdCommentId: PropTypes.string,
  user: PropTypes.object,
};

export default Comments;
