/**
 * Created by piotr.pozniak@thebeaverhead.com on 26/08/2017.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, Input } from "reactstrap";
import TabManager from "../tools/TabManager";
import { updateCaretPositionAfterPhotoUpload } from "../tools/text";
import Indicator from "./Indicator";

const Wysiwyg = (props) => {
  const { isLoading } = props;

  useEffect(() => {
    if (props.changeBody) {
      updateCaretPositionAfterPhotoUpload.call(
        this,
        `#${props.inputId}`,
        props.changeBody
      );
    }
  }, [props.changeBody]);

  /**
   *
   */
  const addPhotoUrl = (image) => {
    let txtarea = document.getElementById(props.inputId);
    if (!txtarea) {
      return;
    }

    let strPos = 0;
    let br =
      txtarea.selectionStart || txtarea.selectionStart == "0"
        ? "ff"
        : document.selection
        ? "ie"
        : false;

    strPos = txtarea.selectionStart;

    let initialPosition = strPos;

    let front = txtarea.value.substring(0, strPos);
    let back = txtarea.value.substring(strPos, txtarea.value.length);

    let changeData = {
      front: front,
      back: back,
      cursorPosition: initialPosition,
    };

    props.uploadImage(image, props.resourceType, changeData, props.resourceId);
  };

  const onSelectImage = () => {
    document.querySelector("#wysiwyg-add-photo").value = null;
    document.querySelector("#wysiwyg-add-photo").click();
  };
  /**
   *
   * @param e
   */
  const onUploadImage = (e) => {
    if (e.target.files[0]) {
      addPhotoUrl(e.target.files[0]);
    }
  };

  const onKeyDown = (keyEvent) => {
    let textBox = document.getElementById(props.inputId);
    TabManager.enableTab(textBox, keyEvent);
  };

  const label = props.label ? (
    <label htmlFor={props.inputId}>Treść</label>
  ) : null;

  const indicator = isLoading ? <Indicator size={28} /> : null;

  return (
    <FormGroup className="col-md-12 wysiwyg-containe">
      {label}
      <ul className={"list-group"}>
        <li className={"list-group-item"}>
          <Button
            size={"sm"}
            color={"secondary"}
            className={"btn-wysiwy"}
            onClick={onSelectImage}
          >
            <i className={"fas fa-image"}></i> Dodaj zdjęcie
          </Button>
          <input
            id="wysiwyg-add-photo"
            type="file"
            className="d-none"
            accept="image/*"
            onChange={onUploadImage}
            disabled={isLoading}
          />
          {indicator}
        </li>
        <li className={"list-group-item"}>
          <Input
            id={props.inputId}
            placeholder={props.placeholder}
            type="textarea"
            style={{ minHeight: "300px" }}
            disabled={isLoading}
            onKeyDown={onKeyDown}
            defaultValue={props.defaultValue}
          />
        </li>
      </ul>
    </FormGroup>
  );
};

Wysiwyg.defaultProps = {
  placeholder: "Treść artykułu. Użyj markdown do formatowania tekstu.",
  label: "Treść",
};

Wysiwyg.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  resourceType: PropTypes.oneOf(["post", "comment"]),
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.string,
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  resourceId: PropTypes.string,
};
export default Wysiwyg;
