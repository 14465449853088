export default function reducer(
  state = {
    collection: [],
    pagination: null,

    fetch: false,
    fetchSuccess: false,
    fetchError: null,
  },
  action) {

  switch (action.type) {

    case "CLEANUP_COLLECTION_POSTS":
      return {
        collection: [],

        fetch: false,
        fetchSuccess: false,
        fetchError: null,
      }

    case "FETCH_LAST_POSTS":
      return {
        ...state,

        //collection: null,

        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "FETCH_LAST_POSTS_REJECTED":
      return {
        ...state,

        //collection: null,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "FETCH_LAST_POSTS_FULFILLED":

      return {
        ...state,

        collection: state.collection.concat(action.payload.posts),
        pagination: action.payload.pagination,

        fetch: false,
        fetchSuccess: true,
      }
  }

  return state;
}
