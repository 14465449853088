/**
 *
 */

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

export default class Schema extends React.Component {
  static defaultProps = {

  };

  constructor(props) {
    super(props);
  }


  /**
   *
   */
  componentDidMount() {

    if (this.props.clear) {
      this.clearStructuredHead();
    }
  }



  /**
   *
   * @param json
   */
  jsonToHead(json) {

    let el = document.createElement('script');
    el.type = 'application/ld+json';
    el.text = JSON.stringify(json);
    el.className = "structured"

    document.querySelector('head').appendChild(el);

  }


  /**
   *
   */
  clearStructuredHead() {

    Schema.forceClearStructuredHead();
  }


  static forceClearStructuredHead() {
    const data = document.getElementsByClassName('structured');

    for (let i = data.length-1; i >= 0; --i) {
      data[i].remove();
    }

  }




  render() {


    return null;
  }
}

Schema.propTypes = {

  clear: PropTypes.bool

};
