/**
 * Created by leper on 08/01/2022
 */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import AxiosConfig from "../../AxiosConfig";
import LikeButton from "./LikeButton";
import CommentsButton from "./CommentsButton";
import DateStamp from "../platform/components/DateStamp";
import { useUserStore } from "../../hooks/user";
import { Link } from "react-router-dom";

const Header = (props) => {
  const pageHeader = useRef();
  const { user } = useUserStore();

  useEffect(() => {
    if (window.innerWidth > 991 && pageHeader.current) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  const { title, coverImage } = props;

  const bgImage = coverImage ? AxiosConfig.getResourceUrl(coverImage.thumb_md_path) : null;
  const bgImagePreview = coverImage ? {backgroundImage: `url('${coverImage.preview}')`} : null;
  const postCreated = props.created ? (
    <>
      <i className="now-ui-icons tech_watch-time"></i>{" "}
      <DateStamp time={props.created} />
    </>
  ) : null;

  const canEdit =
    user.model && (user.model.id == props.id || user.model.role === "admin");

  const editDropdown = canEdit ? (
    <UncontrolledDropdown className="button-dropdown d-inline-block">
      <DropdownToggle
        data-toggle="dropdown"
        id="navbarDropdown"
        className="btn-round btn-icon mr-1"
        color="secondary"
        size="md"
      >
        <i className="fas fa-ellipsis-v"></i>
      </DropdownToggle>
      <DropdownMenu aria-labelledby="navbarDropdown">
        <DropdownItem tag={Link} to={`/edytuj-artykul/${props.slug}`}>
          Edytuj artykuł
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : null;

  return (
    <div className="page-header page-header-small " >
      <div className={"img-preview-header page-header-image"} style={bgImagePreview}/>
      <div
        className="page-header-image"
        style={{
          backgroundImage: `url(${bgImage})`,
        }}
        ref={pageHeader}
      ></div>
      <div className="content-center">
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            {postCreated}
            <h2 className="title">{title}</h2>
            <LikeButton
              votes={props.votes}
              onVote={props.onVote}
              userVoted={props.userVoted}
              isLoading={props.voting}
            />

            <CommentsButton commentsCount={props.commentsCount} />
            {editDropdown}
          </Col>
        </Row>
      </div>
    </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  id: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  coverImage: PropTypes.object,
  votes: PropTypes.number,
  commentsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userVoted: PropTypes.bool,
  created: PropTypes.number,
  onVote: PropTypes.func,
  voting: PropTypes.bool,

};

export default Header;
