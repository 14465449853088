/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/02/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import { Button, Col, Container, Row } from "reactstrap";
import { usePostsStore } from "../hooks/posts";
import BlogCard from "./BlogIndexPage/BlogCard";
import Header from "./ArticlesIndexPage/Header";
import Indicator from "../components/Indicator";
import SeoBlock from "../components/SeoBlock";
import AxiosConfig from "../AxiosConfig";
import WebPage from "../components/Schemas/WebPage";
import InfiniteScroll from "react-infinite-scroll-component";

const BlogIndexPage = (props) => {
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [showMore, setShowMore] = React.useState(true);

  const { posts, cleanupCollection, fetchLastPosts } = usePostsStore();

  useEffect(() => {
    fetchLastPosts(null, 1, ["lifestyle"]);
    return cleanupCollection;
  }, []);

  const pagination = posts.pagination || {};
  const hasMorePosts = pagination.end < pagination.count;

  /**
   *
   * @param page
   */
  const loadMore = () => {
    fetchLastPosts(null, pagination.page + 1, ["lifestyle"]);
  };

  /**
   *
   */
  const onShowMoreClick = () => {
    setShowMore(false);
  };

  const articles = posts.collection.length
    ? posts.collection.map((item) => {
        return <BlogCard key={item.uuid} post={item} />;
      })
    : null;

  const loaderContainer = (
    <Col xs={12} className="text-center">
      <Indicator size={32} />
    </Col>
  );

  const showMoreBtn =
    showMore && !posts.fetch ? (
      <Col xs={12} className="text-center">
        <Button
          className="btn-round"
          color="info"
          type="button"
          onClick={onShowMoreClick}
        >
          Pokaż więcej artykułów...
        </Button>
      </Col>
    ) : null;

  const loader = posts.fetch ? loaderContainer : null;

  return (
    <PageTemplate>
      <SeoBlock
        title={"Blog"}
        description={"Treści lifestylowe, luźno o technologiach i IT."}
        keywords={
          "programowanie jest łatwe, nauka programowania, od czego zacząć, podstawy programowania, warsztaty, informatyka, algorytmy"
        }
        image={"/img/bg/bg02.svg"}
        author={"Programowanie jest łatwe"}
        type="website"
      />
      <WebPage
        name={"Blog - Programowanie Jest Łatwe"}
        description={"Treści lifestylowe, luźno o technologiach i IT."}
        imageUrl={AxiosConfig.getResourceUrl("/img/bg/bg27.jpg")}
        url={AxiosConfig.getResourceUrl(`/artykuly`)}
      />
      <Header
        bgImgSrc={"/img/bg/bg02.svg"}
        title={"Treści lifestylowe, luźno o technologiach i&nbsp;IT."}
      />
      <div className="main">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto mt-3" md="8">
              <InfiniteScroll
                className={"pb-3"}
                next={loadMore}
                hasMore={!showMore && hasMorePosts}
                loader={loaderContainer}
                dataLength={posts.collection.length}
              >
                {articles}
              </InfiniteScroll>
              {showMoreBtn}
            </Col>
          </Row>
        </Container>

        {/*<div className="subscribe-line subscribe-line-white">
          <Container>
            <Row>
              <Col md="6">
                <h4 className="title">Get Tips &amp; Tricks every Week!</h4>
                <p className="description">
                  Join our newsletter and get news in your inbox every week! We
                  hate spam too, so no worries about this.
                </p>
              </Col>
              <Col md="6">
                <Card className="card-plain card-form-horizontal">
                  <div className="card-content">
                    <Form action="" method="">
                      <Row>
                        <Col sm="8">
                          <InputGroup
                            className={emailFocus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons ui-1_email-85"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email Name..."
                              type="text"
                              onFocus={() => setEmailFocus(true)}
                              onBlur={() => setEmailFocus(false)}
                            ></Input>
                          </InputGroup>
                        </Col>
                        <Col sm="4">
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            type="button"
                          >
                            Subscribe
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>*/}
      </div>
    </PageTemplate>
  );
};

BlogIndexPage.defaultProps = {};

BlogIndexPage.propTypes = {};

export default BlogIndexPage;
