/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, matchPath } from "react-router-dom";

import AxiosConfig from "./AxiosConfig";

import { BrowserRouter } from "react-router-dom";

import { history } from "./store";


import MainRoutesComponent from "./MainRoutesComponent";
import ModalRoutesComponent from "./ModalRoutesComponent";
import { useUserStore } from "./hooks/user";
import {useAppStore} from "./hooks/app";

const Router = (props) => {
  const { user, checkSession, logout } = useUserStore();
  const {clearErrors} = useAppStore();

  if (!AxiosConfig._token && user.token) {
    AxiosConfig.setAuthToken(user.token);
  }

  useEffect(() => {
    //clearErrors();
    if (user.token) {
      checkSession();
    }
  }, []);

  useEffect(() => {
    if (user.sessionExpired) {
      logout();
      clearErrors();
    }
  }, [user.sessionExpired])

  const modalLinks = [
    "/logowanie",
    "/rejestracja",
    "/przypomnienie-hasla",
    "/edytuj-profil",
    "/edytuj-powiadomienia",
    "/nowy-artykul",
    "/edytuj-artykul/:slug",
    "/subskrybuj",
  ];

  const prevModalLocation = [
    {
      "/logowanie": `/`,
    },
    {
      "/rejestracja": `/`,
    },
    {
      "/przypomnienie-hasla": `/`,
    },
    {
      "/edytuj-profil": `/`,
    },
    {
      "/nowy-artykul": `/`,
    },
    {
      "/edytuj-artykul/:slug": `/`,
    },
    {
      "/edytuj-powiadomienia": `/`,
    },
    {
      "/subskrybuj": `/`,
    },
  ];

  return (
    <BrowserRouter>
      <MainRoutesComponent
        key={user.model ? user.model.id : "none"}
        modalLinks={modalLinks}
      />
      <ModalRoutesComponent
        modalLinks={modalLinks}
        prevModalLocation={prevModalLocation}
      />

    </BrowserRouter>
  );
};

export default Router;
