/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/01/2022.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export const subscribeMailList = (email) => {
  return async (dispatch) => {
    dispatch({ type: "MAILLIST_SUBSCRIBE" });

    const data = {
      email,
      notify_newsletter: true,
      notify_marketing: true,
      notify_new_reply: true,
      notify_new_comments: true,
    };

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + `/api/email_subscribers.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "MAILLIST_SUBSCRIBE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MAILLIST_SUBSCRIBE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateMailList = (preferences, email, accessToken = null) => {
  return async (dispatch) => {
    dispatch({ type: "MAILLIST_UPDATE" });

    const data = {...preferences, email};

    await axios
      .put(
        AxiosConfig.getEndpointAddress() +
          `/api/email_subscribers/${accessToken}.json`,
        data,
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: "MAILLIST_UPDATE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MAILLIST_UPDATE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export const optOutMailList = (email, accessToken) => {
  return async (dispatch) => {
    dispatch({ type: "MAILLIST_OPTOUT" });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() +
          `/api/email_subscribers/${email}/${accessToken}.json`
      )
      .then((response) => {
        dispatch({
          type: "MAILLIST_OPTOUT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "MAILLIST_OPTOUT_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export const fetchMailList = (queryParams) => {
  return async (dispatch) => {
    dispatch({ type: "ADMIN_MAILLIST_FETCH" });

    await axios
      .get(
        AxiosConfig.getEndpointAddress() +
          `/api/email_subscribers.json?` +
          AxiosConfig.objectToURLQuery(queryParams),
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "ADMIN_MAILLIST_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "ADMIN_MAILLIST_FETCH_REJECTED",
          payload: error,
        });
      });
  };
};
