/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardTitle, Col, Container, Row } from "reactstrap";
import AxiosConfig from "../../AxiosConfig";
import { Link } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar";

const Author = (props) => {
  const { user } = props;
  

  const userProfileUrl = `/uzytkownik/${user.slug}`;
  return (
    <Card className="card-profile card-plain">
      <Row>
        <Col md="2">
          <div className="card-avatar">
            <Link to={userProfileUrl}>
              <UserAvatar user={user} size={96} />
            </Link>
            <div className="ripple-container"></div>
          </div>
        </Col>
        <Col md="10">
          <CardTitle tag="h4">
            <Link to={userProfileUrl}>{user.nickname}</Link>
          </CardTitle>
          <p className="description">{user.bio}</p>
        </Col>
        {/*<Col md="2">
                <Button
                  className="pull-right btn-round"
                  color="default"
                  type="button"
                >
                  Follow
                </Button>
              </Col>*/}
      </Row>
    </Card>
  );
};

Author.defaultProps = {};

Author.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string,
    nickname: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
};

export default Author;
