import React, { useEffect } from "react";
import { Route, Switch, withRouter, matchPath } from "react-router-dom";
import PageTemplate from "./templates/PageTemplate";
import LandingPage from "./pages/LandingPage";
import ArticlesIndexPage from "./pages/ArticlesIndexPage";
import ArticlePage from "./pages/ArticlePage";
import ProfilePage from "./pages/ProfilePage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import Pjl101IndexPage from "./pages/Pjl101IndexPage";
import VideoPage from "./pages/VideoPage";
import { useUserStore } from "./hooks/user";

import AdminArticlesIndexPage from "./pages/Admin/ArticlesIndexPage";
import UsersIndexPage from "./pages/Admin/UsersIndexPage";
import SubscribersIndexPage from "./pages/Admin/SubscribersIndexPage";
import CookiePopup from "./components/CookiePopup";
import BlogIndexPage from "./pages/BlogIndexPage";
import DictIndexPage from "./pages/DictIndexPage";
import HintsIndexPage from "./pages/HintsIndexPage";
import WebBrowserIndexPage from "./pages/WebBrowserIndexPage";
import AIIndexPage from "./pages/AIIndexPage";

let previousMainComponentLocation = null;
const MainRoutesComponent = React.memo(
  (props) => {
    const { user } = useUserStore();
    const userModel = user.model;

    useEffect(() => {
      previousMainComponentLocation = props.location;
    }, []);

    return (
      <Switch location={props.location}>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          {/*
          <Route path="/artykuly" component={ArticlesIndexPage} />
*/}
          <Route path="/artykul/:slug" component={ArticlePage} />
          <Route path="/blog" component={BlogIndexPage} />
          <Route path="/slowniczek" component={DictIndexPage} />
          <Route path="/poradnik" component={HintsIndexPage} />
          <Route path="/101/" component={Pjl101IndexPage} />
          <Route path="/przegladarka/" component={WebBrowserIndexPage} />
          <Route path="/sztuczna-inteligencja/" component={AIIndexPage} />
          <Route path="/video/:slug" component={VideoPage} />
          <Route path="/uzytkownik/:slug" component={ProfilePage} />
          <Route
            path="/reset-hasla/:email/:token"
            component={ResetPasswordPage}
          />

          {userModel && userModel.role === "admin" ? (
            <>
              <Route
                path="/admin/artykuly"
                component={AdminArticlesIndexPage}
              />
              <Route path="/admin/uzytkownicy" component={UsersIndexPage} />
              <Route
                path="/admin/subskrybenci"
                component={SubscribersIndexPage}
              />
            </>
          ) : null}
        </Switch>
        <Route component={PageTemplate} />
      </Switch>
    );
  },
  (oldProps, newProps) => {
    let keepContent = true;

    if (oldProps.location.pathname !== newProps.location.pathname) {
      // if next location is one of the modals with static path
      const modalLinkIndex = oldProps.modalLinks.findIndex((modalLink) => {
        const match = matchPath(newProps.location.pathname, {
          path: modalLink,
          exact: true,
          strict: false,
        });
        return match !== null;
      });

      // don't update, it's a modal opened
      if (modalLinkIndex >= 0) {
        keepContent = true;
      } else if (
        // don't update, locations are the same
        previousMainComponentLocation.pathname === newProps.location.pathname
      ) {
        keepContent = true;
      } else {
        // update, locations are different
        previousMainComponentLocation = newProps.location;
        keepContent = false;
      }
    }

    return keepContent;
  }
);

export default withRouter(MainRoutesComponent);
