/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as mailListActions from "./../actions/mailList";
import { optOutMailList, updateMailList } from "./../actions/mailList";

export function useMailListStore() {
  const dispatch = useDispatch();
  const _mailList = useSelector((store) => store.mailList);

  const subscribeMailList = useCallback(
    async (email) => await dispatch(mailListActions.subscribeMailList(email)),
    [dispatch]
  );

  const updateMailList = useCallback(
    async (preferences, email, accessToken) =>
      await dispatch(
        mailListActions.updateMailList(preferences, email, accessToken)
      ),
    [dispatch]
  );

  const optOutMailList = useCallback(
    async (mail, accessToken) =>
      await dispatch(mailListActions.optOutMailList(mail, accessToken)),
    [dispatch]
  );

  return {
    mailList: _mailList,
    subscribeMailList,
    updateMailList,
    optOutMailList,
  };
}
