/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import Header from "./ProfilePage/Header";
import { useUserStore } from "../hooks/user";
import { Container } from "reactstrap";
import { useProfileStore } from "../hooks/profile";

const ProfilePage = (props) => {
  const [profileLoaded, setProfileLoaded] = useState(false);

  const { profile, fetchProfile } = useProfileStore();

  const profileModel = profile.model;

  useEffect(() => {
    document.body.classList.add("profile-page");
    fetchProfile(props.match.params.slug);

    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []);

  useEffect(() => {
    if (profile.fetchSuccess) {
      setProfileLoaded(true);
    }
  }, [profile.fetchSuccess]);

  const bio = profileLoaded && profileModel.bio && profileModel.bio.length ? (
    <>
      <h3 className="title">O mnie</h3>
      <h5 className="description text-center">{profileModel.bio}</h5>
    </>
  ) : null;


  return (
    <PageTemplate>
      <Header profile={profileModel} />
      <div className="section">
        <Container>{bio}</Container>
      </div>
    </PageTemplate>
  );
};

ProfilePage.defaultProps = {};

ProfilePage.propTypes = {};

export default ProfilePage;
