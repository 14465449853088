/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/07/2017.
 */

import React from "react";
import PropTypes from "prop-types";

export default class Indicator extends React.Component {
  render() {
    return (
      <svg
        className="spinner"
        width={this.props.size + "px"}
        height={this.props.size + "px"}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    );
  }
}

Indicator.defaultProps = {
  size: 65,
};

Indicator.propTypes = {
  size: PropTypes.number,
};
