/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Badge, Card, CardBody, CardFooter, CardTitle, Col } from "reactstrap";
import { Link } from "react-router-dom";
import AxiosConfig from "../../AxiosConfig";
import Timestamp from "../platform/components/Timestamp";
import DateStamp from "../platform/components/DateStamp";
import PreloadImage from "../../components/PreloadImage";
import { kindToUrl } from "../../helpers/post";

const ArticleCard = (props) => {
  const { post } = props;

  const tags =
    post.tags && post.tags.length
      ? post.tags.map((i, idx) => (
          <Link
            key={`ab-${post.slug}${i.slug}`}
            /*tag={Link}*/
            className=""
            color="neutral"
            to={"#"}
            /*to={`/tags/${i.slug}`}*/
          >
            #{i.label}{" "}
          </Link>
        ))
      : null;
  const postUrl = `/${kindToUrl(post.kind)}/${post.slug}`;

  const likes =
    Number.parseInt(post.votes) > 0 ? (
      <span className={"d-inline-flex align-items-center mr-2"}>
        <i className="fas fa-thumbs-up text-danger"></i>
        &nbsp;{post.votes}
      </span>
    ) : null;

  const comments =
    Number.parseInt(post.comments_count) > 0 ? (
      <span className={"d-inline-flex align-items-center"}>
        <i className="far fa-comment text-info"></i>
        &nbsp;{post.comments_count}
      </span>
    ) : null;

  const coverImage =
    post.images && post.images.length
      ? post.images.find((i) => i.is_cover)
      : null;

  const previewImage = coverImage ? (
    <div className="card-image">
      <Link to={postUrl}>
        <div className={"img-16x9"}>
          {coverImage ? (
            <PreloadImage
              alt={post.title}
              className="img rounded img-raised"
              src={AxiosConfig.getResourceUrl(coverImage.thumb_md_path)}
              previewData={coverImage.preview}
            />
          ) : null}
        </div>
      </Link>
    </div>
  ) : null;

  const publishDate = props.showPublishDate ? (
    <span>
      <i className="far fa-calendar-alt mr-1"></i>
      <DateStamp time={post.publish_date} />
    </span>
  ) : null;

  const footer = publishDate ? (
    <CardFooter>
      <div className="stats">{publishDate}</div>
      <div className="stats-link">{tags}</div>
    </CardFooter>
  ) : null;

  return (
    <Col md="4">
      <Card className="card-plain card-blog">
        {previewImage}
        <CardBody>
          {likes}
          {comments}
          <CardTitle tag="h4">
            <Link to={postUrl}>{post.title}</Link>
          </CardTitle>
          <p className="card-description">
            {post.abstract} <Link to={postUrl}>Czytaj całość</Link>
          </p>

          {/*<div className="author">
            <img
              alt="..."
              className="avatar img-raised"
              src={require("assets/img/olivia.jpg").default}
            ></img>
            <span>Anna Spark</span>
          </div>*/}
        </CardBody>
        {footer}
      </Card>
    </Col>
  );
};

ArticleCard.defaultProps = {
  showPublishDate: true,
};

ArticleCard.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    abstract: PropTypes.string,
    kind: PropTypes.string,
    publish_date: PropTypes.number,
  }),
  showPublishDate: PropTypes.bool,
};

export default ArticleCard;
