/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/01/2022
 */

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
  NavLink,
} from "reactstrap";
import Logo from "../../components/Logo";
import classNames from "classnames";
import { useUserStore } from "../../hooks/user";
import UserAvatar from "../../components/UserAvatar";

const DropdownNavyNavbar = (props) => {
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ""
      : " navbar-transparent"
  );

  useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor(" navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  return (
    <Navbar className={"fixed-top" + navbarColor} color="navy" expand="lg">
      {props.children}
    </Navbar>
  );
};

export default DropdownNavyNavbar;
