/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/01/2022
 */

import React from "react";
import { Col, Container, Media, Row } from "reactstrap";
import { useUserStore } from "../../hooks/user";
import { usePostStore } from "../../hooks/post";
import AxiosConfig from "../../AxiosConfig";
import CommentForm from "./CommentForm";

const NewComment = (props) => {
  const { user } = useUserStore();
  const { post } = usePostStore();

  const postModel = post.model || {};
  const hasComments = postModel.comments && postModel.comments.length;

  const sectionTitle = hasComments
    ? "Twoja opinia"
    : "Bądź pierwszy, podziel się swoją opinią!";

  const userAvatar = user.model ? (
    <Media
      alt={user.model.nickname}
      title={user.model.nickname}
      className="img-raised"
      object
      src={AxiosConfig.getResourceUrl(`/avatars/${user.model.avatar}`)}
    />
  ) : null;

  return (
    <div className="section section-comments" id={"new-comment-section"}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="10">
            <div className="media-area">
              <h3 className="title text-center">{sectionTitle}</h3>
              <Media className="media-post">
                <a
                  className="pull-left author"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="avatar">{userAvatar}</div>
                </a>
                <CommentForm />
              </Media>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NewComment.defaultProps = {};

NewComment.propTypes = {};

export default NewComment;
