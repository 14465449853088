/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/01/2021.
 */

/**
 * Function that checks whether go back in history or go to destination.
 * Destination usually means main path. Useful in e.g. modals when it offers
 * going back but could be accessed directly. In such case going back would lead
 * to a newly open tab (or google search results). This one would lock in user in the app
 * and navigate them to the main page.
 * @param history
 * @param destination
 */
const smartHistoryBack = (history, destination = "/") => {
  if (
    history &&
    history.location &&
    !history.location.key
  ) {
    history.push(destination, { trigger: true });
  } else {
    history.goBack();
  }
}

export default smartHistoryBack;
