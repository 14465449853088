/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/07/2017.
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param data
 * @returns {(function(*): void)|*}
 */
export const addComment = (data) => {
  return async (dispatch) => {
    dispatch({ type: "COMMENT_ADD", postId: data.post_id });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + `/api/comments/.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "COMMENT_ADD_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMMENT_ADD_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @returns {(function(*): void)|*}
 */
export const deleteComment = (id) => {
  return async (dispatch) => {
    dispatch({ type: "COMMENT_DELETE", id });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + `/api/comments/${id}.json`,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "COMMENT_DELETE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMMENT_DELETE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @returns {(function(*): void)|*}
 */
export const editComment = (id, data) => {
  return async (dispatch) => {
    dispatch({ type: "COMMENT_EDIT", id });

    await axios
      .put(
        AxiosConfig.getEndpointAddress() + `/api/comments/${id}.json`,
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "COMMENT_EDIT_FULFILLED",
          payload: response.data,
          id
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMMENT_EDIT_REJECTED",
          payload: error,
          id
        });
      });
  };
};

/**
 *
 * @param id
 * @param vote
 * @returns {(function(*): void)|*}
 */
export const voteComment = (id, vote) => {
  return async (dispatch) => {
    dispatch({ type: "COMMENT_VOTE", payload: { id } });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + `/api/comments/vote/${id}.json`,
        { vote },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "COMMENT_VOTE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMMENT_VOTE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param vote
 * @returns {(function(*): void)|*}
 */
export const unVoteComment = (id) => {
  return async (dispatch) => {
    dispatch({ type: "COMMENT_VOTE", payload: { id } });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + `/api/comments/vote/${id}.json`,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "COMMENT_VOTE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMMENT_VOTE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param file
 * @param resource
 * @param changeData
 * @param id
 * @returns {(function(*): Promise<void>)|*}
 */
export const uploadCommentImage = (file, resource, changeData, id) => {
  return async (dispatch) => {
    const data = new FormData();
    data.append("file", file);
    data.append("resource", "postComment");

    dispatch({ type: "COMMENT_IMAGE_UPLOAD", payload: { id } });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/images.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "COMMENT_IMAGE_UPLOAD_FULFILLED",
          payload: response.data,
          changeData: changeData,
          id,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "COMMENT_IMAGE_UPLOAD_REJECTED",
          payload: error,
          id,
        });
      });
  };
};


/**
 *
 * @param comment
 * @returns {(function(*): Promise<void>)|*}
 */
export const setEditComment = (comment) => {
  return async (dispatch) => {

    dispatch({ type: "EDIT_COMMENT_SET", payload: { comment } });

  };
};
