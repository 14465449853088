/**
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Schema from "./Schema";

export default class Organization extends Schema {
  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  /**
   *
   */
  componentDidMount() {
    super.componentDidMount();

    this.jsonToHead(
      Organization.createOrganizationSchema(this.props.org, this.props.socials)
    );
  }

  /**
   *
   * @param org
   * @param socials
   * @returns {{legalName: *, foundingDate: string, address: {'@type': string}, contactPoint: {'@type': string}[], '@type': string, name: *, logo: *, alternateName: *, '@context': string, url: string, sameAs: *}}
   */
  static createOrganizationSchema(org, socials = null) {
    const object = {
      "@context": "http://schema.org",
      "@type": "Organization",
      legalName: org.fullName,
      alternateName: org.name,
      name: org.name,
      url: "https://programowaniejestlatwe.pl",
      logo: {
        "@type": "ImageObject",
        url: org.logoUrl
      },
      address: {
        "@type": "PostalAddress",
        ...org.address
      },
      contactPoint: [
        {
          "@type": "ContactPoint",
          ...org.contactPoint
        }
      ],
      foundingDate: "2016-09-01"
    };

    if (socials) {
      object.sameAs = socials.map(i => i.url);
    }

    return object;
  }

  render() {
    return null;
  }
}

export const OrganizationType = PropTypes.shape({
  contactPoint: PropTypes.string,
  address: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  fullName: PropTypes.string,
});

Organization.propTypes = {
  org: OrganizationType.isRequired,
  socials: PropTypes.array
};
