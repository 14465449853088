/**
 * Created by leper on 03/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "reactstrap";
import TablePagination from "../../../components/TablePagination";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Indicator from "../../../components/Indicator";
import SortableCol from "../../../components/SortableCol";
import {kindToUrl} from "../../../helpers/post";

const dateTimeFormat = new Intl.DateTimeFormat("pl-PL", {
  dateStyle: "short",
  timeStyle: "short",
});

const kindToIcon = (kind) => {
  switch (kind) {
    case "article":
      return "far fa-file-alt";

    case "video":
      return "fas fa-film";
  }
};

const ArticlesTable = (props) => {
  if (props.isLoading) {
    return (
      <div className={"text-center"}>
        <h4 className={"card-title"}>Wczytuję</h4>
        <Indicator size={64} />
      </div>
    );
  }

  if (!props.isLoading && !props.articles.length) {
    return (
      <div className={"text-center"}>
        <h4 className={"card-title"}>Brak wyników do wyświetlenia</h4>
      </div>
    );
  }

  const rows = props.articles.map((i, idx) => {
    return (
      <tr key={`atr-${i.slug}`}>
        <td className="text-center">
          {i.available ? <i className={"fas fa-check"} /> : null}{" "}
          <i className={classNames(kindToIcon(i.kind), "ml-1")} />
        </td>
        <td>{dateTimeFormat.format(new Date(i.publish_date * 1000))}</td>
        <td>{i.title}</td>
        <td>
          <div className={"d-flex justify-content-around"}>
            <span>
              <i className="fas fa-eye text-info"></i> {i.views_count}
            </span>
            <span>
              <i className="far fa-thumbs-up text-danger"></i> {i.votes || 0}
            </span>
            <span>
              <i className="far fa-comment-alt text-success"></i>{" "}
              {i.comments_count}
            </span>
          </div>
        </td>
        <td className="">
          {i.tags && i.tags.length
            ? i.tags.map((tag, tagIdx) => (
                <span key={`${i.slug}-${tag.slug}`}>
                  <span className={"text-muted"}>#</span>
                  {tag.slug}{" "}
                </span>
              ))
            : null}
        </td>
        <td className="text-right">
          <Button
            tag={Link}
            to={`/edytuj-artykul/${i.slug}`}
            className="btn-icon mr-1 d-inline-flex justify-content-center align-items-center"
            color="success"
            size="sm"
            type="button"
          >
            <i className="far fa-edit"></i>
          </Button>
          <Button
            tag={Link}
            target={`_blank-${i.slug}`}
            to={`/${kindToUrl(i.kind)}/${i.slug}`}
            className="btn-icon mr-1 d-inline-flex justify-content-center align-items-center"
            color="default"
            size="sm"
            type="button"
          >
            <i className="fas fa-external-link-alt"></i>
          </Button>
          {/*<Button className="btn-icon" color="danger" size="sm" type="button">
            <i className="far fa-trash-alt"></i>
          </Button>*/}
        </td>
      </tr>
    );
  });

  let resultsLabel = "Wynik";

  if (props.pagination.count > 1) {
    resultsLabel = "Wyniki";
  }
  if (props.pagination.count > 4) {
    resultsLabel = "Wyników";
  }

  return (
    <>
      <div className={"d-flex"}>
        <h4 className={"card-title"}>
          {props.pagination.count} {resultsLabel}
        </h4>
        {props.titleComponent}
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th width={"5%"}></th>
            <SortableCol
              id={"publish_date"}
              width={"10%"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Data publikacji
            </SortableCol>
            <SortableCol
              width={"*"}
              id={"title"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Tytuł
            </SortableCol>
            <th width={"13%"}>Aktywność</th>
            <th width={"22%"}>Tagi</th>
            <th width={"13%"} className={"text-right"}>
              Akcje
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <TablePagination
        page={props.pagination.page || 0}
        pagesCount={props.pagination.pageCount || 0}
        linkBuilder={props.linkBuilder}
      />
    </>
  );
};

ArticlesTable.defaultProps = {
  titleComponent: null,
};

ArticlesTable.propTypes = {
  isLoading: PropTypes.bool,
  articles: PropTypes.array,
  pagination: PropTypes.object,
  sort: PropTypes.object,
  linkBuilder: PropTypes.func,
  onSort: PropTypes.func,

  titleComponent: PropTypes.object,
};

export default ArticlesTable;
