/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import Indicator from "../components/Indicator";
import classNames from "classnames";

const ModalTemplate = (props) => {
  const cancelButton =
    !props.isLoading && props.cancelButtonLabel ? (
      <Button color="secondary" type="button" onClick={props.onCancel}>
        {props.cancelButtonLabel}
      </Button>
    ) : null;

  const submitButton =
    !props.isLoading && props.submitButtonLabel ? (
      <Button color="primary" type="button" onClick={props.onSubmit}>
        {props.submitButtonLabel}
      </Button>
    ) : null;

  const loading = props.isLoading ? <Indicator size={38} /> : null;

  return (
    <Modal isOpen={true} size={props.size}>
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          onClick={props.onClose}
          type="button"
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter
        className={classNames({ "justify-content-end": props.isLoading })}
      >
        {cancelButton}
        {submitButton}
        {loading}
      </ModalFooter>
    </Modal>
  );
};

ModalTemplate.defaultProps = {
  title: "",
  size: "md",
  submitButtonLabel: "Zapisz zmiany",
  cancelButtonLabel: "Anuluj",
};

ModalTemplate.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default ModalTemplate;
