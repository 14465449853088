/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/01/2022
 */

/**
 *
 * @param e
 */
export const preventDefault = (e) => {
  e.preventDefault();
};
