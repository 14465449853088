/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/01/2024
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import { Button, Col, Container, Row } from "reactstrap";
import { usePostsStore } from "../hooks/posts";
import Header from "./WebBrowserIndexPage/Header";
import Indicator from "../components/Indicator";
import SeoBlock from "../components/SeoBlock";
import AxiosConfig from "../AxiosConfig";
import WebPage from "../components/Schemas/WebPage";
import { Link } from "react-router-dom";
import EpisodeThumbnail from "./Pjl101IndexPage/EpisodeThumbnail";

const AIIndexPage = (props) => {
  const { posts, cleanupCollection, fetchLastPosts } = usePostsStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {};
  }, []);

  useEffect(() => {
    loadMore(1);
    return cleanupCollection;
  }, []);

  /**
   *
   * @param page
   */
  const loadMore = (page) => {
    fetchLastPosts("video", page, ["seriaai"], "asc", "publish_date");
  };

  const postsCollection = [...posts.collection];

  for (let i = postsCollection.length; i < 15; i++) {
    postsCollection.push({
      title: `Odcinek ${i + 1} - premiera wkrótce`,
      images: [{}],
    });
  }

  const articles = postsCollection.map((item, idx) => {
    return <EpisodeThumbnail key={`pjlai-c-${idx}`} post={item} sm={4} />;
  });

  /*
  .map(i => {
    console.log(i);
  });*/

  const loaderContainer = (
    <Col xs={12} className="text-center">
      <Indicator size={32} />
    </Col>
  );

  const loader = posts.fetch ? loaderContainer : null;

  return (
    <PageTemplate>
      <SeoBlock
        title={"Sztuczna inteligencja - AI dla programisty"}
        description={
          "Zbuduj aplikację, które diagnozuje usterki w samochodzie za pomocą AI. Zobacz jak budować aplikacje AI od podstaw."
        }
        keywords={
          "programowanie jest łatwe, nauka programowania, sztuczna inteligencja, AI, motoryzacja"
        }
        image={"/img/bg/bg1.png"}
        author={"Programowanie jest łatwe"}
        type="website"
      />
      <WebPage
        name={"Sztuczna inteligencja - AI dla programisty"}
        description={
          "Zbuduj aplikację, które diagnozuje usterki w samochodzie za pomocą AI. Zobacz jak budować aplikacje AI od podstaw."
        }
        imageUrl={AxiosConfig.getResourceUrl("/img/bg/bg30.svg")}
        url={AxiosConfig.getResourceUrl(`/sztuczna-inteligencja`)}
      />
      <div className="page-header page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(/img/bg/bg30.svg)",
          }}
        ></div>
        <div className="content-center text-center">
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h1 className="title">AI dla programistów</h1>
              <h4 className="description text-white">
                Prompts, LLM, embeddings, GPT, Lanchain, API... I masa innych. W
                serii PJL - AI pokażę Tobie jak z nich korzystać na przykładzie
                zbudowanej przeze mnie aplikacji, która zmienia posty z forum
                internetowego w aplikację, która diagnozuje usterki w
                samochodzie.
              </h4>
            </Col>
          </Row>
        </div>
      </div>
      <div className="main">
        <Container>
          <div className={"section"}>
            <Col className="ml-auto mr-auto" md="12">
              <Container>
                <div className="section-pjlai-overview">
                  <Row>
                    <Col md="7">
                      <div className="img-16x9 position-relative">
                        <iframe
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            textAlign: "center",
                          }}
                          controls={"0"}
                          src="https://www.youtube-nocookie.com/embed/jBgtKaFbO_w"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          modestbranding={"0"}
                        ></iframe>
                      </div>
                    </Col>
                    <Col md="5">
                      <h3>Praktyczne zastosowanie LLM AI</h3>
                      <p>
                        Sztuczna inteligencja rozpala wyobraźnie. Jeżeli masz
                        pomysł na aplikację AI to w tej serii zobaczysz od czego
                        zacząć i jakich narzędzi użyć, aby zbudować w zasadzie
                        dowolną aplikację AI, która będzie korzystać z wybranego
                        przez Ciebie źródła danych.
                      </p>
                      <div className={"text-center"}>
                        <Button
                          tag={Link}
                          to={"/subskrybuj"}
                          className={"btn-subscribe"}
                          color={"danger"}
                        >
                          Subskrybuj
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </div>

          <div className="">
            <Row>
              <Col xs={12}>
                <h3>Kod źródłowy</h3>
                <p>
                  Repozytorium tego projektu znajdziesz na{" "}
                  <a href={"https://github.com/programowaniejestlatwe/pjl-ai"}>
                    <i className="fab fa-github"></i> github
                  </a>
                  .
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h3>Odcinki</h3>
              </Col>
              {articles}
              {loader}
            </Row>
          </div>
        </Container>
      </div>
    </PageTemplate>
  );
};

AIIndexPage.defaultProps = {};

AIIndexPage.propTypes = {};

export default AIIndexPage;
