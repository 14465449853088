/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2022
 */

export const UserRoles = [
  {
    label: "Administrator",
    value: "admin",
  },
  {
    label: "Użytkownik",
    value: "user",
  },
];
