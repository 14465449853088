/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import classNames from "classnames";
import {useUserStore} from "../../hooks/user";

const CommentsButton = (props) => {
  const { commentsCount } = props;
  const {user} = useUserStore();

  const commentsCounter =
    Number.parseInt(commentsCount) > 0 ? commentsCount : null;

  /**
   *
   * @param e
   */
  const onCommentClick = (e) => {
    const commentPlaceholderDom = document.querySelector("#new-comment-placeholder");

    if (commentPlaceholderDom) {
      window.scrollTo(0, window.scrollY + commentPlaceholderDom.getBoundingClientRect().top - 90);
      commentPlaceholderDom.click();
    }
    else {
        const wysiwygDom = document.querySelector('#new-comment-input');
        wysiwygDom.scrollIntoView();
        wysiwygDom.focus();
    }

  };

  return (
    <Button className="mr-1" color="info" onClick={onCommentClick} size={"md"}>
      <i
        className={classNames("far fa-comment text-white", {
          "mr-1": commentsCounter ? true : false,
        })}
      ></i>
      &nbsp;{commentsCounter}
    </Button>
  );
};

CommentsButton.defaultProps = {};

CommentsButton.propTypes = {
  commentsCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default CommentsButton;
