/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2020.
 */

import React from "react";
import PropTypes from "prop-types";

import AxiosConfig from "../AxiosConfig";

const UserAvatar = (props) => {
  if (!props.show || !props.user) {
    return null;
  }

  return (
    <div
      className="avatar position-relative"
      style={{ width: props.size, height: props.size }}
    >
      <img
        style={{ width: props.size, height: props.size, objectFit: "cover" }}
        src={AxiosConfig.getResourceUrl(`/avatars/${props.user.avatar}`)}
        alt={props.user.name}
        className="img-circle img-no-padding img-responsive"
        data-tip={props.user.name}
      />
      {props.children}
    </div>
  );
};

UserAvatar.propTypes = {
  size: PropTypes.number,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }),
  show: PropTypes.bool,
};

UserAvatar.defaultProps = {
  size: 38,
  show: true,
  showSummaryTooltip: false,
};

export default UserAvatar;
