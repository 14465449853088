/**
 * Created by piotr.pozniak@thebeaverhead.com on 16/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as commentActions from "./../actions/comment";

export function useCommentStore() {
  const dispatch = useDispatch();
  const _comment = useSelector((store) => store.comment);

  const addComment = useCallback(
    async (data) => await dispatch(commentActions.addComment(data)),
    [dispatch]
  );

  const deleteComment = useCallback(
    async (id) => await dispatch(commentActions.deleteComment(id)),
    [dispatch]
  );

  const editComment = useCallback(
    async (id, data) => await dispatch(commentActions.editComment(id, data)),
    [dispatch]
  );

  const setEditComment = useCallback(
    async (comment) => await dispatch(commentActions.setEditComment(comment)),
    [dispatch]
  );

  const voteComment = useCallback(
    async (id, vote) => await dispatch(commentActions.voteComment(id, vote)),
    [dispatch]
  );

  const unVoteComment = useCallback(
    async (id) => await dispatch(commentActions.unVoteComment(id)),
    [dispatch]
  );

  const uploadCommentImage = useCallback(
    async (file, changeData, id) =>
      await dispatch(commentActions.uploadCommentImage(file, changeData, id)),
    [dispatch]
  );

  return {
    comment: _comment,
    addComment,
    deleteComment,
    editComment,
    voteComment,
    unVoteComment,
    uploadCommentImage,
    setEditComment
  };
}
