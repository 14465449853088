/**
 *
 */

import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import Schema from "./Schema";
import SeoBlock from "../SeoBlock";

export default class Person extends Schema {
  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  /**
   *
   */
  componentDidMount() {
    super.componentDidMount();

    this.jsonToHead(
      Person.createPersonSchema(this.props.person)
    );
  }


  /**
   *
   * @param person
   * @returns {{image: *, '@type': string, name: *, '@context': string, url: *}}
   */
  static createPersonSchema(person) {
    const object = {
      "@context": "http://schema.org",
      "@type": "Person",
      image: person.imageUrl,
      name: person.name,
      url: person.url
    };

    return object;
  }

  render() {
    return null;
  }
}

export const PersonType = PropTypes.shape({
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
});

Person.propTypes = {
  person: PersonType.isRequired
};
