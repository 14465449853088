import {editComment} from "../actions/comment";

const initialState = {
  editCommentBody: "",
  editCommentId: null,

  delete: false,
  deleteError: null,
  deleteSuccess: false,

  edit: false,
  editSuccess: false,
  editError: null,

  create: false,
  createSuccess: false,
  createError: null,

  vote: false,
  voteSuccess: false,
  voteError: null,

  imageUpload: false,
  imageUploadSuccess: false,
  imageUploadError: null,

  commentVoteId: null,
  createdCommentId: null
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  let newCollection = null;

  switch (action.type) {
    case "COMMENT_DELETE":
      return {
        ...state,
        delete: true,
        deleteSuccess: false,
        deleteError: null,
      };

    case "COMMENT_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
      };

    case "COMMENT_DELETE_FULFILLED":
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
      };

    case "COMMENT_ADD":
      return {
        ...state,
        create: true,
        createSuccess: false,
        createError: null,
      };

    case "COMMENT_ADD_FULFILLED":
      return {
        ...state,
        create: false,
        createSuccess: true,
        createdCommentId: action.payload.comments[0].id
      };

    case "COMMENT_ADD_REJECTED":
      return {
        ...state,
        create: false,
        createError: action.payload,
      };

    case "COMMENT_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editError: null,
      };

    case "COMMENT_EDIT_FULFILLED":
      return {
        ...state,
        edit: false,
        editSuccess: true,
        editCommentId: null,
        createdCommentId: action.id
      };

    case "COMMENT_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editError: action.payload,
      };

    /*





    case 'COMMENT_EDIT':
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (item.model.uuid === action.uuid) {
            item.model = {
              ...item.model,
              changeBody: null
            };
            item.postState = {
              ...item.postState,
              loading: true,
              success: false,
              error: null,
            };
          }
          return item;
        })
      };

    case 'COMMENT_EDIT_FULFILLED':

      newCollection = [];

      newCollection = newCollection.concat(
        action.payload.comments.map((item) => {

          let newPostState = {
            ...initialState,
            loading: false
          };

          if (item.uuid === action.uuid) {
            newPostState.editSuccess = true;
          }

          return {
            model: {
              ...item,
              kind: action.payload.kind == 1 ? 'question' : 'article',
              parent_uuid: action.payload.uuid
            },
            postState: newPostState
          };
        })
      );


      return {
        ...state,
        collection: newCollection
      };

    case 'COMMENT_EDIT_REJECTED':
      return {
        ...state,
        collection: state.collection.map((item) => {
          if (item.model.uuid === action.uuid) {
            item.postState = {
              ...initialState,
              editError: action.payload,
            };
          }
          return item;
        })
      };



*/

    case "COMMENT_IMAGE_UPLOAD":
      return {
        ...state,
        imageUpload: true,
        imageUploadSuccess: false,
        imageUploadError: null,
        editCommentId: action.payload.id,
      };

    case "COMMENT_IMAGE_UPLOAD_FULFILLED":
      return {
        ...state,
        imageUpload: false,
        imageUploadSuccess: true,
        editCommentBody: {
          text:
            action.changeData.front +
            "![" +
            action.payload.filename +
            "](" +
            action.payload.short_url +
            ")" +
            action.changeData.back,
          cursorPosition:
            action.changeData.cursorPosition +
            action.payload.filename.length +
            5 +
            action.payload.short_url.length,
        },
      };

    case "COMMENT_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        imageUpload: false,
        imageUploadError: action.payload,
      };

    case "COMMENT_VOTE":
      return {
        ...state,
        vote: true,
        voteSuccess: false,
        voteError: null,
        commentVoteId: action.payload.id,
      };

    case "COMMENT_VOTE_REJECTED":
      return {
        ...state,
        vote: false,
        voteError: action.payload,
      };

    case "COMMENT_VOTE_FULFILLED":
      return {
        ...state,
        vote: false,
        voteSuccess: true,
      };

    case "EDIT_COMMENT_SET":
      return {
        ...initialState,
        //editCommentBody: action.payload.comment.body,
        editCommentId: action.payload.comment.id,
      }

    case "CLEAR_ERRORS":
      return {
        ...initialState,
      };
  }

  return state;
}
