/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/08/2017.
 */

import React from "react";

const dateTimeFormat = new Intl.DateTimeFormat("pl-PL", {
  dateStyle: "medium",
  timeStyle: "medium",
});

const Timestamp = (props) => {
  const time = dateTimeFormat.format(new Date(props.time * 1000));

  return (
    <time className="" dateTime="YYYY-MM-DD hh:mm">
      {time}
    </time>
  );
};

export default Timestamp;
