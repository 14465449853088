/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import AdminPageTemplate from "../../templates/AdminPageTemplate";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Datetime from "react-datetime";
import UsersTable from "./UsersIndexPage/UsersTable";
import Select from "react-select";
import AxiosConfig from "../../AxiosConfig";
import { useAdminUsersStore } from "../../hooks/adminUsers";
import { UserRoles } from "../../consts/users";

const UsersIndexPage = (props) => {
  const { adminUsers, fetchAdmin } = useAdminUsersStore();

  const isLoading = adminUsers.fetch;
  const params = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    role: params.get("role") || null,
    created_after: params.get("created_after") || null,
    created_before: params.get("created_before") || null,
  });

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      page: params.get("page") || 1,
      email: params.get("email") || null,
      nickname: params.get("nickname") || null,
      role: params.get("role") || null,
      created_after: params.get("created_after") || null,
      created_before: params.get("created_before") || null,
      sort: params.get("sort") || null,
      direction: params.get("direction") || null,
    };
  }, [window.location.search]);

  /**
   *
   * @type {function(*): string}
   */
  const linkBuilder = useCallback(
    (newParams) => {
      const params = { ...searchParams, ...newParams };
      return `/admin/uzytkownicy?` + AxiosConfig.objectToURLQuery(params);
    },
    [searchParams]
  );

  useEffect(() => {
    if (window.location.pathname === "/admin/uzytkownicy") {
      fetchAdmin(searchParams);
    }
  }, [searchParams]);

  /**
   *
   * @param e
   */
  const onCreatedAfterDateChange = (e) => {
    setState({
      ...state,
      created_after: typeof e === "object" ? e.unix() : null,
    });
  };

  /**
   *
   * @param e
   */
  const onCreatedBeforeDateChange = (e) => {
    setState({
      ...state,
      created_before: typeof e === "object" ? e.unix() : null,
    });
  };

  /**
   *
   * @param e
   */
  const onChangeRole = (e) => {
    setState({ role: e.value });
  };

  /**
   *
   * @param e
   */
  const onSearch = (e) => {
    const updatedUrl = { ...searchParams, ...state };

    updatedUrl.page = 1;
    const email = document.getElementById("email").value;
    const nickname = document.getElementById("nickname").value;

    updatedUrl.email = email && email.length ? email : null;
    updatedUrl.nickname = nickname && nickname.length ? nickname : null;

    updatedUrl.role = state.role || null;
    updatedUrl.created_before = state.created_before || null;
    updatedUrl.created_after = state.created_after || null;

    updatedUrl.sort = null;
    updatedUrl.direction = null;

    props.history.replace(linkBuilder(updatedUrl));
  };

  /**
   *
   * @param e
   */
  const onClear = (e) => {
    props.history.replace(
      `/admin/uzytkownicy?` + AxiosConfig.objectToURLQuery({ page: 1 })
    );
  };


  /**
   *
   * @param e
   */
  const onSort = (e) => {
    const updatedUrl = {...searchParams, ...e};
    props.history.replace(linkBuilder(updatedUrl));

  };

  return (
    <AdminPageTemplate>
      <Container>
        <Row>
          <Col className="" md="12">
            <Card className="card-plain">
              <CardHeader>
                <h4 className={"card-title"}>Użytkownicy</h4>

                <Form className={"form-horizontal"}>
                  <Row>
                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>Role</label>
                      <Select
                        /*className="react-select react-select-primary"
                        classNamePrefix="react-select"*/
                        isDisabled={isLoading}
                        value={UserRoles.find(
                          (i) => i.value === searchParams.role
                        )}
                        placeholder={"Wszystkie"}
                        options={[
                          { label: "Wszystkie", value: null },
                          ...UserRoles,
                        ]}
                        onChange={onChangeRole}
                        autofill={false}
                      />
                    </FormGroup>
                    <FormGroup className={"col-3"}>
                      <label htmlFor={"title"}>Email</label>
                      <Input
                        id={"email"}
                        placeholder="Email"
                        type="text"
                        disabled={isLoading}
                        defaultValue={searchParams.email}
                      ></Input>
                    </FormGroup>
                    <FormGroup className={"col-3"}>
                      <label htmlFor={"title"}>Nazwa</label>
                      <Input
                        id={"nickname"}
                        placeholder="Nazwa"
                        type="text"
                        disabled={isLoading}
                        defaultValue={searchParams.nickname}
                      ></Input>
                    </FormGroup>

                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>Utworzone po</label>
                      <Datetime
                        inputProps={{
                          placeholder: "Opublikowane po",
                          disabled: isLoading,
                        }}
                        onChange={onCreatedAfterDateChange}
                        value={
                          state.created_after
                            ? state.created_after * 1000
                            : null
                        }
                      />
                    </FormGroup>
                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>
                        Utworzone przed
                      </label>
                      <Datetime
                        disabled={isLoading}
                        inputProps={{
                          placeholder: "Opublikowane prezd",
                          disabled: isLoading,
                        }}
                        onChange={onCreatedBeforeDateChange}
                        value={
                          state.created_before
                            ? state.created_before * 1000
                            : null
                        }
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button onClick={onClear} disabled={isLoading}>
                        Wyczyść
                      </Button>
                      <Button
                        color={"info"}
                        onClick={onSearch}
                        disabled={isLoading}
                      >
                        Szukaj
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody>
                <UsersTable
                  isLoading={isLoading}
                  collection={adminUsers.collection}
                  pagination={adminUsers.pagination}
                  linkBuilder={linkBuilder}
                  onSort={onSort}
                  sort={{
                    sort: searchParams.sort || null,
                    direction: searchParams.direction || null,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </AdminPageTemplate>
  );
};

UsersIndexPage.defaultProps = {};

UsersIndexPage.propTypes = {};

export default UsersIndexPage;
