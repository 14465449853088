/**
 * Created by piotr.pozniak@thebeaverhead.com on 17/01/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Wysiwyg from "../../components/Wysiwyg";
import { Alert, Button, Col, Container, Input, Media, Row } from "reactstrap";
import { useCommentStore } from "../../hooks/comment";
import { useAppStore } from "../../hooks/app";
import { useUserStore } from "../../hooks/user";
import { usePostStore } from "../../hooks/post";
import {withRouter} from "react-router-dom";

const CommentForm = (props) => {
  const [editMode, setEditMode] = useState(props.isEditMode);
  const [formErrors, setFormErrors] = useState({});

  const { user } = useUserStore();
  const { post } = usePostStore();
  const { comment, addComment, uploadCommentImage, editComment } =
    useCommentStore();
  const { clearErrors } = useAppStore();

  useEffect(() => {
    if (editMode) {
      document.getElementById("new-comment-input").focus();
    }
  }, [editMode]);

  useEffect(() => {
    setEditMode(props.isEditMode);
  }, [props.isEditMode]);

  useEffect(() => {
    if (comment.createSuccess || comment.editSuccess) {
      setEditMode(false);

      setTimeout(() => {
        clearErrors();
      }, 500);

      const commentDom = document.getElementById(
        `cmnt-${comment.createdCommentId}`
      );

      const scrollOffset =
        window.scrollY +
        commentDom.getBoundingClientRect().top -
        90;

      window.scrollTo(0, scrollOffset);
    }
  }, [comment.createSuccess, comment.editSuccess]);

  /**
   *
   * @param e
   */
  const onClick = (e) => {
    if (!user.model) {
      props.history.push("/logowanie");
    } else {
      setEditMode(true);
    }
  };

  /**
   *
   * @param e
   */
  const onSubmit = (e) => {
    if (!editMode) {
      return onClick(e);
    }

    const body = document.getElementById("new-comment-input").value;

    if (body.length < 1) {
      return setFormErrors({
        body: "Komentarz musi mieć min. 2 znaki. To chyba niewiele?",
      });
    }

    setFormErrors({});

    if (!props.commentId) {
      addComment({ body, post_id: post.model.id });
    } else {
      editComment(props.commentId, { body, post_id: post.model.id });
    }
  };

  /**
   *
   * @param e
   */
  const onCancel = (e) => {
    setEditMode(false);

    if (typeof props.onCancel === "function") {
      props.onCancel();
    }
  };


  const editContainer = editMode ? (
    <Wysiwyg
      uploadImage={uploadCommentImage}
      inputId={"new-comment-input"}
      defaultValue={props.defaultValue}
      placeholder={"Twój komentarz"}
      label={null}
      changeBody={comment.editCommentBody}
    />
  ) : (
    <Input
      id={"new-comment-placeholder"}
      placeholder="Co Tobie lata po synapsach?"
      rows="4"
      type="textarea"
      onClick={onClick}
    />
  );

  const cancelButton = editMode ? (
    <Button className="pull-right" color="default" onClick={onCancel}>
      <i className="far fa-window-close"></i> Anuluj
    </Button>
  ) : null;

  const error = Object.keys(formErrors).length ? (
    <Alert color={"danger"}>
      <Container>
        <div className="alert-icon">
          <i className="fas fa-exclamation"></i>
        </div>
        {formErrors.body}
      </Container>
    </Alert>
  ) : null;

  return (
    <Media body>
      {editContainer}
      <div className="media-footer">
        {error}
        <Button className="pull-right" color="info" onClick={onSubmit}>
          <i className="far fa-paper-plane"></i> Skomentuj
        </Button>
        {cancelButton}
      </div>
    </Media>
  );
};

CommentForm.defaultProps = {
  isEditMode: false,
};

CommentForm.propTypes = {
  isEditMode: PropTypes.bool,
  onCancel: PropTypes.func,
  defaultValue: PropTypes.string,
  commentId: PropTypes.string,
};

export default withRouter(CommentForm);
