/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import classNames from "classnames";
import Indicator from "../../components/Indicator";

const LikeButton = (props) => {
  const { votes, isLoading, userVoted } = props;

  const votesCounter = Number.parseInt(votes) > 0 ? votes : null;

  const buttonContent = isLoading ? (
    <Indicator size={16} />
  ) : (
    <i
      className={classNames("fa-thumbs-up", {
        fas: userVoted ? true : false,
        far: userVoted ? false : true,
        "mr-1": votesCounter ? true : false,
      })}
    />
  );

  /**
   *
   * @param e
   */
  const onLabelClick = e => {
    e.preventDefault();
    if (!isLoading) {
      props.onVote();
    }
  }

  const label = props.label ? (
    <a href={""} className={"text-danger"} onClick={onLabelClick}>
      Dobry kontent!
    </a>
  ) : null;

  return (
    <>
      <Button
        className={props.className}
        color="danger"
        onClick={props.onVote}
        size={"md"}
        disabled={isLoading}
      >
        {buttonContent}
        &nbsp;{votesCounter}
      </Button>
      {label}
    </>
  );
};

LikeButton.defaultProps = {
  className: "mr-1",
};

LikeButton.propTypes = {
  votes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onVote: PropTypes.func,
  isLoading: PropTypes.bool,
  userVoted: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string
};

export default LikeButton;
