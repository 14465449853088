/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022.
 */

import React from "react";
import PropTypes from "prop-types";

const dateTimeFormat = new Intl.DateTimeFormat("pl-PL", {
  dateStyle: "medium",
});

const DateStamp = (props) => {
  const time = dateTimeFormat.format(new Date(props.time * 1000));

  return (
    <time className="" dateTime="YYYY-MM-DD">
      {time}
    </time>
  );
};

DateStamp.propTypes = {
  time: PropTypes.number.isRequired
}
export default DateStamp;
