const initialState = {
  delete: false,
  deleteError: null,
  deleteSuccess: false,

  edit: false,
  editSuccess: false,
  editingError: null,

  accept: false,
  acceptSuccess: false,
  acceptError: false,

  vote: false,
  voteSuccess: false,
  voteError: null,

  imageUpload: false,
  imageUploadSuccess: false,
  imageUploadError: null,

  coverImageUpload: false,
  coverImageUploadSuccess: false,
  coverImageUploadError: null,

  imageDelete: false,
  imageDeleteSuccess: false,
  imageDeleteError: null,

  fetch: false,
  fetchSuccess: false,
  fetchError: null,

  model: null,
  createdCommentId: null,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "POST_FETCH":
      return {
        ...state,
        fetch: true,
        fetchSuccess: false,
        fetchError: false,
      };

    case "POST_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "POST_FETCH_FULFILLED":
      return {
        ...state,

        model: action.payload,
        fetch: false,
        fetchSuccess: true,
        fetchError: false,
      };

    case "POST_DELETE":
      return {
        ...state,

        delete: true,
        deleteError: null,
        deleteSuccess: false,
      };

    case "POST_DELETE_REJECTED":
      return {
        ...state,
        delete: false,
        deleteError: action.payload,
        deleteSuccess: false,
      };

    case "POST_DELETE_SUCCESS":
      return {
        ...state,
        delete: false,
        deleteSuccess: true,
      };

    case "POST_EDIT":
      return {
        ...state,
        edit: true,
        editSuccess: false,
        editingError: false,
      };

    case "POST_EDIT_REJECTED":
      return {
        ...state,
        edit: false,
        editingError: action.payload,
      };

    case "POST_EDIT_FULFILLED":
      return {
        ...state,
        model: action.payload,
        edit: false,
        editSuccess: true,
      };

    case "POST_VOTE":
      return {
        ...state,

        vote: true,
        voteSuccess: false,
        voteError: null,
      };

    case "POST_VOTE_REJECTED":
      return {
        ...state,

        vote: false,
        voteError: action.payload,
      };

    case "POST_VOTE_FULFILLED":
      return {
        model: action.payload,
        vote: false,
        voteSuccess: true,
      };

    case "POST_IMAGE_UPLOAD":
      return {
        ...state,

        imageUpload: true,
        imageUploadSuccess: false,
        imageUploadError: null,
      };

    case "POST_IMAGE_UPLOAD_FULFILLED":
      return {
        ...state,
        imageUpload: false,
        imageUploadSuccess: true,

        model: {
          ...state.model,
          changeBody: {
            text: `${action.changeData.front}![${action.payload.filename}](${action.payload.short_url})${action.changeData.back}`,
            cursorPosition:
              action.changeData.cursorPosition +
              action.payload.filename.length +
              5 +
              action.payload.short_url.length,
          },
        },
      };

    case "POST_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        imageUpload: false,
        imageUploadError: action.payload,
      };

    case "POST_COVER_IMAGE_UPLOAD":
      return {
        ...state,

        coverImageUpload: true,
        coverImageUploadSuccess: false,
        coverImageUploadError: null,
      };

    case "POST_COVER_IMAGE_UPLOAD_FULFILLED":
      const updatedImages = state.model.images
        ? [...state.model.images, action.payload]
        : [action.payload];

      return {
        ...state,
        coverImageUpload: false,
        coverImageUploadSuccess: true,

        model: {
          ...state.model,
          images: updatedImages,
        },
      };

    case "POST_COVER_IMAGE_UPLOAD_REJECTED":
      return {
        ...state,
        coverImageUpload: false,
        coverImageUploadError: action.payload,
      };

    case "POST_IMAGE_DELETE":
      return {
        ...state,
        imageDelete: true,
        imageDeleteSuccess: false,
        imageDeleteError: null,
      };

    case "POST_IMAGE_DELETE_FULFILLED":
      return {
        ...state,
        imageDelete: false,
        imageDeleteSuccess: true,
      };

    case "POST_IMAGE_DELETE_REJECTED":
      return {
        ...state,
        imageDelete: false,
        imageDeleteError: action.payload,
      };

    case "COMMENT_VOTE_FULFILLED":
      return {
        model: action.payload,
      };

    case "COMMENT_EDIT_FULFILLED":
    case "COMMENT_ADD_FULFILLED":
      return {
        ...state,
        model: action.payload,
      };

    case "COMMENT_DELETE_FULFILLED":
      return {
        model: action.payload,
      };

    case "CLEAR_ERRORS":
      return {
        ...initialState,
        model: state.model,
      };
  }

  return state;
}
