/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/01/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalTemplate from "../templates/ModalTemplate";
import smartHistoryBack from "../tools/smartHistoryBack";
import { Alert, Container, Form, FormGroup, Input } from "reactstrap";
import { useUserStore } from "../hooks/user";
import { validateNickname } from "../helpers/error";
import ApiError from "../components/ApiError";
import useUnload from "../hooks/useUnload";
import { useAppStore } from "../hooks/app";

const ProfileEditModal = (props) => {
  const { user, updateUser } = useUserStore();
  const [formError, setFormError] = useState({});
  const { clearErrors } = useAppStore();

  const userModel = user.model;

  useUnload(() => {
    clearErrors();
  });
  /**
   *
   */
  const onClose = () => {
    smartHistoryBack(props.history);
  };

  useEffect(() => {
    if (user.editSuccess) {
      clearErrors();
      props.history.push(`/uzytkownik/${user.model.slug}`);
    }
  }, [user.editSuccess]);

  /**
   *
   * @param e
   */
  const onSubmit = (e) => {
    e.preventDefault();
    const nickname = document.querySelector("#nickname").value;
    const homepage_url = document.querySelector("#homepage_url").value;
    const git_url = document.querySelector("#git_url").value;
    const bio = document.querySelector("#bio").value;

    if (!validateNickname(nickname)) {
      return setFormError({
        nickname: "Nazwa użytkownika musi mieć conajmniej 1 znak.",
      });
    }

    updateUser(user.model.id, { nickname, homepage_url, git_url, bio });
  };

  const isLoading = user.edit;

  const error = Object.keys(formError).length ? (
    <Alert color={"danger"}>
      <Container>
        <div className="alert-icon">
          <i className="fas fa-exclamation"></i>
        </div>
        {formError.nickname}
      </Container>
    </Alert>
  ) : null;

  const apiError = user.editError ? (
    <ApiError
      error={user.editError}
      defaultMessage={"Zmiany nie zostały zapisane. Spróbuj ponownie."}
    />
  ) : null;

  return (
    <ModalTemplate
      onClose={onClose}
      size={"md"}
      title={"Edytuj profil"}
      onCancel={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <Input
              placeholder="Email"
              type="email"
              defaultValue={userModel.email}
              disabled={true}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <Input
              id="nickname"
              placeholder="Nazwa użytkownika"
              type="text"
              defaultValue={userModel.nickname}
              disabled={isLoading}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <Input
              id="homepage_url"
              placeholder="Strona domowa"
              type="url"
              defaultValue={userModel.homepage_url}
              disabled={isLoading}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <Input
              id="git_url"
              placeholder="Link do github"
              type="url"
              defaultValue={userModel.git_url}
              disabled={isLoading}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <label htmlFor="bio">Krótko o Tobie</label>
            <Input
              id="bio"
              placeholder="Kompiluję kod w myślach, wyciskam 120 na klatę a 50m na basenie robię w 4 sekundy."
              type="textarea"
              defaultValue={userModel.bio}
              disabled={isLoading}
            ></Input>
          </FormGroup>
        </div>
        {apiError}
        {error}
      </Form>
    </ModalTemplate>
  );
};

ProfileEditModal.defaultProps = {};

ProfileEditModal.propTypes = {};

export default ProfileEditModal;
