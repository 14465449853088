/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/01/2022
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import AxiosConfig from "../AxiosConfig";
import classNames from "classnames";

const PreloadImage = props => {

  return (
    <img
      style={{backgroundImage: `url('${props.previewData}')`}}
      alt={props.alt}
      className={classNames(props.className, 'img-preview')}
      src={props.src}
    />
  )
}

PreloadImage.defaultProps = {};

PreloadImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  previewData: PropTypes.string

}

export default PreloadImage;
