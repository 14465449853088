/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import URL from "url-parse";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Vimeo from "./Vimeo";
import Youtube from "./Youtube";

const Formatters = {
  ["vimeo.com"]: Vimeo,
  ["youtube.com"]: Youtube,
  ["youtube-nocookie.com"]: Youtube,
  ["youtu.be"]: Youtube,
};
const LinkBlock = (props) => {
  const url = new URL(props.href, true);

  const LinkFormatter = Object.keys(Formatters).includes(url.host)
    ? Formatters[url.host]
    : null;

  if (!LinkFormatter) {
    const link =
      url.query && url.query.internal ? (
        <Link to={url.pathname}>{props.children[0]}</Link>
      ) : (
        <a href={props.href} target="_blank" title={props.title}>
          {props.children[0]}
        </a>
      );

    return <span>{link}</span>;
  }

  return (
    <div className="image-container">
      <div
        style={{
          padding: "56.25% 0 0 0",
          position: "relative",
        }}
      >
        <LinkFormatter url={url} />
      </div>
    </div>
  );
};

LinkBlock.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default LinkBlock;
