export default function reducer(
  state = {
    collection: [],

    fetch: false,
    fetchSuccess: false,
    fetchError: null,
  },
  action
) {
  switch (action.type) {
    case "FETCH_SIMILAR_POSTS":
      return {
        collection: [],

        fetch: false,
        fetchSuccess: false,
        fetchError: null,
      };

    case "FETCH_SIMILAR_POSTS":
      return {
        ...state,

        //collection: null,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "FETCH_SIMILAR_POSTS_FULFILLED":
      return {
        ...state,

        collection: action.payload.similar,

        fetch: false,
        fetchSuccess: true,
      };
  }

  return state;
}
