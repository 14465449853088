/**
 *
 * @param kind
 * @returns {string}
 */
export const kindToUrl = (kind) => {

  switch (kind) {
    case "video":
      return "video";

    case "article":
    default:
      return "artykul";
  }
};
