/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as postsActions from "./../actions/posts";

export function useAdminPostsStore() {
  const dispatch = useDispatch();
  const _adminPosts = useSelector((store) => store.adminPosts);

  const fetchPostsAdmin = useCallback(
    async (params) =>
      await dispatch(postsActions.fetchPostsAdmin(params)),
    [dispatch]
  );

  const cleanupCollection = useCallback(
    async () => await dispatch(postsActions.cleanupCollection()),
    [dispatch]
  );

  return {
    adminPosts: _adminPosts,
    fetchPostsAdmin,
    cleanupCollection,
  };
}
