/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Col, Container, Row } from "reactstrap";

// core components

const FooterNavy = () => {
  return (
    <>
      <footer className="footer" data-background-color="navy">
        <Container>
          <div className={"content"}>
            <Row>
              <Col md="3">
                <a href="/" onClick={(e) => e.preventDefault()}>
                  <h5>Programowanie jest łatwe</h5>
                </a>
                <p>Skomplikowane rzeczy tłumaczymy w prosty sposób.</p>
                <ul className="links-vertical">
                  <li>
                    <a className="" href="/legal/polityka-prywatnosci">
                      Polityka Prywatności
                    </a>
                  </li>
                  <li>
                    <a className="" href="/legal/regulamin">
                      Regulamin
                    </a>
                  </li>
                </ul>
              </Col>
              <Col md="2"></Col>

              <Col className={"offset-md-3"} md={"4"}>
                <h5 className={"text-md-right"}>Media społecznościowe</h5>
                <ul className="social-buttons text-md-right">
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="youtube"
                      href="https://www.youtube.com/channel/UC893vPY1rxboAGX-rD3UYKQ"
                      target={"_blank-pjl-youtube"}
                    >
                      <i className="fab fa-youtube"></i>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="github"
                      href="https://github.com/programowaniejestlatwe/"
                      target={"_blank-pjl-github"}
                    >
                      <i className="fab fa-github"></i>
                    </Button>
                  </li>
                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round mr-1"
                      color="facebook"
                      href="https://www.facebook.com/programowaniejestlatwe/"
                      target={"_blank-pjl-facebook"}
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                  </li>

                  <li>
                    <Button
                      className="btn-icon btn-neutral btn-round"
                      color="github"
                      href="https://www.instagram.com/programowaniejestlatwe/"
                      target={"_blank-pjl-tiktok"}
                    >
                      <i className="fab fa-tiktok"></i>
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <div className="copyright" id="copyright">
            <a href="https://programowaniejestlatwe.pl" target="_blank">
              © {new Date().getFullYear()}, PJL - Programowanie jest łatwe
            </a>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default FooterNavy;
