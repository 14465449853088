/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";

import ReactModalLogin from "react-modal-login";

import { facebookConfig, googleConfig } from "../consts/social-config";

import { login, register } from "../actions/user";

import { useUserStore } from "../hooks/user";
import { useAppStore } from "../hooks/app";
import useUnload from "../hooks/useUnload";
import smartHistoryBack from "../tools/smartHistoryBack";
import {
  formatError,
  validateEmail,
  validateNickname,
  validatePassword,
} from "../helpers/error";
import classNames from "classnames";
import { FormGroup, Input, Label } from "reactstrap";
import { configureStore } from "../store";
import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param path
 * @returns {string}
 */
const urlToTabID = (path) => {
  switch (path) {
    case "/rejestracja":
      return "register";

    case "/przypomnienie-hasla":
      return "recoverPassword";

    case "/logowanie":
    default:
      return "login";
  }
};

const LoginModal = (props) => {
  const { user, login, register, remindPassword } = useUserStore();
  const { clearErrors } = useAppStore();
  const [tab, setTab] = useState(urlToTabID(props.match.path));
  const [registerData, setRegisterData] = useState({});

  const [formErrors, setFormErrors] = useState({});

  const isSecondLoginStage = useMemo(
    () => (registerData.email && registerData.email.length ? true : false),
    [registerData]
  );

  useUnload(() => {
    clearErrors();
  });

  useEffect(() => {
    if (user.loginSuccess) {
      smartHistoryBack(props.history);
    }
  }, [user.loginSuccess]);

  useEffect(() => {
    if (user.registerSuccess) {
      smartHistoryBack(props.history);
    }
  }, [user.registerSuccess]);

  /**
   * After receiving token and user's data from 3rd party, check if  user can be logged in.
   * If preflight request fails, means user cannot be signed in and must follow the registration steps.
   * In other case, login action will be called and user will be logged in.
   * @param data
   * @returns {Promise<void>}
   */
  const proceedStage2Login = async (data) => {
    axios
      .post(AxiosConfig.getEndpointAddress() + "/api/users/login.json", {
        ...data,
      })
      .then(() => {
        login(data.email, null, data.provider, data.token);
      })
      .catch(() => {
        setRegisterData(data);
        setTab("register");
      });
  };

  /**
   *
   */
  const onLogin = async (provider, response) => {
    setFormErrors({});

    if (provider) {
      let responseToken = null;
      let nickname = "";
      if (provider === "facebook") {
        responseToken = response.authResponse.accessToken;
        window.FB.api("/me", { fields: "name,email" }, (response) => {

          proceedStage2Login({
            email: response.email,
            nickname: response.name,
            provider,
            token: responseToken,
          });
        });
      } else if (provider === "google") {
        responseToken = response.id_token;
        const userInfo = window.gapi.auth2
          .getAuthInstance()
          .currentUser.get()
          .getBasicProfile();

        proceedStage2Login({
          email: userInfo.tv,
          nickname: userInfo.tf,
          provider,
          token: responseToken,
        });
      }
    } else {
      let email = document.querySelector("#email").value;
      const password = document.querySelector("#password").value;

      let loginEmail = null;
      let loginPassword = null;

      if (!validateEmail(email)) {
        loginEmail = "Podaj poprawny adres email.";
      }
      if (!validatePassword(password)) {
        loginPassword = "Hasło musi mieć minimum 6 znaków.";
      }

      if (loginEmail || loginPassword) {
        return setFormErrors({ loginEmail, loginPassword });
      }

      login(email, password, provider, null);
    }
  };

  /**
   *
   */
  const onRegister = () => {
    setFormErrors({});

    const nickname = document.querySelector("#login").value;
    const email = document.querySelector("#email").value;
    const password = isSecondLoginStage
      ? null
      : document.querySelector("#password").value;
    const tosConsent = !document.querySelector("#tos-consent").checked
      ? "Musisz zaakceptować regulamin"
      : null;
    const newsletterConsent = document.querySelector(
      "#newsletter-consent"
    ).checked;

    let registerEmail = null;
    let registerNickname = null;
    let registerPassword = null;

    if (!validateEmail(email)) {
      registerEmail = "Podaj poprawny adres email.";
    }
    if (!validateNickname(nickname)) {
      registerNickname = "Nazwa użytkownika winna mieć minimum 1 znak.";
    }
    if (!isSecondLoginStage && !validatePassword(password)) {
      registerPassword = "Hasło musi mieć minimum 6 znaków.";
    }

    if (registerPassword || registerEmail || registerNickname || tosConsent) {
      return setFormErrors({
        registerPassword,
        registerEmail,
        registerNickname,
        tosConsent,
      });
    }

    register(
      nickname,
      email,
      password,
      newsletterConsent,
      isSecondLoginStage ? registerData.provider : null,
      isSecondLoginStage ? registerData.token : null
    );
  };

  /**
   *
   */
  const onRecoverPassword = () => {
    setFormErrors({});
    const email = document.querySelector("#email").value;

    if (!validateEmail(email)) {
      return setFormErrors({ recoverEmail: "Podaj poprawny adres email." });
    }

    remindPassword(email);
  };

  /**
   *
   */
  const onClose = () => {
    smartHistoryBack(props.history, "/");
  };

  const onStartLoading = () => {};
  const finishLoading = () => {};

  /**
   *
   * @param e
   */
  const onTabChange = (e) => {
    clearErrors();
  };

  const onRegisterEmailBlur = (e) => {
    const email = document.querySelector("#email").value;
    const nickname = document.querySelector("#login").value;

    if (!nickname.length && email.length && validateEmail(email)) {
      const emailParts = e.target.value.split("@");
      const emailUserName = emailParts[0];

      const emailUserNameParts = emailUserName.split(/[\._-]/);
      document.querySelector("#login").value = emailUserNameParts.join(" ");
    }
  };

  /**
   *
   * @param tab
   * @returns {(function(*=): void)|*}
   */
  const changeTab = (tab) => (e) => {
    if (e) {
      e.preventDefault();
    }
    setTab(tab);
  };

  const onLoginError = () => {};

  const isLoading = user.login || user.register || user.remindPassword;

  const error =
    user.loginError || user.registerError || Object.keys(formErrors).length > 0;

  const bottomLoginContainer = (
    <div className="login-modal__bottom-container login-modal__bottom-container--login">
      <a href="#" onClick={changeTab("recoverPassword")}>
        Nie pamiętasz hasła?
      </a>
    </div>
  );
  const bottomRegisterContainer = (
    <>
      <FormGroup
        check
        className={classNames({ "has-danger": formErrors.consentError })}
      >
        <Label check>
          <Input
            type="checkbox"
            value={1}
            id={"tos-consent"}
            disabled={isLoading}
          ></Input>
          <span className="form-check-sign"></span>
          Akceptuję regulamin<sup className={"text-danger"}>*</sup>
          <p className={"disclaimer"}>
            <small>
              Oświadczam, iż zapoznałem/am się z treścią{" "}
              <a
                href={"/legal/regulamin"}
                target={"_blankLegal"}
                rel={"nofollow"}
              >
                Regulaminu
              </a>{" "}
              oraz{" "}
              <a
                href={"/legal/polityka-prywatnosci"}
                target={"_blankLegal"}
                rel={"nofollow"}
              >
                Polityki Prywatności
              </a>{" "}
              i akceptuję ich treść.
            </small>
          </p>
        </Label>
      </FormGroup>
      <FormGroup
        check
        className={classNames({ "has-danger": formErrors.consentError })}
      >
        <Label check>
          <Input
            type="checkbox"
            value={1}
            id={"newsletter-consent"}
            disabled={isLoading}
          ></Input>
          <span className="form-check-sign"></span>
          Newsletter - bądź na bieżąco!{" "}
          <i className="fa fa-arrow-left text-success bounce-left"></i>
          <p className={"disclaimer"}>
            <small>
              Chcę zapisać się do Newslettera, tym samym wyrażam zgodę na
              otrzymywanie informacji o produktach, usługach i promocjach od
              Administratora i przetwarzanie moich danych osobowych w tym celu –
              zgodnie{" "}
              <a
                href={"/legal/polityka-prywatnosci"}
                target={"_blankLegal"}
                rel={"nofollow"}
              >
                Polityką Prywatności
              </a>
              .
            </small>
          </p>
        </Label>
      </FormGroup>
      {!isSecondLoginStage ? (
        <div className="login-modal__bottom-container">
          <a href="#" onClick={changeTab("login")}>
            Masz już konto? Zaloguj się.
          </a>
        </div>
      ) : null}
    </>
  );

  const bottomRecoverPasswordContainer = (
    <p className={"RML-recover-password-info"}>
      Podaj swój email a my wyślemy Tobie email z instrukcją jak zresetować
      hasło.
    </p>
  );

  const registerInputs = [
    {
      containerClass: classNames("form-group", {
        "has-danger": formErrors.registerEmail,
      }),
      label: "Email",
      type: "email",
      inputClass: "form-control",
      id: "email",
      name: "email",
      placeholder: "Email",
      defaultValue: registerData.email,
      onBlur: onRegisterEmailBlur,
    },
    {
      containerClass: classNames("form-group", {
        "has-danger": formErrors.registerNickname,
      }),
      label: "Nazwa użytkownika",
      type: "text",
      inputClass: "form-control",
      id: "login",
      name: "login",
      defaultValue: registerData.nickname,
      placeholder: "Nazwa użytkownika",
    },
  ];

  if (!isSecondLoginStage) {
    registerInputs.push({
      containerClass: classNames("form-group", {
        "has-danger": formErrors.registerPassword,
      }),
      label: "Hasło",
      type: "password",
      inputClass: "form-control",
      id: "password",
      name: "password",
      placeholder: "Hasło",
    });
  }

  return (
    <ReactModalLogin
      visible={true}
      onCloseModal={onClose}
      startLoading={onStartLoading}
      finishLoading={finishLoading}
      loading={isLoading}
      initialTab={tab}
      error={error}
      mainWrapClass={classNames("RML-login-modal-wrap", {
        "RML-hidden-socials": isSecondLoginStage,
      })}
      closeBtn={{
        element: (
          <div className={"RML-login-modal-close"}>
            <button aria-hidden="true" className="close" type="button">
              <i className="now-ui-icons ui-1_simple-remove"></i>
            </button>
          </div>
        ),
      }}
      loginError={{
        containerClass: "RML-login-modal-error--login",
        label:
          formErrors.loginEmail || formErrors.loginPassword
            ? [formErrors.loginEmail, formErrors.loginPassword].join(" ")
            : "Nie udało się zalogować. Spróbuj ponownie.",
      }}
      registerError={{
        label:
          formErrors.registerPassword ||
          formErrors.registerEmail ||
          formErrors.registerNickname ||
          formErrors.tosConsent
            ? [
                formErrors.registerEmail,
                formErrors.registerPassword,
                formErrors.registerNickname,
                formErrors.tosConsent,
              ].join(" ")
            : formatError(
                user.registerError,
                "Nie udało się zarejestrować. Spróbuj ponownie."
              ),
      }}
      recoverPasswordError={{
        label:
          formErrors.recoverEmail ||
          "Nie udało się zarejestrować. Spróbuj ponownie.",
      }}
      tabs={{
        afterChange: onTabChange,
        loginLabel: "Logowanie",
        registerLabel: isSecondLoginStage ? "Ostatni krok" : "Rejestracja",
        onLoginClickAfterTransition: changeTab("login"),
        onRegisterClickAfterTransition: changeTab("register"),
        onRecoverPasswordClickAfterTransition: changeTab("recoverPassword"),
      }}
      recoverPasswordAnchor={{
        label: "zapomniałem",
      }}
      form={{
        bottomLoginContainer: bottomLoginContainer,
        bottomRegisterContainer: bottomRegisterContainer,
        bottomRecoverPasswordContainer: bottomRecoverPasswordContainer,
        recoverPasswordSuccessLabel: user.remindPasswordSuccess
          ? {
              label: "Wysłaliśmy Tobie email z instrukcjami.",
            }
          : null,
        onLogin: onLogin,
        onRegister: onRegister,
        onRecoverPassword: onRecoverPassword,
        loginBtn: {
          label: "Zaloguj",
        },
        registerBtn: {
          label: isSecondLoginStage ? "Dokończ rejestrację" : "Zarejestruj",
        },
        recoverPasswordBtn: {
          label: "Wyślij przypomnienie",
        },
        loginInputs: [
          {
            containerClass: classNames("form-group", {
              "has-danger": formErrors.loginEmail,
            }),
            label: null,
            type: "email",
            inputClass: "form-control",
            id: "email",
            name: "email",
            placeholder: "Email",
          },
          {
            containerClass: classNames("form-group", {
              "has-danger": formErrors.loginPassword,
            }),
            label: null,
            type: "password",
            inputClass: "form-control",
            id: "password",
            name: "password",
            placeholder: "Hasło",
          },
        ],
        registerInputs: registerInputs,
        recoverPasswordInputs: [
          {
            containerClass: classNames("form-group", {
              "has-danger": formErrors.recoverEmail || false,
            }),
            label: "Twój email",
            type: "text",
            inputClass: "form-control",
            id: "email",
            name: "email",
            placeholder: "Twój email",
            disabled: isLoading,
          },
        ],
      }}
      separator={{
        label: "lub",
      }}
      providers={{
        facebook: {
          config: facebookConfig,
          onLoginSuccess: onLogin,
          onLoginFail: onLoginError,
          inactive: isLoading,
          label: "Kontynuuj z Facebookiem",
        },
        google: {
          config: googleConfig,
          onLoginSuccess: onLogin,
          onLoginFail: onLoginError,
          inactive: isLoading,
          label: "Kontynuuj z Google",
        },
      }}
    />
  );
};

export default LoginModal;
