/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

const Header = (props) => {
  return (
    <div className="page-header page-header-small">
      <div
        className="page-header-image"
        style={{
          backgroundImage:
            "url(/img/bg/bg01.png)",
        }}
      ></div>
      <div className="content-center text-center">
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h1 className="title">Łatwiej się nie da.</h1>
            <h4 className="description text-white">
              Jestem tu po to, aby pomóc Tobie zacząć przygodę z programowaniem.
              Znajdziesz tu dokładnie 101<sub>(2)</sub> materiałów, które pomogą
              Tobie zostać cyfrowym Twórcą.
            </h4>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
