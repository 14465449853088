import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter, matchPath } from "react-router-dom";
import { history } from "./store";
import LoginModal from "./modals/LoginModal";
import ProfileEditModal from "./modals/ProfileEditModal";
import ArticleEditModal from "./modals/ArticleEditModal";
import EmailSubscribeModal from "./modals/EmailSubscribeModal";
import NotificationsEditModal from "./modals/NotificationsEditModal";

const ModalRoutesComponent = React.memo(
  (props) => {
    const [modalClosePath, setModalClosePath] = useState(null);

    useEffect(() => {
      updateModalClosePath();
    }, []);

    useEffect(() => {
      setModalClosePath(null);
    }, [props.location.pathname]);

    /**
     *
     */
    const updateModalClosePath = () => {
      const modalLinkIndex = props.prevModalLocation.findIndex((modalLink) => {
        const match = matchPath(props.location.pathname, {
          path: modalLink,
          exact: true,
          strict: false,
        });

        return match !== null;
      });

      if (modalLinkIndex >= 0) {
        setModalClosePath(
          Object.values(props.prevModalLocation[modalLinkIndex])[0]
        );
      }
    };

    /**
     *
     */
    const onCloseAction = () => {
      if (modalClosePath) {
        history.push(modalClosePath);
      } else {
        history.goBack();
      }
    };

    return (
      <Switch>
        <Route exact path="/logowanie" component={LoginModal} />
        <Route exact path="/rejestracja" component={LoginModal} />
        <Route exact path="/przypomnienie-hasla" component={LoginModal} />
        <Route exact path="/edytuj-profil" component={ProfileEditModal} />
        <Route exact path="/nowy-artykul" component={ArticleEditModal} />
        <Route exact path="/edytuj-artykul/:slug" component={ArticleEditModal} />
        <Route exact path="/subskrybuj" component={EmailSubscribeModal} />
        <Route exact path="/edytuj-powiadomienia" component={NotificationsEditModal} />
      </Switch>
    );
  },
  (oldProps, newProps) => {
    if (oldProps.location.pathname !== newProps.location.pathname) {
      return false;
    } else {
      // don't update, locations are the same
      return true;
    }
  }
);

export default withRouter(ModalRoutesComponent);
