/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import URL from "url-parse";
import { Link } from "react-router-dom";

const ImageBlock = (props) => {
  return (
    <span className="markdown-content__image-block">
      <a
        href={props.imageData ? props.imageData.orig_path : props.src}
        target="_blank"
      >
        <img src={props.src} alt={props.alt} />
      </a>
      <span>{props.title}</span>
    </span>
  );
};

export default ImageBlock;
