/**
 * Created by leper on 04/02/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const SortableCol = (props) => {
  const [state, setState] = useState({
    direction: props.defaultSort.direction || null,
  });

  const isSorted = props.defaultSort.sort === props.id;

  /**
   *
   * @param e
   */
  const onSort = (e) => {
    const sort = { sort: props.id };

    if (!isSorted) {
      sort.direction = "asc";
    } else {
      switch (state.direction) {
        case "asc":
          sort.direction = "desc";
          break;

        case "desc":
          sort.direction = null;
          break;

        default:
          sort.direction = "asc";
          break;
      }
    }

    setState({ direction: sort.direction });

    props.onSort(sort);
  };

  return (
    <th
      width={props.width}
      className={classNames("col-sortable", props.className, {
        "col-sortable-asc": isSorted && state.direction === "asc",
        "col-sortable-desc": isSorted && state.direction === "desc",
      })}
      onClick={onSort}
    >
      {props.children}
    </th>
  );
};

SortableCol.defaultProps = {
  defaultSort: {},
};

SortableCol.propTypes = {
  onSort: PropTypes.func,
  id: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  defaultSort: PropTypes.shape({
    sort: PropTypes.string,
    direction: PropTypes.string,
  }),
};

export default SortableCol;
