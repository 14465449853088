/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022.
 */


export default function reducer(
  state = {
    collection: [],
    pagination: {},

    fetch: false,
    fetchSuccess: false,
    fetchError: null,
  },
  action) {

  switch (action.type) {


    case "ADMIN_MAILLIST_FETCH":
      return {
        ...state,

        fetch: true,
        fetchSuccess: false,
        fetchError: null,
      };

    case "ADMIN_MAILLIST_FETCH_REJECTED":
      return {
        ...state,
        fetch: false,
        fetchSuccess: false,
        fetchError: action.payload,
      };

    case "ADMIN_MAILLIST_FETCH_FULFILLED":

      return {
        ...state,

        collection: action.payload.email_subscribers,
        pagination: action.payload.pagination,

        fetch: false,
        fetchSuccess: true,
      }
  }

  return state;
}
