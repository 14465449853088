import React from "react";
import { Helmet } from "react-helmet";
import AxiosConfig from "../AxiosConfig";
import PropTypes from "prop-types";

const SeoBlock = (props) => {
  /*****
   *
   * Title
   */

  const title = <title>{props.title + " | PJL"}</title>;

  const ogTitle = (
    <meta
      property="og:title"
      content={props.title + " - programowaniejestlatwe.pl"}
    />
  );

  /*****
   *
   * URL
   */

  const link = props.link ? (
    <link rel="canonical" href={AxiosConfig.getResourceUrl(props.link)} />
  ) : (
    <link
      rel="canonical"
      href={AxiosConfig.getResourceUrl(window.location.pathname)}
    />
  );

  const ogLink = props.link ? (
    <meta property="og:url" content={AxiosConfig.getResourceUrl(props.link)} />
  ) : (
    <meta
      property="og:url"
      content={AxiosConfig.getResourceUrl(window.location.pathname)}
    />
  );

  /*****
   *
   * Image
   */

  const icon = props.icon ? (
    <link
      rel="icon"
      type="image/png"
      href={AxiosConfig.getResourceUrl(props.icon)}
    />
  ) : (
    <link
      rel="icon"
      type="image/png"
      href={AxiosConfig.getResourceUrl("/favicon.png")}
    />
  );

  const ogImage = props.image ? (
    <meta
      property="og:image"
      content={AxiosConfig.getResourceUrl(props.image)}
    />
  ) : (
    <meta
      property="og:image"
      content={AxiosConfig.getResourceUrl("/favicon.png")}
    />
  );

  /*****
   *
   * Description
   */

  const description = <meta name="description" content={props.description} />;

  const ogDescription = (
    <meta property="og:description" content={props.description} />
  );

  /*****
   *
   * Author & keywords
   */

  const author = props.author ? (
    <meta name="author" content={props.author} />
  ) : (
    <meta name="author" content="hello@programowaniejestlatwe.pl" />
  );

  const keywords = props.keywords ? (
    <meta
      name="keywords"
      content={
        props.keywords +
        ", programowanie jest łatwe, nauka programowania, od czego zacząć, podstawy programowania, warsztaty, informatyka, algorytmy"
      }
    />
  ) : (
    <meta
      name="keywords"
      content="programowanie jest łatwe, nauka programowania, od czego zacząć, podstawy programowania, warsztaty, informatyka, algorytmy"
    />
  );

  /*****
   *
   * Other OG tags
   */

  const ogLocale = props.locale ? (
    <meta property="og:locale" content={props.locale} />
  ) : (
    <meta property="og:locale" content="pl_PL" />
  );

  const ogType = props.type ? (
    <meta property="og:type" content={props.type} />
  ) : (
    <meta property="og:type" content="website" />
  );

  const ogSite = <meta property="og:site_name" content={props.site} />;

  const html = props.htmlClass ? (
    <html lang="pl-PL" className={props.htmlClass} />
  ) : (
    <html lang="pl-PL" />
  );

  return (
    <Helmet>
      {title}
      {link}
      {icon}
      {description}
      {author}
      {keywords}

      {ogTitle}
      {ogLink}
      {ogImage}
      {ogDescription}
      {ogLocale}
      {ogType}
      {ogSite}

      {html}
    </Helmet>
  );
};

SeoBlock.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  description: PropTypes.string,
  author: PropTypes.string,
  locale: PropTypes.string,
  type: PropTypes.string,
  site: PropTypes.string,
  htmlClass: PropTypes.string,
  // ampLink: PropTypes.string,
  keywords: PropTypes.string,
};

SeoBlock.defaultProps = {
  title: "programowaniejestlatwe.pl | PJL",
  description:
    "Zbiór materiałów, artykułów, pytań i odpowiedzi dotyczących programowania. Przekonaj się, że programowanie to nie czarna magia.",
  site: "The Beaverhead Home Page",
  keywords: [
    "bespoke software",
    "custom-made crm",
    "business processes",
    "operational risk reduction",
    "project management tools",
    "spreadsheet to software",
    "minimum viable product",
    "software house",
    "software development company",
  ].join(','),
};

export default SeoBlock;
