/**
 *
 * @param error
 * @param defaultMessage
 * @returns {*}
 */
export const formatError = (error, defaultMessage = "Error has occurred") => {
  const errors = [];

  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && Array.isArray(data.error)) {
      data.error.forEach((i) => {
        if (i.messages && i.messages.length) {
          const fieldName = ucFirst(i.field.replace("_", " "));

          i.messages.map((i) => errors.push(fieldName + " - " + i));
        }
      });

      return errors;
    }
    if (data.message) {
      return [error.response.data.message];
    }
  } else if (error && error.message) {
    return [error.message];
  }

  return [defaultMessage];
};

/**
 *
 * @param str
 * @returns {string}
 */
export const ucFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *
 * @param email
 * @returns {boolean}
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 *
 * @param password
 * @returns {boolean}
 */
export const validatePassword = (password) => {

  if (password && password.length > 5) {
    return true;
  }
  return false;
};

/**
 *
 * @param nickname
 * @returns {boolean}
 */
export const validateNickname = (nickname) => {

  if (nickname && nickname.trim().length) {
    return true;
  }
  return false;
};
