/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as postsActions from "./../actions/posts";

export function usePostsStore() {
  const dispatch = useDispatch();
  const _posts = useSelector((store) => store.posts);

  const fetchLastPosts = useCallback(
    async (kind, page, tags, direction, sort) =>
      await dispatch(postsActions.fetchLastPosts(kind, page, tags, direction, sort)),
    [dispatch]
  );

  const cleanupCollection = useCallback(
    async () => await dispatch(postsActions.cleanupCollection()),
    [dispatch]
  );

  return {
    posts: _posts,
    fetchLastPosts,
    cleanupCollection,
  };
}
