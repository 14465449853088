/**
 * Created by leper on 03/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Table } from "reactstrap";
import TablePagination from "../../../components/TablePagination";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Indicator from "../../../components/Indicator";
import UserAvatar from "../../../components/UserAvatar";
import SortableCol from "../../../components/SortableCol";

const dateTimeFormat = new Intl.DateTimeFormat("pl-PL", {
  dateStyle: "short",
  timeStyle: "short",
});

const kindToIcon = (kind) => {
  switch (kind) {
    case "user":
      return "far fa-user";

    case "admin":
      return "fas fa-user-cog text-danger";
  }
};

const UsersTable = (props) => {
  if (props.isLoading) {
    return (
      <div className={"text-center"}>
        <h4 className={"card-title"}>Wczytuję</h4>
        <Indicator size={64} />
      </div>
    );
  }

  if (!props.isLoading && !props.collection.length) {
    return (
      <div className={"text-center"}>
        <h4 className={"card-title"}>Brak wyników do wyświetlenia</h4>
      </div>
    );
  }

  const rows = props.collection.map((i, idx) => {
    return (
      <tr key={`atr-${i.slug}`}>
        <td className="text-center">
          {i.available ? <i className={"fas fa-check"} /> : null}
          {i.is_verified ? <i className="fas fa-check-double"></i> : null}
        </td>
        <td>
          <UserAvatar user={i} size={32} />
        </td>
        <td>{dateTimeFormat.format(new Date(i.created * 1000))}</td>
        <td>
          {i.last_login
            ? dateTimeFormat.format(new Date(i.last_login * 1000))
            : "Nigdy"}
        </td>
        <td>
          {i.nickname}
          <span className={"d-block text-muted"}>{i.email}</span>
        </td>
        <td>
          <div className={"d-flex justify-content-around"}>
            <span>
              <i className="far fa-comment-alt text-success"></i>{" "}
              {i.comments_count}
            </span>
            <span>
              <i className="fa fa-thumbs-up text-danger"></i>{" "}
              {i.post_votes_count}
            </span>
            <span>
              <i className="fa fa-thumbs-up text-success"></i>{" "}
              {i.comments_count}
            </span>
          </div>
        </td>
        <td className="">
          {i.tags && i.tags.length
            ? i.tags.map((tag, tagIdx) => (
                <span key={`${i.slug}-${tag.slug}`}>
                  <span className={"text-muted"}>#</span>
                  {tag.slug}{" "}
                </span>
              ))
            : null}
        </td>
        <td className="text-right">
          <Button
            tag={Link}
            to={`/edytuj-profil/${i.slug}`}
            className="btn-icon mr-1 d-inline-flex justify-content-center align-items-center"
            color="success"
            size="sm"
            type="button"
          >
            <i className="far fa-edit"></i>
          </Button>
          {/*<Button className="btn-icon" color="danger" size="sm" type="button">
            <i className="far fa-trash-alt"></i>
          </Button>*/}
        </td>
      </tr>
    );
  });

  let resultsLabel = "Wynik";

  if (props.pagination.count > 1) {
    resultsLabel = "Wyniki";
  }
  if (props.pagination.count > 4) {
    resultsLabel = "Wyników";
  }

  return (
    <>
      <h4 className={"card-title"}>
        {props.pagination.count} {resultsLabel}
      </h4>
      <Table responsive>
        <thead>
          <tr>
            <th width={"5%"}></th>
            <th width={"5%"}></th>
            <SortableCol
              id={"created"}
              width={"12%"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Utworzony
            </SortableCol>
            <SortableCol
              id={"last_login"}
              width={"12%"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Logowanie
            </SortableCol>
            <SortableCol
              id={"nickname"}
              width={"*"}
              onSort={props.onSort}
              defaultSort={props.sort}
            >
              Nazwa
            </SortableCol>
            <th width={"15%"}>Aktywność</th>
            <th width={"13%"} className={"text-right"}>
              Akcje
            </th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <TablePagination
        page={props.pagination.page || 0}
        pagesCount={props.pagination.pageCount || 0}
        linkBuilder={props.linkBuilder}
      />
    </>
  );
};

UsersTable.defaultProps = {};

UsersTable.propTypes = {
  onSort: PropTypes.func,
};

export default UsersTable;
