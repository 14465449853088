/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/02/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ModalTemplate from "../templates/ModalTemplate";
import smartHistoryBack from "../tools/smartHistoryBack";
import { Alert, Container, Form, FormGroup, Input, Label } from "reactstrap";
import { useUserStore } from "../hooks/user";
import { validateNickname } from "../helpers/error";
import ApiError from "../components/ApiError";
import useUnload from "../hooks/useUnload";
import { useAppStore } from "../hooks/app";
import {updateMailList} from "../actions/mailList";
import {useMailListStore} from "../hooks/mailList";

const NotificationsEditModal = (props) => {
  const { user } = useUserStore();
  const {mailList, updateMailList} = useMailListStore();
  const { clearErrors } = useAppStore();

  const userModel = user.model;
  const emailSubscriber = userModel && userModel.email_subscriber ? userModel.email_subscriber : {}


  const emailNotificationOptions = [
    {
      label: "Newsletter - bądź na bierząco",
      id: "notify_newsletter",
      checked: emailSubscriber.notify_newsletter,
    },
    {
      label: "Marketing - promocje i oferty",
      id: "notify_marketing",
      checked: emailSubscriber.notify_marketing,
    },
    {
      label: "Nowa aktywność w treści, którą skomentowałeś",
      id: "notify_comments",
      checked: emailSubscriber.notify_comments,
    },
    {
      label: "Odpowiedzi do Twoich komentarzy",
      id: "notify_new_reply",
      checked: emailSubscriber.notify_new_reply,
    },
  ];

  useUnload(() => {
    clearErrors();
  });
  /**
   *
   */
  const onClose = () => {
    smartHistoryBack(props.history);
  };

  useEffect(() => {
    if (mailList.editSuccess) {
      clearErrors();
      props.history.push(`/uzytkownik/${user.model.slug}`);
    }
  }, [mailList.editSuccess]);

  /**
   *
   * @param e
   */
  const onSubmit = (e) => {
    e.preventDefault();

    const data = {};
    emailNotificationOptions.map(i => {
      data[i.id] = document.getElementById(i.id).checked;
    });


    updateMailList(data, user.model.email);
  };

  const isLoading = mailList.edit;

  const apiError = mailList.editError ? (
    <ApiError
      error={mailList.editError}
      defaultMessage={"Zmiany nie zostały zapisane. Spróbuj ponownie."}
    />
  ) : null;


  return (
    <ModalTemplate
      onClose={onClose}
      size={"md"}
      title={"Zarządzaj powiadomieniami"}
      onCancel={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form>
        <div className="form-row">
          <FormGroup className="col-md-12">
            <Label>Powiadomienia Email:</Label>
            <Input
              placeholder="Email"
              type="email"
              defaultValue={userModel.email}
              disabled={true}
            ></Input>
          </FormGroup>
          <FormGroup check disabled>
            <Label check>
              <Input
                defaultValue="1"
                type="checkbox"
                disabled={true}
                defaultChecked={true}
              ></Input>
              Powiadomienia systemowe - niezbędne dla prawidłowego działania platformy
              <span className="form-check-sign">
                  <span className="check"></span>
                </span>
            </Label>
          </FormGroup>
          {emailNotificationOptions.map((i, idx) => (
            <FormGroup check disabled={isLoading}>
              <Label check>
                <Input
                  id={i.id}
                  defaultValue="1"
                  type="checkbox"
                  defaultChecked={i.checked}
                  disabled={isLoading}
                ></Input>
                {i.label}
                <span className="form-check-sign">
                  <span className="check"></span>
                </span>
              </Label>
            </FormGroup>
          ))}
        </div>
        {apiError}
      </Form>
    </ModalTemplate>
  );
};

NotificationsEditModal.defaultProps = {};

NotificationsEditModal.propTypes = {};

export default NotificationsEditModal;
