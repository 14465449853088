/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import AxiosConfig from "../../AxiosConfig";
import { Link } from "react-router-dom";
import { useUserStore } from "../../hooks/user";
import UserAvatar from "../../components/UserAvatar";
import { preventDefault } from "../../tools/doms";
import Indicator from "../../components/Indicator";
import classNames from "classnames";

const Header = (props) => {
  const pageHeader = useRef();
  const { profile } = props;
  const { user, uploadAvatar, updateUser, deleteUser } = useUserStore();

  const avatarFileRef = useRef(null);
  const isOwner = profile && user.model && profile.id === user.model.id;
  const isLoading = user.avatarUpload || user.edit;

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  /**
   *
   * @param e
   */
  const onFileSelectorClick = (e) => {
    e.preventDefault();
    avatarFileRef.current.click();
  };

  /**
   *
   * @param e
   */
  const onRemoveAvatar = (e) => {
    e.preventDefault();
    updateUser(user.model.id, { avatar: "no_avatar.png" });
  };

  /**
   *
   * @param e
   */
  const onAvatarSelected = (e) => {
    if (e.target.files[0]) {
      uploadAvatar(user.model.id, e.target.files[0]);
    }
  };

  /**
   *
   * @param e
   */
  const onDeleteAccount = (e) => {
    e.preventDefault();

    if (
      window.prompt(
        "Na pewno chcesz usunąć swoje konto? Wpisz nazwę użytkownika a my wyślemy Tobie email z dalszymi instrukcjami"
      ) == profile.nickname
    ) {
      deleteUser();
    }
  };

  const avatar = profile ? (
    <div className="photo-container">
      <img
        alt={profile.nickname}
        src={AxiosConfig.getResourceUrl(`/avatars/${profile.avatar}`)}
      ></img>
    </div>
  ) : null;

  const editableAvatar = isOwner ? (
    <>
      {isLoading ? <Indicator size={100} /> : null}
      <UncontrolledDropdown
        className={classNames({
          "d-none": isLoading,
          "d-inline-block": !isLoading,
        })}
      >
        <DropdownToggle
          data-toggle="dropdown"
          id="profile-avatar-options"
          to={"#"}
          tag={Link}
          className={""}
          onClick={preventDefault}
        >
          {avatar}
        </DropdownToggle>
        <DropdownMenu aria-labelledby="profile-avatar-options">
          <DropdownItem tag={Link} to={`#`} onClick={onFileSelectorClick}>
            Zmień zdjęcie profilowe
          </DropdownItem>
          {profile.avatar && profile.avatar !== "no_avatar.png" ? (
            <DropdownItem tag={Link} to={`#`} onClick={onRemoveAvatar}>
              Usuń zdjęcie profilowe
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </UncontrolledDropdown>

      <input
        type="file"
        id={"file-avatar"}
        ref={avatarFileRef}
        accept="image/png, image/jpeg"
        onChange={onAvatarSelected}
        className={"d-none"}
      />
    </>
  ) : (
    avatar
  );

  const title = profile ? profile.nickname : "Wczytuję profil...";

  const homepageButton =
    profile && profile.homepage_url ? (
      <Button
        className="btn-icon btn-round mr-1"
        color="info"
        size="lg"
        href={profile.homepage_url}
      >
        <i className="fas fa-home"></i>
      </Button>
    ) : null;

  const githubButton =
    profile && profile.homepage_url ? (
      <Button
        className="btn-icon btn-round mr-1"
        color="github"
        size="lg"
        target={"_blank_git_pjl_"}
        href={profile.git_url}
      >
        <i className="fab fa-github"></i>
      </Button>
    ) : null;

  const editProfileButton = isOwner ? (
    <>
      <UncontrolledDropdown className="d-inline-block">
        <DropdownToggle
          aria-expanded={false}
          aria-haspopup={true}
          caret
          color="secondary"
          data-toggle="dropdown"
          id="dropdownMenuButton"
          type="button"
        >
          Ustawienia
        </DropdownToggle>
        <DropdownMenu aria-labelledby="navbarDropdown">
          <DropdownItem tag={Link} to={`/edytuj-profil`}>
            Edytuj profil
          </DropdownItem>
          <DropdownItem tag={Link} to={`/edytuj-powiadomienia`}>
            Zarządzaj powiadomieniami
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to={`#`} onClick={onDeleteAccount}>
            Usuń konto
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  ) : null;

  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
        style={{ marginTop: "88px" }}
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg5.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          {editableAvatar}
          <h3 className="title">
            {title}
          </h3>
            {editProfileButton}
          <Row>
            <Col xs={12}>
              <div className="social-description">
                {homepageButton}
                {githubButton}

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

Header.defaultProps = {};

Header.propTypes = {
  profile: PropTypes.shape({
    nickname: PropTypes.string,
    homepage_url: PropTypes.string,
    github_url: PropTypes.string,
  }),
};

export default Header;
