/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/01/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import DropdownNavyNavbar from "./PageTemplate/DropdownNavyNavbar";
import FooterNavy from "./PageTemplate/FooterNavy";
import Navbar from "./PageTemplate/Navbar";
import CookiePopup from "../components/CookiePopup";
import { useLocation } from "react-router-dom";

const PageTemplate = (props) => {
  const url = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [url.pathname]);

  return (
    <>
      <Navbar>
        <DropdownNavyNavbar />
      </Navbar>
      <div className={"wrapper"}>{props.children}</div>
      <FooterNavy />
      <CookiePopup />
    </>
  );
};

PageTemplate.defaultProps = {};

PageTemplate.propTypes = {};

export default PageTemplate;
