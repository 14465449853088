/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import smartHistoryBack from "../tools/smartHistoryBack";
import useUnload from "../hooks/useUnload";
import ModalTemplate from "../templates/ModalTemplate";
import { useAppStore } from "../hooks/app";
import {
  Alert,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { useMailListStore } from "../hooks/mailList";
import ApiError from "../components/ApiError";
import { validateEmail } from "../helpers/error";
import classNames from "classnames";

const EmailSubscribeModal = (props) => {
  const { clearErrors } = useAppStore();
  const [formErrors, setFormErrors] = useState({
    emailError: null,
    consentError: null,
  });

  const [emailInputFocus, setEmailInputFocus] = useState(false);
  const { mailList, subscribeMailList } = useMailListStore();

  const isLoading = mailList.subscribe;
  const signupSuccess = mailList.subscribeSuccess;

  useUnload(() => {
    clearErrors();
  });

  /**
   *
   */
  const onClose = () => {
    clearErrors();

    smartHistoryBack(props.history);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = document.getElementById("subscribe-email").value;
    const consent = document.getElementById("newsletter-consent").checked;

    let emailError = null;
    let consentError = null;

    if (!email.length || !validateEmail(email)) {
      emailError = "Jakaś literówka w adresie email.";
    }

    if (!consent) {
      consentError = "Wyrażenie zgody jest obowiązkowe.";
    }

    setFormErrors({ emailError, consentError });
    if (!emailError && !consentError) {
      await subscribeMailList(email);
    }
  };

  const onEmailInputFocus = (focus) => (e) => {
    setEmailInputFocus(focus);
  };

  const successAlert = signupSuccess ? (
    <Alert color={"success"} className={"mt-1"}>
      <i className="fas fa-exclamation-circle"></i> Pomyślnie zapisałeś się do
      naszego newslettera! Potwierdzenie znajdziesz na swojej skrzynce.
    </Alert>
  ) : null;

  const apiError = mailList.subscribeError ? (
    <ApiError error={mailList.subscribeError} />
  ) : null;

  const formError =
    formErrors.emailError || formErrors.consentError ? (
      <Alert color={"danger"} className={"mt-1"}>
        <i className="fas fa-exclamation-circle"></i> {formErrors.emailError}{" "}
        {formErrors.consentError}
      </Alert>
    ) : null;

  return (
    <ModalTemplate
      onClose={onClose}
      size={"md"}
      title={signupSuccess ? "Dzięki!" : "Zapisz się do newslettera!"}
      onCancel={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
      submitButtonLabel={signupSuccess ? null : "Subskrybuj"}
      cancelButtonLabel={signupSuccess ? "Zamknij" : "Anuluj"}
    >
      <p>
        Spędzając z nami czas poczujesz, że nie taki diabeł straszny, a
        programowanie to naprawdę hobby, które daje możliwość podboju świata.
      </p>
      <Form onSubmit={onSubmit}>
        <InputGroup
          className={classNames({
            "has-danger": formErrors.emailError,
            disabled: isLoading,
            "input-group-focus": emailInputFocus,
          })}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="far fa-envelope"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            className={classNames("form-control", {
              "form-control-danger": formErrors.emailError,
            })}
            autoComplete="email"
            placeholder="Twój adres email"
            type="email"
            id={"subscribe-email"}
            onFocus={onEmailInputFocus(true)}
            onBlur={onEmailInputFocus(false)}
            disabled={isLoading}
          ></Input>
        </InputGroup>
        <strong>Twoje dane traktujemy poważnie.</strong>

        <FormGroup
          check
          className={classNames({ "has-danger": formErrors.consentError })}
        >
          <Label check>
            <Input
              type="checkbox"
              value={1}
              id={"newsletter-consent"}
              disabled={isLoading}
            ></Input>
            <span className="form-check-sign"></span>
            Zgoda
            <p className={"disclaimer"}>
              <small>
                Chcę zapisać się do Newslettera, tym samym wyrażam zgodę na
                otrzymywanie informacji o produktach, usługach i promocjach od
                Administratora i przetwarzanie moich danych osobowych w tym celu
                – zgodnie{" "}
                <a
                  href={"/legal/polityka-prywatnosci"}
                  target={"_blankLegal"}
                  rel={"nofollow"}
                >
                  Polityką prywatności
                </a>
                .
              </small>
            </p>
          </Label>
        </FormGroup>
      </Form>
      {successAlert}
      {apiError}
      {formError}
    </ModalTemplate>
  );
};

EmailSubscribeModal.defaultProps = {};

EmailSubscribeModal.propTypes = {};

export default EmailSubscribeModal;
