import axios from "axios";
import AxiosConfig from "../AxiosConfig";
import { history } from "../store";

/**
 *
 * @param queryParams
 * @returns {(function(*): void)|*}
 */
export function fetchAdmin(queryParams) {
  return function (dispatch) {
    dispatch({ type: "FETCH_ADMIN_USERS" });

    axios
      .get(
        AxiosConfig.getEndpointAddress() +
          "/api/users.json?" +
          AxiosConfig.objectToURLQuery(queryParams),
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "FETCH_ADMIN_USERS_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "FETCH_ADMIN_USERS_REJECTED",
          payload: error,
        });
      });
  };
}
