export default class AxiosConfig {
  static _token = "";

  static _config = {
    headers: {
      Accept: "application/json, text/javascript, /; q=0.01",
      "Content-Type": "application/json",
    },
  };

  static endpointAddress =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:20030"
      : "";

  /**
   *
   * @param token
   */
  static setAuthToken(token) {
    AxiosConfig._token = token;
  }

  /**
   *
   * @returns {string}
   */
  static getAuthToken() {
    return AxiosConfig._token;
  }

  /**
   *
   * @returns {object}
   */
  static getConfig() {
    return AxiosConfig._config;
  }

  /**
   *
   * @returns {object}
   */
  static getAuthConfig() {
    return {
      headers: {
        ...AxiosConfig._config.headers,
        Authorization: "Bearer " + AxiosConfig._token,
      },
    };
  }

  /**
   *
   * @returns {*}
   */
  static getAuthConfigIfAvailable() {
    if (AxiosConfig._token) {
      return AxiosConfig.getAuthConfig();
    }

    return AxiosConfig.getConfig();
  }

  /**
   *
   */
  static getEndpointAddress() {
    return AxiosConfig.endpointAddress;
  }

  /**
   *
   * @param params
   * @returns {string}
   */
  static objectToURLQuery(params = {}) {
    let arResult = [];
    Object.keys(params).forEach((key) => {
      if (params[key] !== null) {
        arResult.push(key + "=" + params[key]);
      }
    });
    return arResult.join("&");
  }

  /**
   *
   * @param path
   * @returns {*}
   */
  static getResourceUrl = (path) => {
    const host =
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? AxiosConfig.getEndpointAddress()
        : "https://programowaniejestlatwe.pl";

    return host + path;
  };
}
