/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/01/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import Header from "./ArticlePage/Header";
import { usePostStore } from "../hooks/post";
import { useHistory } from "react-router-dom";
import { extractBrief } from "../tools/text";
import SeoBlock from "../components/SeoBlock";
import ArticleBody from "./ArticlePage/ArticleBody";
import { Col, Container, Row } from "reactstrap";
import Comments from "./ArticlePage/Comments";
import Author from "./ArticlePage/Author";
import { useUserStore } from "../hooks/user";
import NewComment from "./ArticlePage/NewComment";
import { useCommentStore } from "../hooks/comment";
import Article from "../components/Schemas/Article";
import AxiosConfig from "../AxiosConfig";
import LikeButton from "./ArticlePage/LikeButton";
import { useSimilarPosts } from "../hooks/similarPosts";
import ArticleCard from "./ArticlesIndexPage/ArticleCard";

const ArticlePage = (props) => {
  const [postLoaded, setPostLoaded] = useState(false);

  const { user } = useUserStore();
  const { post, fetchPost, votePost, unvotePost } = usePostStore();
  const { voteComment, unVoteComment, comment } = useCommentStore();
  const { similarPosts, fetchSimilar } = useSimilarPosts();

  const postModel = post.model || {};

  useEffect(() => {
    fetchPost(props.match.params.slug);
    fetchSimilar(props.match.params.slug);

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, [props.match.params.slug]);

  useEffect(() => {
    document.body.classList.add("blog-post");

    return () => {
      document.body.classList.remove("blog-post");
    };
  }, []);

  useEffect(() => {
    if (post.fetchSuccess) {
      setPostLoaded(true);
    }
  }, [post.fetchSuccess]);

  /**
   *
   * @param e
   */
  const onVote = (e) => {
    if (!user.model) {
      props.history.push("/logowanie");
    } else {
      if (Number.parseInt(postModel.user_vote) === 0) {
        votePost(postModel.id, 1);
      } else {
        unvotePost(postModel.id);
      }
    }
  };

  /**
   *
   * @param comment
   */
  const onVoteComment = (comment) => {
    if (!user.model) {
      props.history.push("/logowanie");
    } else {
      if (Number.parseInt(comment.user_vote) === 0) {
        voteComment(comment.id, 1);
      } else {
        unVoteComment(comment.id);
      }
    }
  };

  const title =
    post.fetch || !postModel.title ? "Wczytuję artykuł..." : postModel.title;

  const coverImage =
    postModel.images && postModel.images.length
      ? postModel.images.find((i) => i.is_cover)
      : null;

  let briefWordsArray = [];

  if (postLoaded && postModel.abstract) {
    briefWordsArray = extractBrief(postModel.abstract, 30).briefWordsArray;
  } else if (postLoaded && postModel.body) {
    briefWordsArray = extractBrief(postModel.body, 30).briefWordsArray;
  }

  const tagsInputValue =
    postLoaded && postModel.tags.length
      ? postModel.tags.map((item) => {
          return item.label;
        })
      : null;

  const seoBlock = postLoaded ? (
    <>
      <SeoBlock
        title={postModel.title}
        description={briefWordsArray.join(" ")}
        keywords={tagsInputValue ? tagsInputValue.join(", ") : null}
        image={coverImage ? coverImage.thumb_md_path : null}
        author={postModel.user.nickname}
        type="article"
      />
      <Article
        article={{
          title: postModel.meta_title,
          headline: postModel.meta_description,
          description: postModel.body,
          publishedDate: new Date(postModel.publish_date).toISOString(),
          slug: postModel.slug,
          url: AxiosConfig.getResourceUrl(`/artykul/${postModel.slug}`),
          tags: postModel.tags.map((i) => i.label),
          featured: coverImage
            ? {
                title: postModel.meta_title,
                src: coverImage.orig_path,
                width: "800",
                height: "600",
              }
            : null,
          author: {
            name: postModel.user.nickname,
            imageUrl: AxiosConfig.getResourceUrl(
              `/avatar/${postModel.user.avatar}`
            ),
            url: AxiosConfig.getResourceUrl(
              `/uzytkownik/${postModel.user.slug}`
            ),
          },
          publisher: {
            contactPoint: "",
            address: "",
            logoUrl: AxiosConfig.getResourceUrl(`/img/pjl_logo_white.png`),
            name: "PJL",
            fullName: "Programowanie Jest Łatwe",
          },
        }}
        lastModified={new Date(
          (postModel.modified || postModel.publish_date) * 1000
        ).toISOString()}
      />
    </>
  ) : null;

  const postBody = postLoaded ? (
    <ArticleBody body={postModel.body} votes={postModel.votes} />
  ) : null;

  const comments = postLoaded ? (
    <Comments
      comments={postModel.comments}
      comments_count={postModel.comments_count}
      onVoteComment={onVoteComment}
      createdCommentId={comment.createdCommentId}
      user={user.model}
    />
  ) : null;

  const similar = similarPosts.collection.length
    ? similarPosts.collection.map((i, idx) => (
        <ArticleCard key={i.slug} post={i} showPublishDate={false} />
      ))
    : null;

  const similarPostsBlock = similar ? (
    <>
      <Col md={12}>
        <h4>Powiązane tematy</h4>
      </Col>
      {similar}
    </>
  ) : null;

  return (
    <PageTemplate>
      {seoBlock}
      <Header
        title={title}
        coverImage={coverImage}
        commentsCount={postModel.comments_count}
        created={postModel.publish_date}
        votes={postModel.votes}
        id={postModel.id}
        slug={postModel.slug}
        userVoted={Number.parseInt(postModel.user_vote) !== 0}
        onVote={onVote}
        voting={post.vote}
      />

      <div className="section">
        <Container>
          <Row>
            <Col className="" md="8">
              {postBody}
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-blog-info">
        <Container>
          <Row>
            <Col md="8">
              <Row>
                <Col md="6">
                  <div className="blog-tags">
                    Tag:{" "}
                    {postModel.tags && postModel.tags.length
                      ? postModel.tags.map((i) => (
                          <label className="label label-info mr-1">
                            #{i.slug}
                          </label>
                        ))
                      : null}
                  </div>
                </Col>
                <Col md="6" className={"text-right"}>
                  <LikeButton
                    votes={postModel.votes}
                    onVote={onVote}
                    userVoted={Number.parseInt(postModel.user_vote) !== 0}
                    isLoading={post.vote}
                    label={"Dobry kontent!"}
                  />
                </Col>
              </Row>
              <hr></hr>

              {postModel.user ? <Author user={postModel.user} /> : null}
            </Col>
          </Row>
        </Container>
      </div>
      <NewComment />
      {comments}
      <Container>
        <Row>
          <Col className="" md="8"></Col>

          {similarPostsBlock}
        </Row>
      </Container>
    </PageTemplate>
  );
};

ArticlePage.defaultProps = {};

ArticlePage.propTypes = {};

export default ArticlePage;
