/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/02/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as postsActions from "./../actions/posts";

export function useSimilarPosts() {
  const dispatch = useDispatch();
  const _similarPosts = useSelector((store) => store.similarPosts);

  const fetchSimilar = useCallback(
    async (slug) => await dispatch(postsActions.fetchSimilar(slug)),
    [dispatch]
  );


  return {
    similarPosts: _similarPosts,
    fetchSimilar,
  };
}
