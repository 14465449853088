/**
 * Created by leper on 07/03/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { Link } from "react-router-dom";
import PreloadImage from "../../components/PreloadImage";
import AxiosConfig from "../../AxiosConfig";
import { kindToUrl } from "../../helpers/post";

const EpisodeThumbnail = (props) => {
  const { post } = props;

  const postUrl = post.kind ? `/${kindToUrl(post.kind)}/${post.slug}` : '/subskrybuj';

  const coverImage =
    post.images && post.images.length
      ? post.images.find((i) => i.is_cover)
      : null;

  const previewImage = coverImage ? (
    <PreloadImage
      alt={post.title}
      className="img rounded img-raised"
      src={AxiosConfig.getResourceUrl(coverImage.thumb_md_path)}
      previewData={coverImage.preview}
    />
  ) : null;

  return (
    <Col sm={props.sm}>
      <Card className="card-plain card-blog">
        <div className="card-image">
          <Link to={postUrl}>
            <div className={"img-16x9"}>{previewImage}</div>
          </Link>
        </div>

        <CardBody>
          <CardTitle tag="h4">
            <Link to={postUrl}>{post.title}</Link>
          </CardTitle>
        </CardBody>
      </Card>
    </Col>
  );
};

EpisodeThumbnail.defaultProps = {
  sm: null
};

EpisodeThumbnail.propTypes = {
  sm: PropTypes.number
};

export default EpisodeThumbnail;
