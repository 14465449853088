/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/01/2022
 */

import React from "react";
// reactstrap components
import {
  Navbar,
} from "reactstrap";

const DropdownNavyNavbar = (props) => {
  return (
    <>
      <Navbar className={"fixed-top"} color="navy" expand="lg">
        {props.children}
      </Navbar>
    </>
  );
};

export default DropdownNavyNavbar;
