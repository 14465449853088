/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import AdminPageTemplate from "../../templates/AdminPageTemplate";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Table,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Datetime from "react-datetime";
import ArticlesTable from "./ArticlesIndexPage/ArticlesTable";
import { useAdminPostsStore } from "../../hooks/adminPosts";
import Select from "react-select";
import { PostKindOptions } from "../../consts/posts";
import AxiosConfig from "../../AxiosConfig";
import { history } from "../../store";
import Indicator from "../../components/Indicator";
import { Link } from "react-router-dom";

const ArticlesIndexPage = (props) => {
  const { adminPosts, fetchPostsAdmin } = useAdminPostsStore();

  const isLoading = adminPosts.fetch;
  const params = new URLSearchParams(window.location.search);

  const [state, setState] = useState({
    tags: params.get("tags") || null,
    created_after: params.get("created_after") || null,
    created_before: params.get("created_before") || null,
  });

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return {
      page: params.get("page") || 1,
      kind: params.get("kind"),
      title: params.get("title") || "",
      tags: params.get("tags") || null,
      created_after: params.get("created_after") || null,
      created_before: params.get("created_before") || null,
      sort: params.get("sort") || null,
      direction: params.get("direction") || null,
    };
  }, [window.location.search]);

  /**
   *
   * @type {function(*): string}
   */
  const linkBuilder = useCallback(
    (newParams) => {
      const params = { ...searchParams, ...newParams };
      return `/admin/artykuly?` + AxiosConfig.objectToURLQuery(params);
    },
    [searchParams]
  );

  useEffect(() => {
    if (window.location.pathname === "/admin/artykuly") {
      fetchPostsAdmin(searchParams);
    }
  }, [searchParams]);

  /**
   *
   * @param e
   */
  const onTagsChange = (e) => {
    setState({ ...state, tags: e.map((i) => i.value) });
  };

  /**
   *
   * @param e
   */
  const onCreatedAfterDateChange = (e) => {
    setState({
      ...state,
      created_after: typeof e === "object" ? e.unix() : null,
    });
  };

  /**
   *
   * @param e
   */
  const onCreatedBeforeDateChange = (e) => {
    setState({
      ...state,
      created_before: typeof e === "object" ? e.unix() : null,
    });
  };

  /**
   *
   * @param e
   */
  const onChangeKind = (e) => {
    setState({ kind: e.value });
  };

  /**
   *
   * @param e
   */
  const onSearch = (e) => {
    const updatedUrl = { ...searchParams, ...state };

    updatedUrl.page = 1;
    const title = document.getElementById("title").value;
    updatedUrl.title = title && title.length ? title : null;
    updatedUrl.tags = state.tags && state.tags ? state.tags : null;
    updatedUrl.tags = state.tags && state.tags ? state.tags : null;

    updatedUrl.created_before = state.created_before || null;
    updatedUrl.created_after = state.created_after || null;

    props.history.replace(linkBuilder(updatedUrl));
  };

  /**
   *
   * @param e
   */
  const onClear = (e) => {
    props.history.replace(
      `/admin/artykuly?` + AxiosConfig.objectToURLQuery({ page: 1 })
    );
  };

  /**
   *
   * @param e
   */
  const onSort = (e) => {
    const updatedUrl = { ...searchParams, ...e };
    props.history.replace(linkBuilder(updatedUrl));
  };

  const newArticleBtn = (
    <Button
      tag={Link}
      to={"/nowy-artykul"}
      className={"btn btn btn-outline-success ml-3"}
    >
      Dodaj nowy
    </Button>
  );

  return (
    <AdminPageTemplate>
      <Container>
        <Row>
          <Col className="" md="12">
            <Card className="card-plain">
              <CardHeader>
                <h4 className={"card-title"}>Artykuły</h4>

                <Form className={"form-horizontal"}>
                  <Row>
                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>Rodzaj</label>
                      <Select
                        /*className="react-select react-select-primary"
                        classNamePrefix="react-select"*/
                        isDisabled={isLoading}
                        value={PostKindOptions.find(
                          (i) => i.value === searchParams.kind
                        )}
                        placeholder={"Wszystkie"}
                        options={[
                          { label: "Wszystkie", value: null },
                          ...PostKindOptions,
                        ]}
                        onChange={onChangeKind}
                        autofill={false}
                      />
                    </FormGroup>
                    <FormGroup className={"col-3"}>
                      <label htmlFor={"title"}>Tytuł</label>
                      <Input
                        id={"title"}
                        placeholder="Tytuł"
                        type="text"
                        disabled={isLoading}
                        defaultValue={searchParams.title}
                      ></Input>
                    </FormGroup>
                    <FormGroup className="col-3">
                      <label htmlFor={"meta_description"}>Tagi</label>
                      <CreatableSelect
                        isMulti
                        /*className="react-select react-select-primary"
                        classNamePrefix="react-select"*/
                        isDisabled={isLoading}
                        placeholder={"Szukaj tagów"}
                        aria-placeholder={"Szukaj tagów"}
                        noOptionsMessage={() => "Tag, bez #"}
                        formatCreateLabel={(e) => `Wybierz "${e}"`}
                        onChange={onTagsChange}
                        defaultValue={
                          searchParams.tags && searchParams.tags.length
                            ? searchParams.tags
                                .split(",")
                                .map((i) => ({ value: i, label: i }))
                            : null
                        }
                      />
                    </FormGroup>
                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>Utworzone po</label>
                      <Datetime
                        inputProps={{
                          placeholder: "Opublikowane po",
                          disabled: isLoading,
                        }}
                        onChange={onCreatedAfterDateChange}
                        value={
                          state.created_after
                            ? state.created_after * 1000
                            : null
                        }
                      />
                    </FormGroup>
                    <FormGroup className={"col-2"}>
                      <label htmlFor={"meta_description"}>
                        Utworzone przed
                      </label>
                      <Datetime
                        disabled={isLoading}
                        inputProps={{
                          placeholder: "Opublikowane prezd",
                          disabled: isLoading,
                        }}
                        onChange={onCreatedBeforeDateChange}
                        value={
                          state.created_before
                            ? state.created_before * 1000
                            : null
                        }
                      />
                    </FormGroup>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button onClick={onClear} disabled={isLoading}>
                        Wyczyść
                      </Button>
                      <Button
                        color={"info"}
                        onClick={onSearch}
                        disabled={isLoading}
                      >
                        Szukaj
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardHeader>
              <CardBody>
                <ArticlesTable
                  isLoading={isLoading}
                  articles={adminPosts.collection}
                  pagination={adminPosts.pagination}
                  linkBuilder={linkBuilder}
                  onSort={onSort}
                  sort={{
                    sort: searchParams.sort || null,
                    direction: searchParams.direction || null,
                  }}
                  titleComponent={newArticleBtn}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </AdminPageTemplate>
  );
};

ArticlesIndexPage.defaultProps = {};

ArticlesIndexPage.propTypes = {};

export default ArticlesIndexPage;
