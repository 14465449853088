/**
 * Created by piotr.pozniak@thebeaverhead.com on 03/02/2022
 */

export const PostKindOptions = [
  {
    label: "Artykuł",
    value: "article",
  },
  {
    label: "Video",
    value: "video",
  },
];
