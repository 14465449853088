// import React from "react";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";
//import whyDidYouRender from "@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rootReducer from "./reducers/index";
// import {filtersReducerTransform} from "./reducers/filtersReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["app", "user"],
  // transforms: [filtersReducerTransform]
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const middlewareComponents = [
  thunk,
  routerMiddleware(history),
];

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewareComponents.push(logger);
  //whyDidYouRender(React);
}
const middleware = applyMiddleware(...middlewareComponents);

export const configureStore = createStore(persistedReducer, middleware);
export const persistor = persistStore(configureStore);
