/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/01/2022
 */

import React, { useEffect, useRef, useState } from "react";
import ModalTemplate from "../templates/ModalTemplate";
import smartHistoryBack from "../tools/smartHistoryBack";
import { Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import ApiError from "../components/ApiError";
import useUnload from "../hooks/useUnload";
import { useAppStore } from "../hooks/app";
import { usePostStore } from "../hooks/post";
import CreatableSelect from "react-select/creatable";
import Wysiwyg from "../components/Wysiwyg";
import CoverImageSelector from "./ArticleEditModal/CoverImageSelector";
import AxiosConfig from "../AxiosConfig";
import { configureStore } from "../store";
import Select from "react-select";
import Datetime from "react-datetime";
import { PostKindOptions } from "../consts/posts";
import { kindToUrl } from "../helpers/post";

const ArticleEditModal = (props) => {
  const [postState, setPostState] = useState({
    available: 0,
    title: "",
    body: "",
    abstract: "",
    kind: "",
    meta_description: "",
    meta_title: "",
    tags: [],
    coverImage: null,
    video_url: "",
  });

  const { post, uploadPostImage, deletePostImage, updatePost, fetchPost } =
    usePostStore();
  const [formError, setFormError] = useState({});
  const { clearErrors } = useAppStore();

  const postModel = post.model || {};

  const isNew = props.match.params === null || props.match.params === undefined;

  useUnload(() => {
    clearErrors();
  });

  console.log(props.match, isNew);
  useEffect(() => {


    if (!isNew) {
      fetchPost(props.match.params.slug);
    }
  }, []);

  useEffect(() => {
    if (post.fetchSuccess) {
      const coverImage =
        postModel.images && postModel.images.length
          ? postModel.images.find((i) => i.is_cover)
          : null;

      setPostState({
        available: postModel.available,
        publish_date: postModel.publish_date,
        title: postModel.title,
        body: postModel.body,
        kind: postModel.kind,
        abstract: postModel.abstract,
        meta_description: postModel.meta_description,
        meta_title: postModel.meta_title,
        video_url: postModel.video_url,
        tags:
          postModel.tags && postModel.tags.length
            ? postModel.tags.map((i) => ({ label: i.label, value: i.slug }))
            : [],
        coverImage: coverImage
          ? AxiosConfig.getResourceUrl(coverImage.thumb_md_path)
          : null,
      });
    }
  }, [post.fetchSuccess]);

  /**
   *
   */
  const onClose = () => {
    clearErrors();
    smartHistoryBack(props.history);
  };

  /**
   *
   * @param e
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    const videoUrl =
      postState.kind === "video"
        ? document.querySelector("#video_url").value
        : null;

    const data = {
      available: document.querySelector("#available").checked ? 1 : 0,
      publish_date: postState.publish_date,
      title: document.querySelector("#title").value,
      body: document.querySelector("#article-wysiwyg").value,
      abstract: document.querySelector("#abstract").value,
      meta_description: document.querySelector("#meta_description").value,
      meta_title: document.querySelector("#meta_title").value,
      video_url: videoUrl,
      tags: postState.tags.map((i) => i.label),
    };

    const oldCoverImage =
      postModel.images && postModel.images.length
        ? postModel.images.find((i) => i.is_cover)
        : null;

    const hasNewCoverImage =
      postState.coverImage && typeof postState.coverImage === "object";

    // remove existing cover image if it is required
    const removeCoverImage =
      oldCoverImage && (!postState.coverImage || hasNewCoverImage);

    if (removeCoverImage) {
      await deletePostImage(oldCoverImage.id);
    }

    await updatePost(isNew ? null : postModel.id, data, postState.kind);

    let postStore = configureStore.getState().post;

    if (postStore.editSuccess) {
      if (hasNewCoverImage) {
        await uploadPostImage(
          postState.coverImage,
          "post",
          null,
          postStore.model.id,
          1
        );
      }
      postStore = configureStore.getState().post;

      if (
        !hasNewCoverImage ||
        (hasNewCoverImage && postStore.coverImageUploadSuccess)
      ) {
        clearErrors();
        const path = kindToUrl(postStore.model.kind);
        //props.history.push(`/${path}/${postStore.model.slug}`);
        smartHistoryBack(props.history);
      }
    }
  };

  const isLoading = post.edit || post.imageDelete || post.coverImageUpload;

  /*  const error = Object.keys(formError).length ? (
    <Alert color={"danger"}>
      <Container>
        <div className="alert-icon">
          <i className="fas fa-exclamation"></i>
        </div>
        {formError.nickname}
      </Container>
    </Alert>
  ) : null;*/

  /**
   *
   * @param e
   */
  const onTagsChange = (e) => {
    const data = { ...postState };
    data.tags = e;
    setPostState(data);
  };

  /**
   *
   * @param e
   */
  const onPublishedDateChange = (e) => {
    const data = { ...postState };
    data.publish_date = typeof e === "object" ? e.unix() : null;
    setPostState(data);
  };

  /**
   *
   * @param e
   */
  const onChangeKind = (e) => {
    const data = { ...postState };

    data.kind = e.value;
    setPostState(data);
  };

  /**
   *
   * @param e
   */
  const onCoverImageChange = (e) => {
    const data = { ...postState };
    data.coverImage = e;

    setPostState(data);
  };

  const apiError = post.editError ? (
    <ApiError
      error={post.editError}
      defaultMessage={"Zmiany nie zostały zapisane. Spróbuj ponownie."}
    />
  ) : null;

  const modalTitle = isNew ? "Utwórz artykuł" : `Edytuj ${postModel.title}`;

  const videoLink =
    postState.kind === "video" ? (
      <FormGroup className="col-md-12">
        <label htmlFor={"title"}>Link do video</label>
        <Input
          id={"video_url"}
          placeholder="Link do video"
          type="text"
          disabled={isLoading}
          defaultValue={postState.video_url}
        ></Input>
      </FormGroup>
    ) : null;

  return (
    <ModalTemplate
      onClose={onClose}
      size={"lg"}
      title={modalTitle}
      onCancel={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <Form>
        <div className="form-row">
          <CoverImageSelector
            previewImage={postState.coverImage}
            onChange={onCoverImageChange}
          />
          <FormGroup className="col-md-12">
            <label htmlFor={"kind"}>Rodzaj treści</label>
            <Select
              /*className="react-select react-select-primary"
              classNamePrefix="react-select"*/
              disabled={isLoading}
              value={PostKindOptions.find((i) => i.value === postState.kind)}
              placeholder={"Wybierz rodzaj treści"}
              options={[
                { label: "Wybierz rodzaj", value: null },
                ...PostKindOptions,
              ]}
              onChange={onChangeKind}
              autofill={false}
            />
          </FormGroup>
          {videoLink}
          <FormGroup className="col-md-12">
            <label htmlFor={"title"}>Tytuł</label>
            <Input
              id={"title"}
              placeholder="Tytuł"
              type="text"
              disabled={isLoading}
              defaultValue={postState.title}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <label htmlFor={"meta_title"}>Meta tytuł</label>
            <Input
              id={"meta_title"}
              placeholder="Meta tytuł. Ten tekst zostanie użyty do SEO jako tytuł"
              type="text"
              disabled={isLoading}
              defaultValue={postState.meta_title}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <label htmlFor={"abstract"}>Krótki opis, TL;DR</label>

            <Input
              id={"abstract"}
              placeholder="Abstrakt. Krótki opis, podsumowanie artykułu"
              type="textarea"
              disabled={isLoading}
              defaultValue={postState.abstract}
            ></Input>
          </FormGroup>
          <FormGroup className="col-md-12">
            <label htmlFor={"meta_description"}>
              Meta opis. Wiesz, dla SEO.
            </label>
            <Input
              id={"meta_description"}
              placeholder="Meta opis. Ten opis zostanie użyty jako opis SEO"
              type="textarea"
              disabled={isLoading}
              defaultValue={postState.meta_description}
            ></Input>
          </FormGroup>
          <FormGroup className="col-12">
            <label htmlFor={"meta_description"}>Tagi</label>
            <CreatableSelect
              isMulti
              /*className="react-select react-select-primary"
              classNamePrefix="react-select"*/
              placeholder={"Wpisz tagi, ile chcesz ale lepiej nie więcej jak 5"}
              aria-placeholder={
                "Wpisz tagi, ile chcesz ale lepiej nie więcej jak 5"
              }
              noOptionsMessage={() => "Wpisz tag, aby go użyć"}
              formatCreateLabel={(e) => `Dodaj "${e}"`}
              onChange={onTagsChange}
              value={postState.tags}
            />
          </FormGroup>
          <Wysiwyg
            uploadImage={uploadPostImage}
            resourceType={"post"}
            isLoading={isLoading}
            defaultValue={postState.body}
            inputId={"article-wysiwyg"}
            changeBody={postModel.changeBody}
          />
          <FormGroup className={"col-4"}>
            <Datetime
              inputProps={{ placeholder: "Datetime Picker Here" }}
              value={
                postState.publish_date ? postState.publish_date * 1000 : null
              }
              onChange={onPublishedDateChange}
            />
          </FormGroup>
          <Col md={8} />
          <FormGroup check className={"col-4"}>
            <Label check>
              <Input
                type="checkbox"
                id={"available"}
                defaultChecked={postModel.available}
              ></Input>
              <span className="form-check-sign"></span>
              Dostępny publicznie
            </Label>
          </FormGroup>
        </div>
        {apiError}
        {/*{error}*/}
      </Form>
    </ModalTemplate>
  );
};

ArticleEditModal.defaultProps = {};

ArticleEditModal.propTypes = {};

export default ArticleEditModal;
