/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/07/2017.
 */

import axios from "axios";
import { history } from "../store";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param slug
 * @param windowUser
 * @returns {Function}
 */
export const fetchPost = (slug) => {
  return async (dispatch) => {
    dispatch({ type: "POST_FETCH" });

    await axios
      .get(
        AxiosConfig.getEndpointAddress() + "/api/posts/" + slug + ".json",
        AxiosConfig.getAuthConfigIfAvailable()
      )
      .then((response) => {
        dispatch({
          type: "POST_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "POST_FETCH_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param id
 * @param data
 * @param kind
 * @returns {Function}
 */
export const updatePost = (id, data, kind) => {
  return async (dispatch) => {
    dispatch({ type: "POST_EDIT", id });

    const action = id ? axios.put : axios.post;
    const address = id
      ? `/api/posts/${kind}/${id}.json`
      : `/api/posts/${kind}.json`;

    await action(
      AxiosConfig.getEndpointAddress() + address,
      data,
      AxiosConfig.getAuthConfig()
    )
      .then((response) => {
        dispatch({
          type: "POST_EDIT_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "POST_EDIT_REJECTED",
          payload: error,
          id,
        });
      });
  };
};

/**
 *
 * @param postId
 * @param type
 * @returns {Function}
 */
export const votePost = (postId, vote = 1) => {
  return async (dispatch) => {
    dispatch({ type: "POST_VOTE" });

    axios
      .post(
        AxiosConfig.getEndpointAddress() + `/api/posts/vote/${postId}.json`,
        {
          vote,
        },
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "POST_VOTE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "POST_VOTE_REJECTED",
          payload: error,
        });
      });
  };
};


/**
 *
 * @param postId
 * @param type
 * @returns {Function}
 */
export const unvotePost = (postId) => {

  return async (dispatch) => {
    dispatch({ type: "POST_VOTE" });

    axios
      .delete(
        AxiosConfig.getEndpointAddress() + `/api/posts/vote/${postId}.json`,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "POST_VOTE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "POST_VOTE_REJECTED",
          payload: error,
        });
      });
  };
};

/**
 *
 * @param file
 * @param resource
 * @param changeData
 * @param resourceId
 * @param isCover
 * @returns {(function(*): Promise<void>)|*}
 */
export const uploadPostImage = (file, resource, changeData, resourceId = null, isCover = 0) => {
  return async (dispatch) => {

    const action = isCover ? "POST_COVER_IMAGE_UPLOAD" : "POST_IMAGE_UPLOAD";

    let data = new FormData();
    data.append("file", file);
    data.append("resource", resource);
    data.append("is_cover", isCover ? "1" : "0");

    if (resourceId) {

      data.append("id", resourceId);
    }

    dispatch({ type: action });

    await axios
      .post(
        AxiosConfig.getEndpointAddress() + "/api/images.json",
        data,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: `${action}_FULFILLED`,
          payload: response.data,
          changeData: changeData,
        });
      })
      .catch(function (error) {
        dispatch({
          type: `${action}_REJECTED`,
          payload: error,
        });
      });
  };
};

/**
 *
 * @param imageId
 * @returns {(function(*): Promise<void>)|*}
 */
export const deletePostImage = (imageId) => {
  return async (dispatch) => {

    dispatch({ type: "POST_IMAGE_DELETE" });

    await axios
      .delete(
        AxiosConfig.getEndpointAddress() + `/api/images/${imageId}.json`,
        AxiosConfig.getAuthConfig()
      )
      .then((response) => {
        dispatch({
          type: "POST_IMAGE_DELETE_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "POST_IMAGE_DELETE_REJECTED",
          payload: error,
        });
      });
  };
};
