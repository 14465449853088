import { combineReducers } from "redux";

import app from "./app";
import posts from "./posts";
import similarPosts from "./similarPosts";
import post from "./post";
import comment from "./comment";
import profile from "./profile";
import user from "./user";
import mailList from "./mailList";
import adminPosts from "./adminPosts";
import adminUsers from "./adminUsers";
import adminMailList from "./adminMailList";

import { connectRouter } from "connected-react-router";

export default (history) =>
  combineReducers({
    app,
    posts,
    similarPosts,
    comment,
    post,
    profile,
    user,
    mailList,
    router: connectRouter(history),
    adminPosts,
    adminUsers,
    adminMailList,
  });
