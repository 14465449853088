/**
 * Created by leper on 03/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Nav, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import classNames from "classnames";
import {Link} from "react-router-dom";

const TablePagination = (props) => {
  const { page, pagesCount } = props;
  const maxItems = pagesCount < props.maxItems ? pagesCount : props.maxItems;

  const middle = Math.floor(maxItems / 2);
  const firstIndex =
    page - middle < 1
      ? 1
      : page -
        middle -
        (pagesCount - page < middle ? middle - (pagesCount - page) : 0);
  const lastIndex = page + middle > pagesCount ? pagesCount : page + middle;

  const pagesLinks = Array.from(Array(maxItems).keys()).map((i, idx) => {
    const currentPage = i + firstIndex;

    return (
      <PaginationItem
        active={currentPage === page}
        color={"info"}
        key={`pl-${idx}`}
      >
        <PaginationLink
          tag={Link}
          to={props.linkBuilder({page: currentPage})}
          className={"nav-link"}
        >
          {currentPage}
        </PaginationLink>
      </PaginationItem>
    );
  });

  const previous =
    firstIndex > 1 ? (
      <>
        <PaginationItem>
          <PaginationLink
            className={"nav-link"}
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            1
          </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={true}>
          <PaginationLink>&#8230;</PaginationLink>
        </PaginationItem>
      </>
    ) : null;

  const next =
    lastIndex < pagesCount && maxItems < pagesCount? (
      <>
        <PaginationItem disabled={true}>
          <PaginationLink>&#8230;</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className={"nav-link"}
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            {pagesCount}
          </PaginationLink>
        </PaginationItem>
      </>
    ) : null;

  return (
    <nav>
      <Pagination
        className="pagination justify-content-end"
      >
        {previous}
        {pagesLinks}
        {next}
      </Pagination>
    </nav>
  );
};

TablePagination.defaultProps = {
  maxItems: 5,
};

TablePagination.propTypes = {
  page: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  maxItems: PropTypes.number,
};

export default TablePagination;
