/**
 * Created by piotr.pozniak@thebeaverhead.com on 27/01/2022
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup, InputGroupAddon, InputGroupText,
  Row,
} from "reactstrap";
import { useMailListStore } from "../hooks/mailList";
import { validateEmail } from "../helpers/error";
import ApiError from "./ApiError";

const MailListSubscribeForm = (props) => {
  const [emailError, setEmailError] = useState(null);
  const { mailList, subscribeMailList } = useMailListStore();

  /**
   *
   * @param e
   * @returns {Promise<void>}
   */
  const onSubmit = async (e) => {
    e.preventDefault();
    const email = document.getElementById('subscribe-email').value;

    if (!email.length || !validateEmail(email)) {
      return setEmailError("Jakaś literówka w adresie email");
    }

    setEmailError(null);
    await subscribeMailList(email);
  };

  const apiError = mailList.subscribeError ? (
    <ApiError error={mailList.subscribeError} />
  ) : null;

  const formError = emailError ? (
    <Alert color={"danger"} className={"mt-1"}>
      <i className="fas fa-exclamation-circle"></i> {emailError}
    </Alert>
  ) : null;

  if (mailList.subscribeSuccess) {
    return (
      <Alert color={"success"} className={"mt-1"}>
        <i className="fas fa-exclamation-circle"></i> Pomyślnie zapisałeś się do
        naszego newslettera! Potwierdzenie znajdziesz na swojej skrzynce.
      </Alert>
    );
  }

  return (
    <Card className="card-raised card-form-horizontal">
        <CardBody>
          <Form action="" method="">
            <Row>
              <Col sm="8">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    autoComplete="email"
                    placeholder="Twój adres email"
                    type="email"
                    id={"subscribe-email"}
                  ></Input>
                </InputGroup>
              </Col>

              <Col md="4">
                <Button
                  block
                  className="btn-round"
                  color="info"
                  type="button"
                  onClick={onSubmit}
                >
                  Zapisz się
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {apiError}
                {formError}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
  );
};

MailListSubscribeForm.defaultProps = {};

MailListSubscribeForm.propTypes = {};

export default MailListSubscribeForm;
