/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as profileActions from "./../actions/profile";

export function useProfileStore() {
  const dispatch = useDispatch();
  const _profile = useSelector((store) => store.profile);

  const fetchProfile = useCallback(
    async (slug) =>
      await dispatch(profileActions.fetchProfile(slug)),
    [dispatch]
  );

  return {
    profile: _profile,
    fetchProfile
  };
}
