/**
 * Created by leper on 18/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardTitle } from "reactstrap";
import AxiosConfig from "../../AxiosConfig";
import PreloadImage from "../../components/PreloadImage";
import { Link } from "react-router-dom";
import { kindToUrl } from "../../helpers/post";

const BlogCard = (props) => {
  const { post } = props;

  const coverImage =
    post.images && post.images.length
      ? post.images.find((i) => i.is_cover)
      : null;
  const postUrl = `/${kindToUrl(post.kind)}/${post.slug}`;
  const tags =
    post.tags && post.tags.length
      ? post.tags.map((i, idx) => (
          /*<Link
          key={`ab-${post.slug}${i.slug}`}
          /!*tag={Link}*!/
          className=""
          color="info"
          to={"#"}
          /!*to={`/tags/${i.slug}`}*!/
        >*/
          <span className={"mr-1"}>#{i.label}</span>
          /*</Link>*/
        ))
      : null;

  return (
    <Card className="card-plain card-blog">
      <div className="card-image">
        <Link to={postUrl}>
          <div className={"img-16x9"}>
            {coverImage ? (
              <PreloadImage
                alt={post.title}
                className="img rounded img-raised"
                src={AxiosConfig.getResourceUrl(coverImage.thumb_md_path)}
                previewData={coverImage.preview}
              />
            ) : null}
          </div>
        </Link>
      </div>
      <h6 className="category text-info">{tags}</h6>
      <CardTitle tag="h3">
        <Link to={postUrl}>{post.title}</Link>
      </CardTitle>
      <h5 className="card-description">{post.abstract}</h5>
      <Button tag={Link} className="btn-round" color="info" to={postUrl}>
        Czytaj całość
      </Button>
    </Card>
  );
};

BlogCard.defaultProps = {};

BlogCard.propTypes = {};

export default BlogCard;
