/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/08/2017.
 */

/**
 *
 * @param str
 * @param is_xhtml
 * @returns {string}
 */
export function nl2br(str, is_xhtml) {
  let breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
}

/**
 *
 * @param str
 * @param length
 */
export function extractBrief(str, length) {
  let wordCounter = 0;
  let briefWordsArray = [];

  str.split(/\s/).map((word) => {
    if (word.length > 0) {
      wordCounter++;
      if (briefWordsArray.length < length) {
        briefWordsArray.push(word);
      }
    }
  });

  return {
    wordCounter: wordCounter,
    briefWordsArray: briefWordsArray,
  };
}

/**
 *
 * @param str
 * @returns {*}
 */
export function sanitizeOutputStr(str) {
  if (!str) return "";

  str = str.replace(/&lt;&bsol;/gi, "</");
  str = str.replace(/&lt;/gi, "<");
  str = str.replace(/&gt;/gi, ">");

  // str = str.replace(/&nbsp;&nbsp;&nbsp;/gi, "\t");

  return str;
}

/**
 *
 * @param str
 * @returns {*}
 */
export function sanitizeInputStr(str) {
  // change <code></code> to [code][/code]
  str = str.replace(/<code.*>([\s\S]*?)<\/code>/gi, "[code]$1[/code]");
  str = str.replace(/<h1.*>([\s\S]*?)<\/h1>/gi, "[h1]$1[/h1]");
  str = str.replace(/<ul.*>([\s\S]*?)<\/ul>/gi, "[ul]$1[/ul]");
  str = str.replace(/<li.*>([\s\S]*?)<\/li>/gi, "[li]$1[/li]");
  str = str.replace(/<b.*>([\s\S]*?)<\/b>/gi, "[b]$1[/b]");
  str = str.replace(/<i.*>([\s\S]*?)<\/i>/gi, "[i]$1[/i]");
  str = str.replace(
    /<a[^>]+href=\"(.*?)\"[^>]*>([\s\S]*?)<\/a>/gi,
    '[a href="$1"]$2[/a]'
  );

  str = str.replace(/<\//gi, "&lt;&bsol;");
  str = str.replace(/</gi, "&lt;");
  str = str.replace(/>/gi, "&gt;");

  //str = str.replace(/\t/gi, "&nbsp;&nbsp;&nbsp;");

  // strip all tags
  // str = str.replace(/<(?:.|\s)*?>/g, "");

  // revert back [code] to <code> and [/code] to </code>
  str = str.replace(/\[code\]([\s\S]*?)\[\/code\]/gi, "<code>$1</code>");
  str = str.replace(/\[h1\]([\s\S]*?)\[\/h1\]/gi, "<h1>$1</h1>");
  str = str.replace(/\[ul\]([\s\S]*?)\[\/ul\]/gi, "<ul>$1</ul>");
  str = str.replace(/\[li\]([\s\S]*?)\[\/li\]/gi, "<li>$1</li>");
  str = str.replace(/\[b\]([\s\S]*?)\[\/b\]/gi, "<b>$1</b>");
  str = str.replace(/\[i\]([\s\S]*?)\[\/i\]/gi, "<i>$1</i>");
  str = str.replace(
    /\[a[^>]+href=\"(.*?)\"[^>]*]([\s\S]*?)\[\/a\]/gi,
    '<a href="$1" target="_blank">$2</a>'
  );
  return str;
}

/**
 *
 * @param contentId
 * @param data
 *
 */
export function updateCaretPositionAfterPhotoUpload(contentId, data) {
  const txtarea = document.querySelector(contentId);

  let br =
    txtarea.selectionStart || txtarea.selectionStart == "0"
      ? "ff"
      : document.selection
      ? "ie"
      : false;

  let scrollPos = txtarea.scrollTop;

  txtarea.value = data.text;
  let strPos = data.cursorPosition;

  // if (br === "ie") {
  //   txtarea.focus();
  //   let ieRange = document.selection.createRange();
  //   ieRange.moveStart('character', -txtarea.value.length);
  //   ieRange.moveStart('character', strPos);
  //   ieRange.moveEnd('character', 0);
  //   ieRange.select();
  // } else if (br === "ff") {
  txtarea.selectionStart = strPos;
  txtarea.selectionEnd = strPos;
  txtarea.focus();
  // }

  txtarea.scrollTop = scrollPos;
  txtarea.blur();
  txtarea.focus();
}

/***
 * Written by Uno Kim (http://djkeh.github.io)
 */
export let TabManager = {
  tabKey: 9, // This number means tab key ascii input.
  enableTab: function (textBox, keyEvent) {
    if (this.isTabKeyInput(keyEvent)) {
      // Put tab key into the current cursor(caret) position.
      this.insertTab(textBox);

      // Block(invalidate) actual tab key input by returning key event handler to false.
      this.blockKeyEvent(keyEvent);
    }
  },
  isTabKeyInput: function (keyEvent) {
    return keyEvent.keyCode == this.tabKey;
  },
  insertTab: function (textBox) {
    let pos = this.getCaretPosition(textBox);
    let preText = textBox.value.substring(0, pos);
    let postText = textBox.value.substring(pos, textBox.value.length);

    textBox.value = preText + "\t" + postText; // input tab key

    this.setCaretPosition(textBox, pos + 1);
  },
  setCaretPosition: function (item, pos) {
    // Firefox, Chrome, IE9~ Support
    if (item.setSelectionRange) {
      item.focus();
      item.setSelectionRange(pos, pos);
    }
    // ~IE9 Support
    else if (item.createTextRange) {
      let range = item.createTextRange();
      range.collapse(true);
      range.moveEnd("character", pos);
      range.moveStart("character", pos);
      range.select();
    }
  },
  getCaretPosition: function (item) {
    let caretPosition = 0;

    // Firefox, Chrome, IE9~ Support
    if (item.selectionStart || item.selectionStart == "0") {
      caretPosition = item.selectionStart;
    }
    // ~IE9 Support
    else if (document.selection) {
      item.focus();
      let sel = document.selection.createRange();
      sel.moveStart("character", -item.value.length);
      caretPosition = sel.text.length;
    }

    return caretPosition;
  },
  blockKeyEvent: function (keyEvent) {
    if (keyEvent.preventDefault) {
      keyEvent.preventDefault();
    } else {
      keyEvent.returnValue = false;
    }
  },
};
