/**
 * Created by piotr.pozniak@thebeaverhead.com on 01/08/2023
 */

import React from "react";
import PropTypes from "prop-types";

const Vimeo = ({ url }) => {
  return (
    <iframe
      src={`https://player.vimeo.com/video${url.pathname}?badge=0&autopause=0&player_id=0`}
      frameBorder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowFullScreen
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
      title="PJL - test upload"
    ></iframe>
  );
};

Vimeo.defaultProps = {};

Vimeo.propTypes = {};

export default Vimeo;
