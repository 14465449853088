/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/01/2022
 */

import React from "react";
import PropTypes from "prop-types";
import PageTemplate from "./../templates/PageTemplate";
import Header from "./LandingPage/Header";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import AxiosConfig from "../AxiosConfig";
import SeoBlock from "../components/SeoBlock";
import MailListSubscribeForm from "../components/MailListSubscribeForm";
import { Link } from "react-router-dom";

const LandingPage = (props) => {
  return (
    <PageTemplate>
      <SeoBlock
        title={"Programowanie jest łatwe"}
        description={
          "Zbiór artykułów o programowania. Przekonaj się, że programowanie to nie czarna magia."
        }
        keywords={
          "programowanie jest łatwe, nauka programowania, od czego zacząć, podstawy programowania, warsztaty, informatyka, algorytmy"
        }
        image={AxiosConfig.getResourceUrl("/img/bg/bg27.jpg")}
        author={"Programowanie jest łatwe"}
        type="website"
      />

      <div className={"section"}>
        <Container fluid>
          <Row>
            <Col className="px-0" md="6">
              <Header />
            </Col>
            <Col className="px-0" md="6">
              <div className="card-container">
                <Card className="card-homepage ">
                  <CardBody>
                    <p className="text-black">
                      Świat IT kusi swoimi możliwościami. Najnowsze technologie,
                      przekraczanie granic, wyjście poza realny świat. Nabycie
                      umiejętności programowania jest równoznaczne z
                      przynależnością do elitarnej grupy i wysokimi zarobkami.
                      Ale czy zawsze?
                    </p>
                  </CardBody>
                </Card>
                <Card
                  className="card-homepage card-background"
                  style={{
                    backgroundImage: "url(/img/bg/bg02.svg)",
                  }}
                >
                  <CardBody>
                    <p>
                      Nauka programowania jest czasochłonna, a w jej trakcie nie
                      zawsze wszystko idzie po Twojej myśli. Dlatego bez
                      odpowiedniego wsparcia łatwo o spadek motywacji -
                      zwłaszcza gdy z wielu stron docierają obietnice szybkich
                      kursów, dzięki którym osiągniesz sukces w IT w zaledwie 3
                      miesiące.
                    </p>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={"section features-5"}>
        <Container>
          <div className="title">
            <h2>Nie daj się pokonać</h2>
            <p>
              Wiemy, że początek przygody z programowaniem daleki jest od
              wyobrażeń życia, jako programista. Dziedzina ta wymaga ogromnej
              wiedzy teoretycznej, ale też umiejętności zastosowania jej w
              konkretnych, nie zawsze oczywistych problemach.
            </p>
          </div>
          <Row>
            <Col md={5}>
              <div className={"info info-horizontal"}>
                <div className="icon icon-info icon-circle">
                  <i className="far fa-life-ring"></i>
                </div>

                <div className={"description"}>
                  <h4 className="info-title">Presja</h4>
                  <p>
                    Stoisz w miejscu, a na bootcampie obiecywano
                    przebranżowienie się w 3 miesiące? To jest w porządku, że
                    nie robisz oszałamiających postępów na samym początku.
                  </p>
                </div>
              </div>

              <div className={"info info-horizontal"}>
                <div className="icon icon-info icon-circle">
                  <i className="fas fa-laptop-code"></i>
                </div>

                <div className={"description"}>
                  <h4 className="info-title">
                    Różnorodność technologii i ogrom nowych pojęć
                  </h4>
                  <p>
                    IT nie stoi w miejscu. Nawet profesjonaliści muszą się
                    ciągle uczyć. To OKEJ czuć się zagubionym - zwłaszcza na
                    początku.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={5} className={"offset-md-1"}>
              <div className={"info info-horizontal"}>
                <div className="icon icon-info icon-circle">
                  <i className="fas fa-hourglass-end"></i>
                </div>

                <div className={"description"}>
                  <h4 className="info-title">Zbyt duże oczekiwania</h4>
                  <p>
                    Nie napiszesz gry na samym początku przygody z
                    programowaniem. Gdy oczekujesz zbyt dużo, rozczarowanie może
                    skutecznie zdemotywować.
                  </p>
                </div>
              </div>

              <div className={"info info-horizontal"}>
                <div className="icon icon-info icon-circle">
                  <i className="fas fa-map-signs"></i>
                </div>

                <div className={"description"}>
                  <h4 className="info-title">
                    Trudność w podjęciu decyzji, od czego zacząć
                  </h4>
                  <p>
                    To jak ze sportem. Masz do wyboru szeroki wachlarz dyscyplin
                    i każdy, dosłownie każdy, znajdzie coś dla siebie. Zapoznaj
                    się z wymaganiami korzyściami danej dziedziny.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="outside-the-box projects-4 pt-0 pb-0"
        data-background-color="gray"
      >
        <Container fluid>
          <Row>
            <Col className="px-0" md="6">
              <Card
                className="card-fashion card-background"
                style={{
                  backgroundImage: "url(/img/bg/bg02.svg)",
                }}
              >
                <CardBody>
                  <CardTitle className="text-left" tag="div">
                    <h2>Myśl poza schematem</h2>
                  </CardTitle>
                  {/*<CardFooter className="text-left">
                    <div className="stats">
                        <span>
                          <i className="now-ui-icons objects_globe"></i>
                          The “Crazy idea” project
                        </span>
                    </div>
                  </CardFooter>*/}
                </CardBody>
              </Card>
            </Col>
            <Col className="px-0" md="6">
              <div className="card-container">
                <Card className="card-fashion">
                  <CardTitle tag="div">
                    <h4>
                      Cokolwiek nie zdecydujesz, potrzebujesz czasu i
                      poświęcenia. Zamiast wskakiwać do kursu konkretnej
                      technologii, rozejrzyj się po świecie IT.
                    </h4>
                  </CardTitle>
                  {/* <CardBody>
                    <CardFooter>
                      <div className="stats">
                          <span>
                            <i className="now-ui-icons education_paper"></i>
                            The “I’m on vacation” project
                          </span>
                      </div>
                    </CardFooter>
                  </CardBody>*/}
                </Card>
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage: "url(/img/bg/bg03.svg)",
                  }}
                ></Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="px-0" md="6">
              <div className="card-container">
                <Card
                  className="card-fashion card-background"
                  style={{
                    backgroundImage: "url(/img/bg/bg04.svg)",
                  }}
                ></Card>
                <Card className="card-fashion">
                  <CardTitle tag="div">
                    <h4>
                      Zobacz, które tematy interesują Cię najbardziej. Szersza
                      perspektywa ułatwi Ci zdecydowanie się na konkretną
                      ścieżkę kariery. Pozwoli też uniknąć frustracji.
                    </h4>
                  </CardTitle>
                  {/*<CardBody>
                    <CardFooter>
                      <div className="stats">
                        <span>
                          <i className="now-ui-icons design_app"></i>
                          The "Agile Project" project
                        </span>
                      </div>
                    </CardFooter>
                  </CardBody>*/}
                </Card>
              </div>
            </Col>
            <Col className="px-0" md="6">
              <Card
                className="card-fashion card-background"
                style={{
                  backgroundImage: "url(/img/bg/bg06.svg)",
                }}
              >
                <CardBody>
                  <CardTitle className="text-left" tag="div">
                    <p>
                      Dlatego tworząc PJL, kierujemy się następującymi
                      wartościami:
                    </p>
                  </CardTitle>
                  <CardFooter className="text-left">
                    <div className="outside-the-box--reasons ml-auto">
                      <div className="info info-horizontal">
                        <div className="icon icon-warning">
                          <i className="fas fa-bullseye"></i>
                        </div>
                        <div className="description">
                          <p className="description text-white">
                            Prostota przekazu
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-warning">
                          <i className="far fa-lightbulb"></i>
                        </div>
                        <div className="description">
                          <p className="description text-white">
                            Odpowiednio dobrane treści
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-warning">
                          <i className="fas fa-battery-full"></i>
                        </div>
                        <div className="description">
                          <p className="description text-white">
                            Motywacja do działania
                          </p>
                        </div>
                      </div>
                    </div>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="about-description text-center">
        <div className="landing-page--pjl-desc">
          <Container>
            <Row>
              <Col className="mr-auto ml-auto" md="8">
                <h2 className="title">PROGRAMOWANIE JEST ŁATWE</h2>
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <p className="description text-left">
                    Zaraz po czytaniu, pisaniu i liczeniu, programowanie jest
                    umiejętnością XXI w. Nie myśl o niej, jako źródle bogactwa i
                    wystawnego życia.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <p className="description text-left">
                    Traktuj programowanie, jak czynność, która daje mnóstwo
                    satysfakcji. Programując wchodzisz do innego świata,
                    odrywasz się od rzeczywistości i zapominasz o
                    ograniczeniach.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <p className="description text-left">
                    To gra, w której tworzysz własne zasady, a mapę, po której
                    się poruszasz, ogranicza tylko Twoja wyobraźnia. Z takim
                    podejściem kariera, pieniądze i wszystkie inne tego typu
                    rzeczy przyjdą same.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className={"section"} data-background-color={"navy"}>
        <Container fluid={true} className={""}>
          <Row>
            <Col md={8} className={"offset-md-2"}>
              <h4 className={"description font-weight-light"}>
                PJL to miejsce, w którym skomplikowane rzeczy tłumaczymy w
                prosty sposób. Wyjaśniamy terminy, aby były dla Ciebie łatwe do
                zrozumienia. Budujemy treści tak, aby odnalezienie sensu nie
                stanowiło problemu, niezależnie od Twojego poziomu wiedzy.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="subscribe-line subscribe-line-image"
        style={{
          backgroundImage: "url(/img/bg/bg06.svg)",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="text-center">
                <h4 className="title">Na co czekasz?</h4>
                <p className="description">
                  Spędzając z nami czas poczujesz, że nie taki diabeł straszny,
                  a&nbsp;programowanie to naprawdę hobby, które daje możliwość
                  podboju świata.
                </p>
              </div>

              {/*<MailListSubscribeForm />*/}
              <div className={"text-center"}>
                <Button
                  tag={Link}
                  to={"/subskrybuj"}
                  className={"btn-round btn-glow"}
                  color={"info"}
                >
                  Zapisz się do newslettera
                </Button>
              </div>

              <div className="text-center">
                <p className="description">
                  Nie daj się długo namawiać. Zapisz się do naszego newslettera
                  i&nbsp;bądź z nami na bieżąco. Chcemy motywować Cię do nauki
                  i&nbsp;dzielić się wiedzą w łatwy i przyjacielski sposób.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </PageTemplate>
  );
};

LandingPage.defaultProps = {};

LandingPage.propTypes = {};

export default LandingPage;
