/**
 * Created by piotr.pozniak@thebeaverhead.com on 21/01/2022
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PageTemplate from "../templates/PageTemplate";
import { Button, Col, Container, Row } from "reactstrap";
import { usePostsStore } from "../hooks/posts";
import ArticleCard from "./ArticlesIndexPage/ArticleCard";
import Header from "./Pjl101IndexPage/Header";
import Indicator from "../components/Indicator";
import SeoBlock from "../components/SeoBlock";
import AxiosConfig from "../AxiosConfig";
import WebPage from "../components/Schemas/WebPage";
import MailListSubscribeForm from "../components/MailListSubscribeForm";
import { Link } from "react-router-dom";
import EpisodeThumbnail from "./Pjl101IndexPage/EpisodeThumbnail";

const Pjl101IndexPage = (props) => {
  const { posts, cleanupCollection, fetchLastPosts } = usePostsStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {};
  }, []);

  useEffect(() => {
    loadMore(1);
    return cleanupCollection;
  }, []);

  /**
   *
   * @param page
   */
  const loadMore = (page) => {
    fetchLastPosts("video", page, ["101"], "asc", "publish_date");
  };

  const postsCollection = [...posts.collection];

  for (let i = postsCollection.length; i < 5; i++) {
    postsCollection.push({
      title: `Odcinek ${i + 1} - premiera wkrótce`,
      images: [{}],
    });
  }
  console.log(postsCollection);

  const articles = postsCollection.map((item, idx) => {
    return <EpisodeThumbnail key={`pjl101-c-${idx}`} post={item} />;
  });

  /*
  .map(i => {
    console.log(i);
  });*/

  const loaderContainer = (
    <Col xs={12} className="text-center">
      <Indicator size={32} />
    </Col>
  );

  const loader = posts.fetch ? loaderContainer : null;

  return (
    <PageTemplate>
      <SeoBlock
        title={"PJL 101"}
        description={
          "Zamiast od razu wchodzić do programowania, co bez odpowiedniego nastawienia może przytłoczyć, postanowiłem przygotować serię 101 odcinków, gdzie poruszę tematy, które pozwolą nam znaleźć wspólny język. Po ich obejrzeniu łatwiej będzie Tobie odnaleźć się i zdecydować, w jakiej dziedzinie programowania będziesz chciała się rozwijać."
        }
        keywords={
          "programowanie jest łatwe, nauka programowania, od czego zacząć, podstawy programowania, warsztaty, informatyka, algorytmy"
        }
        image={"/img/bg/bg1.png"}
        author={"Programowanie jest łatwe"}
        type="website"
      />
      <WebPage
        name={"PJL 101 - Programowanie Jest Łatwe"}
        description={
          "Zamiast od razu wchodzić do programowania, co bez odpowiedniego nastawienia może przytłoczyć, postanowiłem przygotować serię 101 odcinków, gdzie poruszę tematy, które pozwolą nam znaleźć wspólny język. Po ich obejrzeniu łatwiej będzie Tobie odnaleźć się i zdecydować, w jakiej dziedzinie programowania będziesz chciała się rozwijać."
        }
        imageUrl={AxiosConfig.getResourceUrl("/img/bg/bg1.png")}
        url={AxiosConfig.getResourceUrl(`/artykuly`)}
      />
      <Header />
      <div className="main">
        <Container>
          <div className={"section"}>
            <Col className="ml-auto mr-auto" md="12">
              <Container>
                <div className="section-pjl101-overview">
                  <Row>
                    <Col md="7">
                      <div className="img-16x9 position-relative">
                        <iframe
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            textAlign: "center",
                          }}
                          controls={"0"}
                          src="https://www.youtube-nocookie.com/embed/w3Xv02UMG9Y"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          modestbranding={"0"}
                        ></iframe>
                      </div>
                    </Col>
                    <Col md="5">
                      <h3>Znajdźmy wspólny język</h3>
                      <p>
                        Zamiast od razu wchodzić do programowania, co bez
                        odpowiedniego nastawienia może przytłoczyć, postanowiłem
                        przygotować serię 101 odcinków, gdzie poruszę tematy,
                        które pozwolą nam znaleźć wspólny język. Po ich
                        obejrzeniu łatwiej będzie Tobie odnaleźć się i
                        zdecydować, w jakiej dziedzinie programowania będziesz
                        chciała się rozwijać.
                      </p>
                      <div className={"text-center"}>
                        <Button
                          tag={Link}
                          to={"/subskrybuj"}
                          className={"btn-subscribe"}
                          color={"danger"}
                        >
                          Subskrybuj
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </Col>
          </div>

          <div className="">
            <Row>
              <Col xs={12}>
                <h3>Odcinki</h3>
              </Col>
              {articles}
              {loader}
            </Row>
          </div>
        </Container>
      </div>
    </PageTemplate>
  );
};

Pjl101IndexPage.defaultProps = {};

Pjl101IndexPage.propTypes = {};

export default Pjl101IndexPage;
