/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/07/2017.
 */

// think twice before changing it and pushing to repo....
const facebook = {
  appId: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "641439716903512" : '188315981744700',
  cookie: true,
  xfbml: true,
  status: true,
  version: 'v12.0',
  scope: 'email,public_profile',
  return_scopes: true
};

export const facebookConfig = facebook;

const google = {
  client_id: "404875117207-hdq2mgmps19l6d32868lonfo1kb8rn6f.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/plus.login"
};

export const googleConfig = google;
