/**
 *
 */

import React from "react";
import PropTypes from "prop-types";
import Schema from "./Schema";
import Organization, {OrganizationType} from "./Organization";
import SeoBlock from "../SeoBlock";
import Person, {PersonType} from "./Person";

export default class Article extends Schema {
  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  /**
   *
   */
  componentDidMount() {
    super.componentDidMount();

    this.jsonToHead(
      Article.createArticleSchema(this.props.article, this.props.lastModified)
    );
  }

  /**
   *
   *
   * @param article
   * @param socials
   * @param lastModified
   * @returns {{"@context": string, "@type": string, name: *, headline, url: string, articleBody: *, wordCount: *, author: {image: *, "@type": string, name: *, "@context": string, url: *}, publisher: {legalName: *, foundingDate: string, address: {"@type": string}, contactPoint: {"@type": string}[], "@type": string, name: *, logo: *, alternateName: *, "@context": string, url: string, sameAs: *}, datePublished, dateModified: *, mainEntityOfPage: {"@type": string, "@id": string}, keywords: string, timeRequired: string}}
   */
  static createArticleSchema(article, lastModified) {
    const object = {
      "@context": "http://schema.org",
      "@type": "Article",
      name: article.title,
      headline: article.headline,
      url: article.url,
      articleBody: article.description,
      wordCount: article.description.split(" ").length,
      author: Person.createPersonSchema(article.author),
      publisher: Organization.createOrganizationSchema(article.publisher),
      datePublished: article.publishedDate,
      dateModified: lastModified,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": article.slug,
      },
      keywords: article.tags.join(", "),
      timeRequired: "P5M",
    };

    if (article.featured) {
      object.image = {
        "@type": "ImageObject",
        caption: article.featured.title,
        url: article.featured.src,
        width: article.featured.width,
        height: article.featured.height,
      };
    }

    return object;
  }

  render() {
    return null;
  }
}

Article.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publishedDate: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    tags: PropTypes.array,
    author: PersonType.isRequired,
    publisher: OrganizationType.isRequired,
    featured: PropTypes.shape({
      src: PropTypes.string,
      title: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    }),
  }).isRequired,
  lastModified: PropTypes.string.isRequired,
};
