/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */

import axios from "axios";
import AxiosConfig from "../AxiosConfig";

/**
 *
 * @param slug
 */
export const fetchProfile = (slug) => {
  return async (dispatch) => {
    dispatch({ type: "PROFILE_FETCH" });

    await axios
      .get(AxiosConfig.getEndpointAddress() + `/api/users/${slug}.json`)
      .then((response) => {
        dispatch({
          type: "PROFILE_FETCH_FULFILLED",
          payload: response.data,
        });
      })
      .catch(function (error) {
        dispatch({
          type: "PROFILE_FETCH_REJECTED",
          payload: error,
        });
      });
  };
};
