/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/01/2022
 */

/**
 *
 * @returns {Function}
 */
export const clearErrors = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_ERRORS" });
  };
};
/**
 *
 * @returns {Function}
 */
export const acceptCookies = (options) => {
  return (dispatch) => {
    dispatch({ type: "APP_SET_COOKIE_ACCEPTED", options });
  };
};
