/**
 * Created by leper on 14/02/2022
 */

import React from "react";
import PropTypes from "prop-types";
import URL from "url-parse";
import Vimeo from "../../components/TextFormatters/Vimeo";
import Youtube from "../../components/TextFormatters/Youtube";
const Formatters = {
  ["vimeo.com"]: Vimeo,
  ["youtube.com"]: Youtube,
  ["youtube-nocookie.com"]: Youtube,
  ["youtu.be"]: Youtube,
};
const Video = (props) => {
  const url = new URL(props.url, true);

  const LinkFormatter = Object.keys(Formatters).includes(url.host)
    ? Formatters[url.host]
    : null;

  if (!LinkFormatter) {
    return <span>Video not found</span>;
  }

  return (
    <div className="video-container">
      <LinkFormatter url={url} />
    </div>
  );
};

Video.defaultProps = {};

Video.propTypes = {
  url: PropTypes.string,
};

export default Video;
