/**
 * Created by piotr.pozniak@thebeaverhead.com on 11/01/2022
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import FooterNavy from "../templates/PageTemplate/FooterNavy";
import Logo from "../components/Logo";
import { useUserStore } from "../hooks/user";
import { useAppStore } from "../hooks/app";
import Indicator from "../components/Indicator";
import ApiError from "../components/ApiError";

const ResetPasswordPage = (props) => {
  const [firstFocus, setFirstFocus] = useState(false);
  const [lastFocus, setLastFocus] = useState(false);
  const [formError, setFormError] = useState(null);

  const [state, setState] = useState({
    newPassword: "",
    passwordConfirm: "",
  });

  const { user, resetPassword } = useUserStore();
  const { clearErrors } = useAppStore();

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
      clearErrors();
    };
  }, []);

  useEffect(() => {
    if (user.resetPasswordSuccess) {
      props.history.push("/");
    }

  }, [user.resetPasswordSuccess]);

  /**
   *
   * @param type
   * @returns {(function(*): void)|*}
   */
  const onPasswordChange = (type) => (e) => {
    const data = { ...state };
    data[type] = e.target.value;
    setState(data);
  };

  /**
   *
   * @param e
   */
  const onSubmit = async (e) => {
    e.preventDefault();

    if (state.newPassword.length < 5) {
      setFormError("Hasło musi mieć minimum 6 znaków.");
      return;
    }

    if (state.newPassword != state.passwordConfirm) {
      setFormError("Hasła nie są takie same.");
      return;
    }
    setFormError(null);

    await resetPassword(
      props.match.params.email,
      props.match.params.token,
      state.newPassword
    );
  };

  const buttonOrLoader = user.resetPassword ? (
    <Indicator size={32} />
  ) : (
    <Button block type={"submit"} className="btn-round" color="info" size="lg">
      Zapisz zmiany
    </Button>
  );

  const error = user.resetPasswordError ? (
    <ApiError error={user.resetPasswordError} />
  ) : null;

  const passwordError = formError ? (
    <ApiError error={formError} defaultMessage={formError} color={"warning"} />
  ) : null;

  return (
    <div className="page-header header-filter" filter-color="blue">
      <div
        className="page-header-image"
        style={{
          backgroundImage:
            "url(" + require("assets/img/login.jpg").default + ")",
        }}
      ></div>
      <div className="content">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="5">
              <Card className="card-login card-plain">
                <Form
                  action="/"
                  className="form"
                  method={"post"}
                  onSubmit={onSubmit}
                >
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <Logo className="logo-white small" />
                    </div>
                    <h5 className={"card-title"}>Zresetuj hasło</h5>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-lock-open"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nowe hasło..."
                        type="password"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={state.newPassword}
                        onChange={onPasswordChange("newPassword")}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Wpisz ponownie nowe hasło..."
                        type="password"
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                        value={state.passwordConfirm}
                        onChange={onPasswordChange("passwordConfirm")}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    {error}
                    {passwordError}
                    {buttonOrLoader}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <FooterNavy />
    </div>
  );
};

ResetPasswordPage.defaultProps = {};

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
