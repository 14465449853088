/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/02/2022.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as mailListActions from "../actions/mailList";

export function useAdminMailListStore() {
  const dispatch = useDispatch();
  const _adminMailList = useSelector((store) => store.adminMailList);

  const fetchMailList = useCallback(
    async (params) => await dispatch(mailListActions.fetchMailList(params)),
    [dispatch]
  );

  return {
    adminMailList: _adminMailList,
    fetchMailList,
  };
}
