/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/01/2022
 */


const initialState = {
  model: null,

  fetch: false,
  fetchError: null,
  fetchSuccess: false,
};

export default function reducer(
  state = {
    ...initialState,
  },
  action
) {
  switch (action.type) {
    case "PROFILE_FETCH":
      return {
        ...state,
        fetch: true,
        fetchError: null,
        fetchSuccess: false,
      };

    case "PROFILE_FETCH_REJECTED":
      return {
        ...state,
        fetch: true,
        fetchError: action.payload,
      };

    case "PROFILE_FETCH_FULFILLED":
    case "USER_EDIT_FULFILLED":
    case "USER_AVATAR_EDIT_FULFILLED":
      return {
        ...state,
        model: action.payload,
        fetch: false,
        fetchSuccess: true,
      };


  }

  return state;
}
