/**
 * Created by piotr.pozniak@thebeaverhead.com on 08/01/2022
 */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody, CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const headerTemplates = [
  {
    title: "Hello World!",
    description: `"Hello World!" to pierwszy przykładowy tekst, który jest używany do demonstracji składni nowego języka.`,
  },
  {
    title: "ala ma kota",
    description: `"ala ma kota" to często wykorzysytywany tekst, służący do demonstracji działania algorytmów.`,
  },
];

const backgrounds = [
  "bg01.png",
  "bg02.svg",
  "bg03.svg",
  "bg04.svg",
  "bg05.png",
  "bg06.svg",
];

const Header = (props) => {
  const titleIndex =
    getRandomIntInclusive(0, headerTemplates.length) % headerTemplates.length;
  const bgIndex =
    getRandomIntInclusive(0, backgrounds.length) % backgrounds.length;

  const header = headerTemplates[titleIndex];
  const background = backgrounds[bgIndex];
  /*
  const pageHeader = useRef();

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);*/

  return (
    <Card
      className="card-homepage card-background"
      style={{
        backgroundImage: "url(/img/bg/bg03.svg)",
      }}
    >
      <CardBody>
        <CardTitle className="text-left" tag="div">
          <h2>{header.title}</h2>
        </CardTitle>
        <CardFooter className="text-left">
          <p>{header.description}</p>
        </CardFooter>
      </CardBody>
    </Card>

    /*<div
      className="landing-page-tips subscribe-line subscribe-line-white"
     /!* style={{
        backgroundImage: `url(/img/bg/${background})`,
      }}*!/
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col md="6">
            <h4 className="title mt-0">{header.title}</h4>
            <p className="description">{header.description}</p>
          </Col>
          <Col md="6">
            <Card className="card-plain card-form-horizontal">
              {/!*<CardBody>
                <Form action="" method="">
                  <Row>
                    <Col sm="8">
                      <InputGroup
                        className={emailFocus1 ? "input-group-focus" : ""}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Your Email..."
                          type="text"
                          onFocus={() => setEmailFocus1(true)}
                          onBlur={() => setEmailFocus1(false)}
                        ></Input>
                      </InputGroup>
                    </Col>
                    <Col sm="4">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        type="button"
                      >
                        Subscribe
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>*!/}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
   */
  );
};

Header.defaultProps = {};

Header.propTypes = {};

export default Header;
