/**
 * Created by piotr.pozniak@thebeaverhead.com on 05/01/2021.
 */

import React from "react";
import PropTypes from "prop-types";
import Schema from "./Schema";
import SeoBlock from '../SeoBlock';

export default class WebPage extends Schema {
  static defaultProps = {};

  constructor(props) {
    super(props);
  }

  /**
   *
   */
  componentDidMount() {
    super.componentDidMount();

    this.jsonToHead(WebPage.createEventSchema(this.props));
  }

  /**
   *
   * @param event
   * @returns {{"@context": string, "@type": string, name: *, description: *, startDate: *, endDate: null, location: {"@type": string, name: *, address: {"@type": string, streetAddress: *, addressLocality: *, addressCountry: string}}, image: *}}
   */
  static createEventSchema(props) {

    const object = {
      "@context": "http://schema.org",
      "@type": "WebPage",
      name: props.name,
      description: props.description,
      url: props.url,
      image: props.imageUrl
    };

    return object;
  }

  render() {
    return null;
  }
}

WebPage.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
};
