/**
 * Created by leper on 14/02/2022
 */

import React from "react";
import PropTypes from "prop-types";

const PanoramicContainer = (props) => {
  return (
    <div className={"panoramic-container"}>
      <div>{props.children}</div>
    </div>
  );
};

PanoramicContainer.defaultProps = {};

PanoramicContainer.propTypes = {};

export default PanoramicContainer;
