/**
 * Created by piotr.pozniak@thebeaverhead.com on 10/07/2017.
 */

import React from "react";
import { history } from "../store";



export default class Logo extends React.Component {



  render() {

    const isHomepage = history.location.pathname == "/" ? true : false;

    return (
      <div className={this.props.className}>
        <div
          className={"label " + (isHomepage ? "" : "clickable")}
          onClick={() => {
            if (!isHomepage) {
              history.push("/");
            }
          }}
        >
          pjl
        </div>
        <div
          className={"frame " + (isHomepage ? "" : "clickable")}
          onClick={() => {
            if (!isHomepage) {
              history.push("/");
            }
          }}
        >
          <div className="mask"></div>
        </div>
      </div>
    );
  }
}
